import { Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';
import { CommonInputProps, useInput, useTranslate } from 'react-admin';
import { Label, LabelColor } from 'src/components/label';
import { varAlpha } from 'src/theme/styles';

export interface ChoiceItem {
  key?: string;
  value: string;
  label: string | ReactNode;
  badge?: string | number | React.ReactElement;
  badgeColor?: LabelColor;
  disabled?: boolean;
}

export type TabsInputProps = CommonInputProps & {
  choices: ChoiceItem[];
};

export function TabsInput(props: TabsInputProps) {
  const { field } = useInput(props as any);
  const t = useTranslate();
  const { fullWidth } = props;

  return (
    <Tabs
      value={field.value}
      onChange={(event, newValue) => field.onChange(newValue)}
      sx={{
        px: 2.5,
        boxShadow: (theme) =>
          `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
        width: fullWidth ? '100%' : undefined,
      }}
    >
      {props.choices.map((tab) => (
        <Tab
          key={tab.key || tab.value}
          iconPosition="end"
          value={tab.value}
          label={typeof tab.label === 'string' ? t(tab.label) : tab.label}
          disabled={tab.disabled}
          icon={
            tab.badge ? (
              <Label
                variant={(tab.value === field.value && 'filled') || 'soft'}
                color={tab.badgeColor || 'default'}
              >
                {tab.badge}
              </Label>
            ) : undefined
          }
        />
      ))}
    </Tabs>
  );
}
