import { ResourceOptions } from 'react-admin';
import { BsBank2 } from 'react-icons/bs';
import { Resources } from '../resource';

export { default as list } from './List';

export const name = Resources.transaction;
export const icon = BsBank2;
export const options: ResourceOptions = {
  label: 'resource.transaction',
};
