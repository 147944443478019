import { MdDoubleArrow } from 'react-icons/md';
import { Box, Typography } from '@mui/material';
import { toFloats } from 'src/utils/format-number';
import ItemPnl from './ItemPnl';
import PnLText from './PnLText';
import { colors } from 'src/configs/color.config';
import { getSymbolFromPair } from 'src/utils/currency';
import PnLValueFormatted from './PnLValueFormatted';
import { isNil } from 'lodash';
import { Image } from 'src/components/image';
import { f } from 'src/utils/format';
import { useTranslate } from 'react-admin';
import dayjs from 'dayjs';
import { TIME_FORMAT } from 'src/configs/common.config';
import { currency } from './Currency';

type Props = {
  order: any;
};

export const CryptoOrderDetail = (props: Props) => {
  const t = useTranslate();

  const { order } = props;

  const selectedHistory = order || {};
  const closingPrice = toFloats({ value: selectedHistory.exit, emptyValue: '--' });
  const inUnstable = ['Pending', 'FilledPublishing'].includes(selectedHistory.status);
  const canCashoutProfit =
    (!['Exit', 'ExitRewarding', 'ExitCleaning'].includes(selectedHistory.status) ||
      selectedHistory.status === 'Filled') &&
    !inUnstable;

  return (
    <div>
      <Box
        sx={{
          px: 3,
          pt: 6,
          pb: '29px',
          // bgcolor: '#1e1e22',
        }}
      >
        <Box display="flex" alignItems="center" gap={1} mb="13px">
          <Typography fontSize={18}>{selectedHistory.pair?.replace('_', '/')}</Typography>
        </Box>
        <Box display="flex" alignItems="flex-end" gap={0}>
          <Box flex={1} maxWidth="calc(100% - 86px)">
            {canCashoutProfit ? (
              <ItemPnl selectedHistory={selectedHistory} />
            ) : (
              <PnLText pnl={selectedHistory.pnl} roi={selectedHistory.roi} isClosed />
            )}
          </Box>
        </Box>
      </Box>
      <Box px={4} lineHeight={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          px={0}
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.betType')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            {selectedHistory.transaction_type === 'Buy' ? (
              <>
                <MdDoubleArrow
                  style={{
                    transform: 'rotate(-0.25turn)',
                    color: colors.itemGreen,
                    width: 18,
                    height: 18,
                  }}
                />
                <Typography fontSize={16}>{t('module.game.gameDetail.crypto.buyUp')}</Typography>
              </>
            ) : (
              <>
                <MdDoubleArrow
                  style={{
                    transform: 'rotate(0.25turn)',
                    color: colors.btnBgRed,
                    width: 18,
                    height: 18,
                  }}
                />
                <Typography fontSize={16}>{t('module.game.gameDetail.crypto.buyDown')}</Typography>
              </>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.betAmount')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            <Typography fontSize={16} lineHeight="1" mt="2px">
              {f(selectedHistory.currencyAmount, { decimal: 2, defaultValue: '--' })}
            </Typography>
            {currency.find((i) => i.name === order.currency)?.icon}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.multiplier')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            x{selectedHistory.leverage}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.fee')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            <Typography fontSize={16} lineHeight="1" mt="2px">
              {f(selectedHistory.fee)}
            </Typography>
            {currency.find((i) => i.name === order.currency)?.icon}
          </Box>
        </Box>
        {selectedHistory.status === 'Exit' ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>
              {+selectedHistory.pnl >= 0
                ? t('module.game.gameDetail.win')
                : t('module.game.gameDetail.lose')}
            </Typography>
            <Box display="flex" alignItems="center" gap="7px">
              <Typography
                fontSize={16}
                color={+selectedHistory.pnl >= 0 ? 'primary.main' : '#ff4651'}
                lineHeight="1"
                mt="2px"
              >
                <PnLValueFormatted pnl={selectedHistory?.pnl} />
              </Typography>
              {currency.find((i) => i.name === order.currency)?.icon}
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.winLose')}</Typography>
            <Box display="flex" alignItems="center" gap="7px" fontSize={16} lineHeight="1">
              {selectedHistory.pnl && Number(selectedHistory.pnl) ? (
                <ItemPnl selectedHistory={selectedHistory} isField />
              ) : (
                '--'
              )}

              {currency.find((i) => i.name === order.currency)?.icon}
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.bettingTime')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            {dayjs(selectedHistory.createdAt).format(TIME_FORMAT)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.entry')}</Typography>
          <Box display="flex" gap={1}>
            <Typography fontSize={16} lineHeight="1">
              {toFloats({ value: selectedHistory.entry, emptyValue: '--' })}
            </Typography>
            <img src={getSymbolFromPair(selectedHistory.pair)} width="15px" height="15px" alt="" />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.bust')}</Typography>
          <Box display="flex" gap={1}>
            <Typography fontSize={16} lineHeight="1">
              {!!+selectedHistory?.burst ? f(selectedHistory?.burst ?? 0, { decimal: 2 }) : '--'}
            </Typography>
            <img src={getSymbolFromPair(selectedHistory.pair)} width="15px" height="15px" alt="" />
          </Box>
        </Box>
        {isNil(selectedHistory.tp) || (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.takeProfit')}</Typography>
            <Typography fontSize={16} lineHeight="1">
              {toFloats({ value: selectedHistory.tp, emptyValue: '--' })}
            </Typography>
          </Box>
        )}
        {isNil(selectedHistory.sl) || (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.stopLoss')}</Typography>
            <Typography fontSize={16} lineHeight="1">
              {toFloats({ value: selectedHistory.sl, emptyValue: '--' })}
            </Typography>
          </Box>
        )}

        {selectedHistory.exitAt && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py="18px"
              borderBottom="1px solid #38383d"
            >
              <Typography fontSize={14}>{t('module.game.gameDetail.crypto.exitAt')}</Typography>
              <Typography fontSize={16} lineHeight="1">
                {selectedHistory.exitAt && dayjs(selectedHistory.exitAt).format(TIME_FORMAT)}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py="18px"
              borderBottom="1px solid #38383d"
            >
              <Typography fontSize={14}>{t('module.game.gameDetail.crypto.exitPrice')}</Typography>
              <Box display="flex" gap={1}>
                <Typography fontSize={16} lineHeight="1">
                  {closingPrice}
                </Typography>
                {closingPrice !== '--' && (
                  <Image
                    src={getSymbolFromPair(selectedHistory.pair)}
                    width="15px"
                    height="15px"
                    alt=""
                  />
                )}
              </Box>
            </Box>
          </>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.orderStatus')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            {t(`module.game.gameDetail.crypto.${selectedHistory.status}`)}
            {!!selectedHistory.exit_reason && (
              <>&nbsp; ({t(selectedHistory.exit_reason.toLowerCase())})</>
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" py={4}>
          <Box display="flex" width="110px">
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.orderId')}</Typography>
          </Box>

          <Box display="flex" flexGrow="1" gap={2} justifyContent="flex-end" alignItems="center">
            <Typography fontSize={14}>{selectedHistory.id}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
