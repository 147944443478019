import { useTranslate, WithListContext } from 'react-admin';
import { ListCard } from './ListCard';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { DashboardRevenueChart } from './DashboardRevenueChart';
import { DashboardPlayerChart } from './DashboardPlayerChart';
import { DashboardInOutComeChart } from './DashboardInOutComeChart';
import { DashboardTxnsByGameChart } from './DashboardTxnsByGameChart';

export const DashboardCharts = () => {
  const translate = useTranslate();

  return (
    <WithListContext
      render={({ data: revenueData, filterValues, isLoading }) => {
        return (
          <Box>
            <ListCard loading={isLoading ?? false} data={revenueData?.[0]} days={'7'} />
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={8}>
                <DashboardRevenueChart
                  revenueData={revenueData?.[0]}
                  isLoading={isLoading ?? false}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DashboardInOutComeChart
                  filterValues={{ filter: filterValues }}
                  isLoading={isLoading ?? false}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DashboardPlayerChart
                  filterValues={{ filter: filterValues }}
                  isLoading={isLoading ?? false}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DashboardTxnsByGameChart
                  filterValues={{ filter: filterValues }}
                  isLoading={isLoading ?? false}
                />
              </Grid>
            </Grid>
            {(revenueData?.[0] || filterValues?.gameIds) && (
              <Stack alignItems="center" direction="row" mt={2} gap={1}>
                <Icon icon="eva:info-outline" width="1.2rem" height="1.2rem" />
                <Typography variant="body2">{translate('module.dashBoard.infoChart')}</Typography>
              </Stack>
            )}
          </Box>
        );
      }}
    />
  );
};
