import * as qs from 'qs';

export function mergeQueryParams<T extends object>(url: string, parameters: T) {
  const filteredParameters = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(parameters).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ''
    )
  );
  const keys = Object.keys(filteredParameters);
  let queryUrl: string = url;
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(filteredParameters);
  }
  return queryUrl;
}

export function mapLangCodeToCountryCode(lang: string) {
  switch (lang.toLowerCase()) {
    case 'zh':
      return 'cn';
    case 'vi':
      return 'vn';
    case 'ar':
      return 'sa';
    case 'en':
      return 'us';
    case 'hi':
      return 'in';
    case 'pt':
      return 'pt';
    case 'ko':
      return 'kr';
    case 'es':
      return 'es';
    default:
      return lang;
  }
}
