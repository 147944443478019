import { ResourceOptions } from 'react-admin';
import { FaGamepad } from 'react-icons/fa';
import { Resources } from '../resource';

export { default as list } from './List';

export const name = Resources.gameDetail;
export const icon = FaGamepad;
export const options: ResourceOptions = {
  label: 'resource.gameDetail',
};
