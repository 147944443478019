import * as React from 'react';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import clsx from 'clsx';
import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { Iconify } from 'src/components/iconify';
import {
  FieldTitle,
  useTranslate,
  SortPayload,
  useResourceContext,
  useTranslateLabel,
} from 'ra-core';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CGDatagridHeaderCell = (props: DatagridHeaderCellProps): JSX.Element => {
  const { className, field, sort, updateSort, isSorting, toolTipTitle, ...rest } = props;
  const resource = useResourceContext();
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();
  const translateTooltip = translate(`tooltips.headers.${field?.props.label}`, { _: '' });
  // const sortLabel = translate('ra.sort.sort_by', {
  //     field: field
  //         ? translateLabel({
  //               label:
  //                   typeof field.props.label === 'string'
  //                       ? field.props.label
  //                       : undefined,
  //               resource,
  //               source: field.props.source,
  //           })
  //         : undefined,
  //     order: translate(`ra.sort.${sort?.order === 'ASC' ? 'DESC' : 'ASC'}`),
  //     _: translate('ra.action.sort'),
  // });

  return (
    <StyledTableCell
      className={clsx(className, field?.props.headerClassName)}
      align={field?.props.textAlign || field?.type.textAlign}
      variant="head"
      {...rest}
    >
      {updateSort &&
      sort &&
      field &&
      field.props.sortable !== false &&
      field.type.sortable !== false &&
      (field.props.sortBy || field.props.source) ? (
        <TableSortLabel
          active={sort.field === (field.props.sortBy || field.props.source)}
          direction={sort.order === 'ASC' ? 'asc' : 'desc'}
          data-field={field.props.sortBy || field.props.source}
          data-order={field.props.sortByOrder || 'ASC'}
          onClick={updateSort}
          classes={DatagridHeaderCellClasses}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            gap={'5px'}
            sx={{
              justifyContent:
                field?.props?.textAlign === 'left' || field?.type?.textAlign === 'left'
                  ? 'flex-start !important'
                  : 'flex-end !important',
            }}
          >
            <FieldTitle label={field.props.label} source={field.props.source} resource={resource} />
            {translateTooltip !== '' && (
              <Tooltip
                title={<Typography fontSize={12}>{translateTooltip}</Typography>}
                placement={
                  field?.props.textAlign === 'right' || field?.type.textAlign === 'right'
                    ? 'bottom-end'
                    : 'bottom-start'
                }
                enterDelay={300}
              >
                <Iconify
                  width={16}
                  icon="eva:info-outline"
                  sx={{ cursor: 'pointer', color: 'text.disabled' }}
                />
              </Tooltip>
            )}
          </Stack>
        </TableSortLabel>
      ) : (
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={'5px'}
          sx={{
            justifyContent:
              field?.props?.textAlign === 'left' || field?.type?.textAlign === 'left'
                ? 'flex-start !important'
                : 'flex-end !important',
          }}
        >
          <FieldTitle label={field?.props.label} source={field?.props.source} resource={resource} />
          {translateTooltip !== '' && (
            <Tooltip
              title={<Typography fontSize={12}>{translateTooltip}</Typography>}
              placement={
                field?.props.textAlign === 'right' || field?.type.textAlign === 'right'
                  ? 'bottom-end'
                  : 'bottom-start'
              }
              enterDelay={300}
            >
              <Iconify
                width={16}
                icon="eva:info-outline"
                sx={{ cursor: 'pointer', color: 'text.disabled' }}
              />
            </Tooltip>
          )}
        </Stack>
      )}
    </StyledTableCell>
  );
};

export interface DatagridHeaderCellProps extends Omit<TableCellProps, 'classes' | 'resource'> {
  className?: string;
  field?: JSX.Element;
  isSorting?: boolean;
  sort?: SortPayload;
  updateSort?: (event: any) => void;
  toolTipTitle?: string;
}

export default memo(
  CGDatagridHeaderCell,
  (props, nextProps) =>
    props.updateSort === nextProps.updateSort &&
    props.sort?.field === nextProps.sort?.field &&
    props.sort?.order === nextProps.sort?.order &&
    props.isSorting === nextProps.isSorting
);

const PREFIX = 'RaDatagridHeaderCell';

export const DatagridHeaderCellClasses = {
  icon: `${PREFIX}-icon`,
};

// Remove the sort icons when not active
const StyledTableCell = styled(TableCell, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .MuiTableSortLabel-icon`]: {
    display: 'none',
  },
  [`& .Mui-active .MuiTableSortLabel-icon`]: {
    display: 'inline',
  },
});
