import { NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

function View() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <TextField source="currency" />
        <NumberField source="playerCT" label="Player CT" />
        <NumberField source="newPlayerCT" label="New Player CT" />
        <NumberField source="hands" />
        <NumberField source="rounds" />
        <NumberField source="stake" />
        <NumberField source="winAmount" />
        <TextField source="playerWL" label="Player W/L" />
        <NumberField source="sysWL" label="System W/L" />
        <NumberField source="sysProfit" label="System Profit %" />
      </SimpleShowLayout>
    </Show>
  );
}

export default View;
