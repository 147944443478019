import polyglotI18nProvider from 'ra-i18n-polyglot';
import { TranslationMessages } from 'react-admin';
import { langs } from 'src/layouts/configs';
import * as languages from '../languages';

const translations: { [key: string]: TranslationMessages } = {
  ...languages,
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'en',
  langs.map((lang) => ({ locale: lang.value, name: lang.label }))
);

export default i18nProvider;
