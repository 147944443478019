export enum StorageKey {
  AuthData = 'auth_data',
  UserData = 'user_data',
  Permission = 'permission',
  GameDetailData = 'RaStore.game-detail.listParams',
}
export class CGStorage {
  public static async get<T>(key: StorageKey): Promise<T | null> {
    try {
      const data = localStorage.getItem(key);
      if (!data) return null;
      return JSON.parse(data) as T;
    } catch (error) {
      console.error(`Get storage with key ${key} failed:`, error);
      return null;
    }
  }
  public static async set(key: StorageKey, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Save data to storage with key ${key} failed:`, error);
    }
  }
  public static async remove(key: StorageKey) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Remove data in storage with key ${key} failed:`, error);
    }
  }
}
