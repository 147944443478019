import { SxProps, Table, TableCell, TableProps } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import difference from 'lodash/difference';
import union from 'lodash/union';
import {
  Identifier,
  OptionalResourceContextProvider,
  RaRecord,
  sanitizeListRestProps,
  SortPayload,
  useListContextWithProps,
} from 'ra-core';
import * as React from 'react';
import {
  cloneElement,
  ComponentType,
  createContext,
  createElement,
  FC,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  DatagridBody,
  DatagridClasses,
  DatagridLoading,
  DatagridRoot,
  PureDatagridBody,
  RowClickFunction,
} from 'react-admin';
import { EmptyContent } from 'src/components/empty-content';
import { Scrollbar } from 'src/components/scrollbar';
import { CGDatagridHeader } from "./CGDatagridHeader";

const defaultBulkActionButtons = <BulkDeleteButton />;

export const CGDatagrid: React.ForwardRefExoticComponent<
  Omit<DatagridProps, 'ref'> & React.RefAttributes<HTMLTableElement>
> = React.forwardRef<HTMLTableElement, DatagridProps>((props, ref) => {
  const {
    optimized = false,
    body = optimized ? PureDatagridBody : DatagridBody,
    header = CGDatagridHeader,
    children,
    className,
    expand,
    bulkActionButtons = defaultBulkActionButtons,
    hover,
    isRowSelectable,
    isRowExpandable,
    resource,
    rowClick,
    rowSx,
    rowStyle,
    size = 'small',
    sx,
    expandSingle = false,
    ...rest
  } = props;

  const { sort, data, isPending, onSelect, onToggleItem, selectedIds, setSort, total } =
    useListContextWithProps(props);

  const hasBulkActions = !!bulkActionButtons !== false;

  const contextValue = useMemo(
    () => ({ isRowExpandable, expandSingle }),
    [isRowExpandable, expandSingle]
  );

  const lastSelected = useRef(null);

  useEffect(() => {
    if (!selectedIds || selectedIds.length === 0) {
      lastSelected.current = null;
    }
  }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps

  // we manage row selection at the datagrid level to allow shift+click to select an array of rows
  const handleToggleItem = useCallback(
    (id: any, event: any) => {
      if (!data) return;
      const ids = data.map((record) => record.id);
      const lastSelectedIndex = ids.indexOf(lastSelected.current);
      lastSelected.current = event.target.checked ? id : null;

      if (event.shiftKey && lastSelectedIndex !== -1) {
        const index = ids.indexOf(id);
        const idsBetweenSelections = ids.slice(
          Math.min(lastSelectedIndex, index),
          Math.max(lastSelectedIndex, index) + 1
        );

        const newSelectedIds = event.target.checked
          ? union(selectedIds, idsBetweenSelections)
          : difference(selectedIds, idsBetweenSelections);

        onSelect?.(
          isRowSelectable
            ? newSelectedIds.filter((id: Identifier) =>
                isRowSelectable(data.find((record) => record.id === id))
              )
            : newSelectedIds
        );
      } else {
        onToggleItem?.(id);
      }
    },
    [data, isRowSelectable, onSelect, onToggleItem, selectedIds]
  );

  if (isPending === true) {
    return (
      <DatagridLoading
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  const isEmptyData = isEmpty(data) || total === 0;

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  return (
    <DatagridContextProvider value={contextValue}>
      <OptionalResourceContextProvider value={resource}>
        <DatagridRoot sx={sx as any} className={clsx(DatagridClasses.root, className)}>
          {bulkActionButtons !== false ? (
            <BulkActionsToolbar>
              {isValidElement(bulkActionButtons) ? bulkActionButtons : defaultBulkActionButtons}
            </BulkActionsToolbar>
          ) : null}
          <div className={DatagridClasses.tableWrapper}>
            <Scrollbar>
              <Table
                ref={ref}
                className={DatagridClasses.table}
                size={size}
                {...sanitizeRestProps(rest)}
              >
                {createOrCloneElement(
                  header,
                  {
                    children,
                    sort,
                    data,
                    hasExpand: !!expand,
                    hasBulkActions,
                    isRowSelectable,
                    onSelect,
                    selectedIds,
                    setSort,
                  },
                  children
                )}
                {createOrCloneElement(
                  body,
                  {
                    expand,
                    rowClick,
                    data,
                    hasBulkActions,
                    hover,
                    onToggleItem: handleToggleItem,
                    resource,
                    rowSx,
                    rowStyle,
                    selectedIds,
                    isRowSelectable,
                  },
                  children
                )}
                {isEmptyData ? (
                  <TableCell
                    sx={{
                      p: 0,
                      borderRadius: 0,
                      '.MuiStack-root': {
                        borderRadius: 0,
                      },
                    }}
                    colSpan={React.Children.count(children) + 1}
                  >
                    <EmptyContent filled />
                  </TableCell>
                ) : null}
              </Table>
            </Scrollbar>
          </div>
        </DatagridRoot>
      </OptionalResourceContextProvider>
    </DatagridContextProvider>
  );
});

const createOrCloneElement = (element: any, props: any, children: any) =>
  isValidElement(element)
    ? cloneElement(element, props, children)
    : createElement(element, props, children);

export interface DatagridProps<RecordType extends RaRecord = any>
  extends Omit<TableProps, 'size' | 'classes' | 'onSelect'> {
  /**
   * The component used to render the body of the table. Defaults to <DatagridBody>.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#body
   */
  body?: ReactElement | ComponentType;

  /**
   * A class name to apply to the root table element
   */
  className?: string;

  /**
   * The component used to render the bulk action buttons. Defaults to <BulkDeleteButton>.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
   * @example
   * import { List, Datagrid, BulkDeleteButton } from 'react-admin';
   * import { Button } from '@mui/material';
   * import ResetViewsButton from './ResetViewsButton';
   *
   * const PostBulkActionButtons = () => (
   *     <>
   *         <ResetViewsButton label="Reset Views" />
   *         <BulkDeleteButton />
   *     </>
   * );
   *
   * export const PostList = () => (
   *     <List>
   *         <Datagrid bulkActionButtons={<PostBulkActionButtons />}>
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  bulkActionButtons?: ReactElement | false;

  /**
   * The component used to render the expand panel for each row.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#expand
   * @example
   * import { List, Datagrid, useRecordContext } from 'react-admin';
   *
   * const PostPanel = () => {
   *     const record = useRecordContext();
   *     return (
   *         <div dangerouslySetInnerHTML={{ __html: record.body }} />
   *     );
   * };
   *
   * const PostList = () => (
   *     <List>
   *         <Datagrid expand={<PostPanel />}>
   *             ...
   *         </Datagrid>
   *     </List>
   * )
   */
  expand?:
    | ReactElement
    | FC<{
        id: Identifier;
        record: RecordType;
        resource: string;
      }>;

  /**
   * The component used to render the header row. Defaults to <DatagridHeader>.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#header
   */
  header?: ReactElement | ComponentType;

  /**
   * Whether to allow only one expanded row at a time. Defaults to false.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#expandsingle
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * export const PostList = () => (
   *    <List>
   *       <Datagrid expandSingle>
   *          ...
   *      </Datagrid>
   *   </List>
   * );
   */
  expandSingle?: boolean;

  /**
   * Set to false to disable the hover effect on rows.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#hover
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * const PostList = () => (
   *     <List>
   *         <Datagrid hover={false}>
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  hover?: boolean;

  /**
   * The component used to render the empty table.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#empty
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * const CustomEmpty = () => <div>No books found</div>;
   *
   * const PostList = () => (
   *     <List>
   *         <Datagrid empty={<CustomEmpty />}>
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  empty?: ReactElement;

  /**
   * A function that returns whether the row for a record is expandable.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#isrowexpandable
   * @example
   * import { List, Datagrid, useRecordContext } from 'react-admin';
   *
   * const PostPanel = () => {
   *     const record = useRecordContext();
   *     return (
   *         <div dangerouslySetInnerHTML={{ __html: record.body }} />
   *     );
   * };
   *
   * const PostList = () => (
   *     <List>
   *         <Datagrid
   *             expand={<PostPanel />}
   *             isRowExpandable={row => row.has_detail}
   *         >
   *             ...
   *         </Datagrid>
   *     </List>
   * )
   */
  isRowExpandable?: (record: RecordType) => boolean;

  /**
   * A function that returns whether the row for a record is selectable.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#isrowselectable
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * export const PostList = () => (
   *     <List>
   *         <Datagrid isRowSelectable={ record => record.id > 300 }>
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  isRowSelectable?: (record: RecordType) => boolean;

  /**
   * Set to true to optimize datagrid rendering if the children never vary.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#optimized
   */
  optimized?: boolean;

  /**
   * The action to trigger when the user clicks on a row.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#rowclick
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * export const PostList = () => (
   *     <List>
   *         <Datagrid rowClick="edit">
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  rowClick?: string | RowClickFunction | false;

  /**
   * A function that returns the sx prop to apply to a row.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#rowsx
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * const postRowSx = (record, index) => ({
   *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
   * });
   * export const PostList = () => (
   *     <List>
   *         <Datagrid rowSx={postRowSx}>
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  rowSx?: (record: RecordType, index: number) => SxProps;

  /**
   * @deprecated use rowSx instead
   */
  rowStyle?: (record: RecordType, index: number) => any;

  /**
   * Density setting, can be either 'small' or 'medium'. Defaults to 'small'.
   *
   * @see https://marmelab.com/react-admin/Datagrid.html#size
   * @example
   * import { List, Datagrid } from 'react-admin';
   *
   * export const PostList = () => (
   *     <List>
   *         <Datagrid size="medium">
   *             ...
   *         </Datagrid>
   *     </List>
   * );
   */
  size?: 'medium' | 'small';

  // can be injected when using the component without context
  sort?: SortPayload;
  data?: RecordType[];
  isLoading?: boolean;
  isPending?: boolean;
  onSelect?: (ids: Identifier[]) => void;
  onToggleItem?: (id: Identifier) => void;
  setSort?: (sort: SortPayload) => void;
  selectedIds?: Identifier[];
  total?: number;
}

const injectedProps = [
  'isRequired',
  'setFilter',
  'setPagination',
  'limitChoicesToValue',
  'translateChoice',
  // Datagrid may be used as an alternative to SelectInput
  'field',
  'fieldState',
  'formState',
];

const sanitizeRestProps = (props: any) =>
  Object.keys(sanitizeListRestProps(props))
    .filter((propName) => !injectedProps.includes(propName) || propName === 'ref')
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

CGDatagrid.displayName = 'Datagrid';

const DatagridContextProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: DatagridContextValue;
}): ReactElement => <DatagridContext.Provider value={value}>{children}</DatagridContext.Provider>;
export const DatagridContext = createContext<DatagridContextValue>({});

DatagridContext.displayName = 'DatagridContext';

export type DatagridContextValue = {
  isRowExpandable?: (record: RaRecord) => boolean;
  expandSingle?: boolean;
};

export default CGDatagrid;
