export const assetsIconFiles = {
  BITCOIN: '/assets/icons/currencies/bitcoin.png',
  ETHEREUM: '/assets/icons/currencies/ethereum.png',
  TETHER: '/assets/icons/currencies/Tether.png',
  BNB: '/assets/icons/currencies/bnb-icon2_2x.png',
  SOL: '/assets/icons/currencies/solana.png',
  USDC: '/assets/icons/currencies/usdc.png',
  XRP: '/assets/icons/currencies/xrp-symbol-white-128.png',
  STETH: '/assets/icons/currencies/steth_logo.png',
  TON: '/assets/icons/currencies/ton_symbol.png',
  DOGECOIN: '/assets/icons/currencies/dogecoin.png',
  TRON: '/assets/icons/currencies/tron-logo.png',
  CARDANO: '/assets/icons/currencies/cardano.png',
  WSTETH: '/assets/icons/currencies/wstETH.png',
  AVALANCHE: '/assets/icons/currencies/Avalanche_Circle_RedWhite_Trans.png',
  WRAPPEDBITCOIN: '/assets/icons/currencies/wrapped_bitcoin_wbtc.png',
  SHIBAINU: '/assets/icons/currencies/shiba.png',
  WETH: '/assets/icons/currencies/weth.png',
  BITCOINCASH: '/assets/icons/currencies/bitcoin-cash-circle.png',
  POLKADOT: '/assets/icons/currencies/polkadot.png',
  CHAINLINK: '/assets/icons/currencies/chainlink-new-logo.png',
  LEO: '/assets/icons/currencies/leo-token.png',
  DAI: '/assets/icons/currencies/Badge_Dai.png',
  UNISWAP: '/assets/icons/currencies/uniswap-logo.png',
  LITECOIN: '/assets/icons/currencies/litecoin.png',
  NEARPROTOCOL: '/assets/icons/currencies/near.jpg',
  POLYGON: '/assets/icons/currencies/polygon.png',
  WEETH: '/assets/icons/currencies/weETH.png',
  KASPA: '/assets/icons/currencies/kaspa-icon-exchanges.png',
  INTERNETCOMPUTER: '/assets/icons/currencies/Internet_Computer_logo.png',
  PEPETOKEN: '/assets/icons/currencies/pepe-token.jpeg',
  USDE: '/assets/icons/currencies/USDE.png',
  APTOS: '/assets/icons/currencies/sui_asset.jpeg',
  MONERO: '/assets/icons/currencies/monero_logo.png',
  STELLAR: '/assets/icons/currencies/Stellar_symbol_black_RGB.png',
  ETHEREUMCLASSIC: '/assets/icons/currencies/ethereum-classic-logo.png',
  FIRSTDIGITAL: '/assets/icons/currencies/firstfigital.jpeg',
  CRO: '/assets/icons/currencies/cro_token_logo.png',
  ASI: '/assets/icons/currencies/ASI.png',
  WECHATIMAGE: '/assets/icons/currencies/WeChat_Image_20220118095654.png',
  SUI: '/assets/icons/currencies/sui_asset.jpeg',
  STACKS: '/assets/icons/currencies/Stacks_Logo_png.png',
  FILECOIN: '/assets/icons/currencies/filecoin.png',
  ARU: '/assets/icons/currencies/ARUsPeNQ_400x400.jpeg',
  AAVE: '/assets/icons/currencies/aave-token-round.png',
  TOKEN: '/assets/icons/currencies/token-logo.png',
  HBAR: '/assets/icons/currencies/hbar.png',
  VET: '/assets/icons/currencies/VET_Token_Icon.png',
  ARBITRUM: '/assets/icons/currencies/arb.jpg',
  COSMOS: '/assets/icons/currencies/cosmos_hub.png',
  IMMUTABLEX: '/assets/icons/currencies/immutableX-symbol-BLK-RGB.png',
  SECONDARYSYMBOL: '/assets/icons/currencies/Secondary_Symbol.png',
  MARKMAKER: '/assets/icons/currencies/Mark_Maker.png',
  RNDR: '/assets/icons/currencies/rndr.png',
  OPTIMISM: '/assets/icons/currencies/Optimism.png',
  WBTOKEN: '/assets/icons/currencies/wbt_token.png',
  ORT6SIEN: '/assets/icons/currencies/oRt6SiEN_400x400.jpg',
  DOGWIFHAT: '/assets/icons/currencies/dogwifhat.jpg',
  RETH: '/assets/icons/currencies/reth.png',
  GRAPHTOKEN: '/assets/icons/currencies/Graph_Token.png',
  ICON: '/assets/icons/currencies/icon_colour.png',
  FIL: '/assets/icons/currencies/filecoin.png',
  XMR: '/assets/icons/currencies/monero_logo.png',
  CHZ: '/assets/icons/currencies/chiliz.png',
  USDT: '/assets/icons/currencies/Tether.png',
  BTC: '/assets/icons/currencies/bitcoin.png',
  ETH: '/assets/icons/currencies/ethereum.png',
  DOGE: '/assets/icons/currencies/dogecoin.png',
  MATIC: '/assets/icons/currencies/polygon.png',
  BCH: '/assets/icons/currencies/6ad5509616a5fca9f389801052bea3fe.png',
  LTC: '/assets/icons/currencies/litecoin.png',
  CG11: '/assets/icons/currencies/CGO_200x200.png',
  ETC: '/assets/icons/currencies/60bf91c68afb0a00068efeef_ETC.png',
  OKB: '/assets/icons/currencies/okb.png',
  BRL: '/assets/icons/currencies/ic_brl.svg',
  INR: '/assets/icons/currencies/ic_inr.svg',
};

export const errorLinks = {
  RUNE: 'https://coin-images.coingecko.com/coins/images/10791/large/rune.png?1696510522', // error
  STPT: 'https://coin-images.coingecko.com/coins/images/11684/large/stpt.png?1696513055', // error
  KAVA: 'https://coin-images.coingecko.com/coins/images/10356/large/kava.png?1696503896', // error
  PERL: 'https://coin-images.coingecko.com/coins/images/11880/large/perlin.png?1696509866', // error
  SAND: 'https://coin-images.coingecko.com/coins/images/12165/large/sand.png?1696501851', // error
  CLO: 'https://coin-images.coingecko.com/coins/images/16411/large/clo.png?1696505113', // error
  WAXP: 'https://coin-images.coingecko.com/coins/images/3124/large/wax.png?1696505734', // error
  GNO: 'https://coin-images.coingecko.com/coins/images/424/large/Gnosis.png?1696504531', // error
  RLC: 'https://coin-images.coingecko.com/coins/images/530/large/rlc.png?1696507478', // error
  AUDIO: 'https://coin-images.coingecko.com/coins/images/11846/large/audius.png?1696512080', // error
  BTT: 'https://coin-images.coingecko.com/coins/images/828/large/btt.png?1696505178', // error
  ANT: 'https://coin-images.coingecko.com/coins/images/784/large/ant.png?1696506373', // error
  MKR: 'https://coin-images.coingecko.com/coins/images/1367/large/maker.png?1696505504', // error
  KNC: 'https://coin-images.coingecko.com/coins/images/109/large/kyber.png?1696507081', // error
  LDO: 'https://coin-images.coingecko.com/coins/images/18873/large/ldo.png?1696520170', // error
  PAXG: 'https://coin-images.coingecko.com/coins/images/5716/large/paxg.png?1696505706', // error
  CLV: 'https://coin-images.coingecko.com/coins/images/14870/large/clover.png?1696514233', // error
  NUM: 'https://coin-images.coingecko.com/coins/images/14076/large/num.png?1696507611', // error
  SGB: 'https://coin-images.coingecko.com/coins/images/13596/large/sgb.png?1696504886', // error
  SKL: 'https://coin-images.coingecko.com/coins/images/13003/large/skale.png?1696513752', // error
  RSR: 'https://coin-images.coingecko.com/coins/images/4559/large/reserve.png?1696512898', // error
  ZRX: 'https://coin-images.coingecko.com/coins/images/768/large/0x.png?1696505547', // error
  DCR: 'https://coin-images.coingecko.com/coins/images/504/large/decred.png?1696513283', // error
  TWT: 'https://coin-images.coingecko.com/coins/images/14835/large/trust_wallet_token.png?1696511122', // error
  FLOKI: 'https://coin-images.coingecko.com/coins/images/18185/large/Floki.png?1696512675', // error
  HNT: 'https://coin-images.coingecko.com/coins/images/9387/large/helium.png?1696511672', // error
  QNT: 'https://coin-images.coingecko.com/coins/images/7820/large/qnt.png?1696512561', // error
  HUSD: 'https://coin-images.coingecko.com/coins/images/10977/large/husd.png?1696512203', // error
  CVC: 'https://coin-images.coingecko.com/coins/images/711/large/civic.png?1696505708', // error
  WTC: 'https://coin-images.coingecko.com/coins/images/836/large/wtc.png?1696507081', // error
  CTXC: 'https://coin-images.coingecko.com/coins/images/6752/large/ctxc.png?1696507267', // error
  LOOM: 'https://coin-images.coingecko.com/coins/images/4429/large/loom-network.png?1696507487', // error
  MDT: 'https://coin-images.coingecko.com/coins/images/5102/large/mdt.png?1696506127', // error
  GRT: 'https://coin-images.coingecko.com/coins/images/13397/large/graph_token.png?1696507697', // error
  COVAL: 'https://coin-images.coingecko.com/coins/images/2754/large/coval.png?1696508104', // error
  BNT: 'https://coin-images.coingecko.com/coins/images/834/large/bnt.png?1696508911', // error
  LEND: 'https://coin-images.coingecko.com/coins/images/10724/large/aave.png?1696510155', // error
  PNT: 'https://coin-images.coingecko.com/coins/images/11789/large/pontoon.png?1696509393', // error
  NKN: 'https://coin-images.coingecko.com/coins/images/10043/large/nkn.png?1696507223', // error
  ORBS: 'https://coin-images.coingecko.com/coins/images/4661/large/orbs.png?1696507378', // error
  REN: 'https://coin-images.coingecko.com/coins/images/1337/large/ren.png?1696512522', // error
  RIF: 'https://coin-images.coingecko.com/coins/images/711/large/rif.png?1696512735', // error
  MITH: 'https://coin-images.coingecko.com/coins/images/14159/large/mith.png?1696508155', // error
  WAVES: 'https://coin-images.coingecko.com/coins/images/110/large/waves.png?1696512835', // error
  ALPHA: 'https://coin-images.coingecko.com/coins/images/10750/large/alpha.png?1696513160', // error
  COTI: 'https://coin-images.coingecko.com/coins/images/4418/large/coti.png?1696512148', // error
  BASICATTENTIONTOKEN:
    'https://coin-images.coingecko.com/coins/images/677/large/bat.png?1696513283', // error
  POND: 'https://coin-images.coingecko.com/coins/images/13314/large/pond.png?1696513500', // error
  MANA: 'https://coin-images.coingecko.com/coins/images/11021/large/decentraland.png?1696510458', // error
  SXP: 'https://coin-images.coingecko.com/coins/images/11780/large/sxp.png?1696509650', // error
  TRB: 'https://coin-images.coingecko.com/coins/images/9447/large/truth.png?1696510848', // error
  BLZ: 'https://coin-images.coingecko.com/coins/images/9306/large/bluzelle.png?1696513135', // error
  RARI: 'https://coin-images.coingecko.com/coins/images/9696/large/rari.png?1696513544', // error
  CRV: 'https://coin-images.coingecko.com/coins/images/12171/large/crv.png?1696513606', // error
  RGT: 'https://coin-images.coingecko.com/coins/images/12848/large/rgt.png?1696511468', // error
  TRX: 'https://coin-images.coingecko.com/coins/images/109/large/tron.png?1696502811', // error
  SHIB: 'https://coin-images.coingecko.com/coins/images/11939/large/shiba-inu.png?1696507880', // error
  CAKE: 'https://coin-images.coingecko.com/coins/images/10652/large/pancake.png?1696501837', // error
  BAND: 'https://coin-images.coingecko.com/coins/images/9152/large/band.png?1696506877', // error
  REI: 'https://coin-images.coingecko.com/coins/images/10343/large/rei.png?1696512213', // error
  DGB: 'https://coin-images.coingecko.com/coins/images/65/large/digi_byte.png?1696503403', // error
  XTZ: 'https://coin-images.coingecko.com/coins/images/1713/large/tezos.png?1696506444', // error
  YFI: 'https://coin-images.coingecko.com/coins/images/12114/large/yearn-finance.png?1696512324', // error
  TKO: 'https://coin-images.coingecko.com/coins/images/14215/large/tko.png?1696506811', // error
  XEM: 'https://coin-images.coingecko.com/coins/images/12139/large/xem.png?1696511584', // error
  STMX: 'https://coin-images.coingecko.com/coins/images/4514/large/stmx.png?1696507533', // error
  BEL: 'https://coin-images.coingecko.com/coins/images/13153/large/bel.png?1696504886', // error
  TROY: 'https://coin-images.coingecko.com/coins/images/4697/large/troy.png?1696505860', // error
  BTRST: 'https://coin-images.coingecko.com/coins/images/8884/large/btrst.png?1696506434', // error
  PPT: 'https://coin-images.coingecko.com/coins/images/1529/large/populous.png?1696509535', // error
  CVP: 'https://coin-images.coingecko.com/coins/images/18184/large/cvp.png?1696512304', // error
  SNX: 'https://coin-images.coingecko.com/coins/images/1270/large/snx.png?1696509502', // error
  CTSI: 'https://coin-images.coingecko.com/coins/images/12780/large/ctsi.png?1696506127', // error
  SUSHI: 'https://coin-images.coingecko.com/coins/images/12199/large/sushi.png?1696501851', // error
  ZEN: 'https://coin-images.coingecko.com/coins/images/650/large/zen.png?1696513166', // error
  EGLD: 'https://coin-images.coingecko.com/coins/images/12879/large/elrond.png?1696510953', // error
};

export const coinLinks: Record<string, string> = {
  ...assetsIconFiles,
  ...errorLinks,
};

// export const coinLinks = {
//   BITCOIN: 'https://coin-images.coingecko.com/coins/images/1/large/bitcoin.png?1696501400',
//   ETHEREUM: 'https://coin-images.coingecko.com/coins/images/279/large/ethereum.png?1696501628',
//   TETHER: 'https://coin-images.coingecko.com/coins/images/325/large/Tether.png?1696501661',
//   BNB: 'https://coin-images.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970',
//   SOL: 'https://coin-images.coingecko.com/coins/images/4128/large/solana.png?1718769756',
//   USDC: 'https://coin-images.coingecko.com/coins/images/6319/large/usdc.png?1696506694',
//   XRP: 'https://coin-images.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1696501442',
//   STETH: 'https://coin-images.coingecko.com/coins/images/13442/large/steth_logo.png?1696513206',
//   TON: 'https://coin-images.coingecko.com/coins/images/17980/large/ton_symbol.png?1696517498',
//   DOGECOIN: 'https://coin-images.coingecko.com/coins/images/5/large/dogecoin.png?1696501409',
//   TRON: 'https://coin-images.coingecko.com/coins/images/1094/large/tron-logo.png?1696502193',
//   CARDANO: 'https://coin-images.coingecko.com/coins/images/975/large/cardano.png?1696502090',
//   WSTETH: 'https://coin-images.coingecko.com/coins/images/18834/large/wstETH.png?1696518295',
//   AVALANCHE:
//     'https://coin-images.coingecko.com/coins/images/12559/large/Avalanche_Circle_RedWhite_Trans.png?1696512369',
//   WRAPPEDBITCOIN: 'https://coin-images.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1696507857',
//   SHIBAINU: 'https://coin-images.coingecko.com/coins/images/11939/large/shiba.png?1696511800',
//   WETH: 'https://coin-images.coingecko.com/coins/images/2518/large/weth.png?1696503332',
//   BITCOINCASH: 'https://coin-images.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1696501932',
//   POLKADOT: 'https://coin-images.coingecko.com/coins/images/12171/large/polkadot.png?1696512008',
//   CHAINLINK: 'https://coin-images.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1696502009',
//   LEO: 'https://coin-images.coingecko.com/coins/images/8418/large/leo-token.png?1696508607',
//   DAI: 'https://coin-images.coingecko.com/coins/images/9956/large/Badge_Dai.png?1696509996',
//   UNISWAP: 'https://coin-images.coingecko.com/coins/images/12504/large/uniswap-logo.png?1720676669',
//   LITECOIN: 'https://coin-images.coingecko.com/coins/images/2/large/litecoin.png?1696501400',
//   NEARPROTOCOL: 'https://coin-images.coingecko.com/coins/images/10365/large/near.jpg?1696510367',
//   POLYGON: 'https://coin-images.coingecko.com/coins/images/4713/large/polygon.png?1698233745',
//   WEETH: 'https://coin-images.coingecko.com/coins/images/33033/large/weETH.png?1701438396',
//   KASPA: 'https://coin-images.coingecko.com/coins/images/25751/large/kaspa-icon-exchanges.png?1696524837',
//   INTERNETCOMPUTER: 'https://coin-images.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1696514180',
//   PEPETOKEN: 'https://coin-images.coingecko.com/coins/images/29850/large/pepe-token.jpeg?1696528776',
//   USDE: 'https://coin-images.coingecko.com/coins/images/33613/large/USDE.png?1716355685',
//   APTOS: 'https://coin-images.coingecko.com/coins/images/26455/large/sui_asset.jpeg?1696525453',
//   MONERO: 'https://coin-images.coingecko.com/coins/images/69/large/monero_logo.png?1696501460',
//   STELLAR: 'https://coin-images.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1696501482',
//   ETHEREUMCLASSIC: 'https://coin-images.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1696501717',
//   FIRSTDIGITAL: 'https://coin-images.coingecko.com/coins/images/31079/large/firstfigital.jpeg?1696529912',
//   CRO: 'https://coin-images.coingecko.com/coins/images/7310/large/cro_token_logo.png?1696507599',
//   ASI: 'https://coin-images.coingecko.com/coins/images/5681/large/ASI.png?1719827289',
//   WECHATIMAGE: 'https://coin-images.coingecko.com/coins/images/4463/large/WeChat_Image_20220118095654.png?1696505053',
//   SUI: 'https://coin-images.coingecko.com/coins/images/26375/large/sui_asset.jpeg?1696525453',
//   STACKS: 'https://coin-images.coingecko.com/coins/images/2069/large/Stacks_Logo_png.png?1709979332',
//   FILECOIN: 'https://coin-images.coingecko.com/coins/images/12817/large/filecoin.png?1696512609',
//   ARU: 'https://coin-images.coingecko.com/coins/images/28452/large/ARUsPeNQ_400x400.jpeg?1696527447',
//   AAVE: 'https://coin-images.coingecko.com/coins/images/12645/large/aave-token-round.png?1720472354',
//   TOKEN: 'https://coin-images.coingecko.com/coins/images/30980/large/token-logo.png?1696529819',
//   HBAR: 'https://coin-images.coingecko.com/coins/images/3688/large/hbar.png?1696504364',
//   VET: 'https://coin-images.coingecko.com/coins/images/1167/large/VET_Token_Icon.png?1710013505',
//   ARBITRUM: 'https://coin-images.coingecko.com/coins/images/16547/large/arb.jpg?1721358242',
//   COSMOS: 'https://coin-images.coingecko.com/coins/images/1481/large/cosmos_hub.png?1696502525',
//   IMMUTABLEX: 'https://coin-images.coingecko.com/coins/images/17233/large/immutableX-symbol-BLK-RGB.png?1696516787',
//   SECONDARYSYMBOL: 'https://coin-images.coingecko.com/coins/images/12882/large/Secondary_Symbol.png?1696512670',
//   MARKMAKER: 'https://coin-images.coingecko.com/coins/images/1364/large/Mark_Maker.png?1696502423',
//   RNDR: 'https://coin-images.coingecko.com/coins/images/11636/large/rndr.png?1696511529',
//   OPTIMISM: 'https://coin-images.coingecko.com/coins/images/25244/large/Optimism.png?1696524385',
//   WBTOKEN: 'https://coin-images.coingecko.com/coins/images/27045/large/wbt_token.png?1696526096',
//   ORT6SIEN: 'https://coin-images.coingecko.com/coins/images/4343/large/oRt6SiEN_400x400.jpg?1696504946',
//   DOGWIFHAT: 'https://coin-images.coingecko.com/coins/images/33566/large/dogwifhat.jpg?1702499428',
//   RETH: 'https://coin-images.coingecko.com/coins/images/20764/large/reth.png?1696520159',
//   GRAPHTOKEN: 'https://coin-images.coingecko.com/coins/images/13397/large/Graph_Token.png?1696513159',
//   ICON: 'https://coin-images.coingecko.com/coins/images/11610/large/icon_colour.png?1696511504',
//   RUNE: 'https://coin-images.coingecko.com/coins/images/10791/large/rune.png?1696510522', // error
//   STPT: 'https://coin-images.coingecko.com/coins/images/11684/large/stpt.png?1696513055', // error
//   KAVA: 'https://coin-images.coingecko.com/coins/images/10356/large/kava.png?1696503896', // error
//   FIL: 'https://coin-images.coingecko.com/coins/images/12817/large/filecoin.png?1696510947',
//   PERL: 'https://coin-images.coingecko.com/coins/images/11880/large/perlin.png?1696509866', // error
//   SAND: 'https://coin-images.coingecko.com/coins/images/12165/large/sand.png?1696501851', // error
//   CLO: 'https://coin-images.coingecko.com/coins/images/16411/large/clo.png?1696505113', // error
//   WAXP: 'https://coin-images.coingecko.com/coins/images/3124/large/wax.png?1696505734', // error
//   GNO: 'https://coin-images.coingecko.com/coins/images/424/large/Gnosis.png?1696504531', // error
//   RLC: 'https://coin-images.coingecko.com/coins/images/530/large/rlc.png?1696507478', // error
//   AUDIO: 'https://coin-images.coingecko.com/coins/images/11846/large/audius.png?1696512080', // error
//   XMR: 'https://coin-images.coingecko.com/coins/images/69/large/monero_logo.png?1696501478',
//   BTT: 'https://coin-images.coingecko.com/coins/images/828/large/btt.png?1696505178', // error
//   ANT: 'https://coin-images.coingecko.com/coins/images/784/large/ant.png?1696506373', // error
//   MKR: 'https://coin-images.coingecko.com/coins/images/1367/large/maker.png?1696505504', // error
//   KNC: 'https://coin-images.coingecko.com/coins/images/109/large/kyber.png?1696507081', // error
//   LDO: 'https://coin-images.coingecko.com/coins/images/18873/large/ldo.png?1696520170', // error
//   PAXG: 'https://coin-images.coingecko.com/coins/images/5716/large/paxg.png?1696505706', // error
//   CLV: 'https://coin-images.coingecko.com/coins/images/14870/large/clover.png?1696514233', // error
//   NUM: 'https://coin-images.coingecko.com/coins/images/14076/large/num.png?1696507611', // error
//   SGB: 'https://coin-images.coingecko.com/coins/images/13596/large/sgb.png?1696504886', // error
//   SKL: 'https://coin-images.coingecko.com/coins/images/13003/large/skale.png?1696513752', // error
//   RSR: 'https://coin-images.coingecko.com/coins/images/4559/large/reserve.png?1696512898', // error
//   CHZ: 'https://coin-images.coingecko.com/coins/images/8834/large/chiliz.png?1696510155',
//   ZRX: 'https://coin-images.coingecko.com/coins/images/768/large/0x.png?1696505547', // error
//   DCR: 'https://coin-images.coingecko.com/coins/images/504/large/decred.png?1696513283', // error
//   USDT: 'https://coin-images.coingecko.com/coins/images/325/large/Tether.png?1696501661',
//   TWT: 'https://coin-images.coingecko.com/coins/images/14835/large/trust_wallet_token.png?1696511122', // error
//   FLOKI: 'https://coin-images.coingecko.com/coins/images/18185/large/Floki.png?1696512675', // error
//   HNT: 'https://coin-images.coingecko.com/coins/images/9387/large/helium.png?1696511672', // error
//   QNT: 'https://coin-images.coingecko.com/coins/images/7820/large/qnt.png?1696512561', // error
//   HUSD: 'https://coin-images.coingecko.com/coins/images/10977/large/husd.png?1696512203', // error
//   CVC: 'https://coin-images.coingecko.com/coins/images/711/large/civic.png?1696505708', // error
//   WTC: 'https://coin-images.coingecko.com/coins/images/836/large/wtc.png?1696507081', // error
//   CTXC: 'https://coin-images.coingecko.com/coins/images/6752/large/ctxc.png?1696507267', // error
//   LOOM: 'https://coin-images.coingecko.com/coins/images/4429/large/loom-network.png?1696507487', // error
//   MDT: 'https://coin-images.coingecko.com/coins/images/5102/large/mdt.png?1696506127', // error
//   GRT: 'https://coin-images.coingecko.com/coins/images/13397/large/graph_token.png?1696507697', // error
//   COVAL: 'https://coin-images.coingecko.com/coins/images/2754/large/coval.png?1696508104', // error
//   BNT: 'https://coin-images.coingecko.com/coins/images/834/large/bnt.png?1696508911', // error
//   LEND: 'https://coin-images.coingecko.com/coins/images/10724/large/aave.png?1696510155', // error
//   PNT: 'https://coin-images.coingecko.com/coins/images/11789/large/pontoon.png?1696509393', // error
//   NKN: 'https://coin-images.coingecko.com/coins/images/10043/large/nkn.png?1696507223', // error
//   ORBS: 'https://coin-images.coingecko.com/coins/images/4661/large/orbs.png?1696507378', // error
//   REN: 'https://coin-images.coingecko.com/coins/images/1337/large/ren.png?1696512522', // error
//   RIF: 'https://coin-images.coingecko.com/coins/images/711/large/rif.png?1696512735', // error
//   MITH: 'https://coin-images.coingecko.com/coins/images/14159/large/mith.png?1696508155', // error
//   WAVES: 'https://coin-images.coingecko.com/coins/images/110/large/waves.png?1696512835', // error
//   ALPHA: 'https://coin-images.coingecko.com/coins/images/10750/large/alpha.png?1696513160', // error
//   COTI: 'https://coin-images.coingecko.com/coins/images/4418/large/coti.png?1696512148', // error
//   BASICATTENTIONTOKEN: 'https://coin-images.coingecko.com/coins/images/677/large/bat.png?1696513283', // error
//   POND: 'https://coin-images.coingecko.com/coins/images/13314/large/pond.png?1696513500', // error
//   MANA: 'https://coin-images.coingecko.com/coins/images/11021/large/decentraland.png?1696510458', // error
//   SXP: 'https://coin-images.coingecko.com/coins/images/11780/large/sxp.png?1696509650', // error
//   TRB: 'https://coin-images.coingecko.com/coins/images/9447/large/truth.png?1696510848', // error
//   BLZ: 'https://coin-images.coingecko.com/coins/images/9306/large/bluzelle.png?1696513135', // error
//   RARI: 'https://coin-images.coingecko.com/coins/images/9696/large/rari.png?1696513544', // error
//   CRV: 'https://coin-images.coingecko.com/coins/images/12171/large/crv.png?1696513606', // error
//   RGT: 'https://coin-images.coingecko.com/coins/images/12848/large/rgt.png?1696511468', // error
//   BTC: 'https://coin-images.coingecko.com/coins/images/1/large/bitcoin.png?1696502730',
//   ETH: 'https://coin-images.coingecko.com/coins/images/279/large/ethereum.png?1696501228',
//   DOGE: 'https://coin-images.coingecko.com/coins/images/5/large/dogecoin.png?1696503698',
//   MATIC: 'https://coin-images.coingecko.com/coins/images/4713/large/polygon.png?1696504982',
//   BCH: 'https://img.bitgetimg.com/multiLang/coin_img/6ad5509616a5fca9f389801052bea3fe.png',
//   TRX: 'https://coin-images.coingecko.com/coins/images/109/large/tron.png?1696502811', // error
//   LTC: 'https://coin-images.coingecko.com/coins/images/2/large/litecoin.png?1696501867',
//   SHIB: 'https://coin-images.coingecko.com/coins/images/11939/large/shiba-inu.png?1696507880', // error
//   CAKE: 'https://coin-images.coingecko.com/coins/images/10652/large/pancake.png?1696501837', // error
//   BAND: 'https://coin-images.coingecko.com/coins/images/9152/large/band.png?1696506877', // error
//   REI: 'https://coin-images.coingecko.com/coins/images/10343/large/rei.png?1696512213', // error
//   DGB: 'https://coin-images.coingecko.com/coins/images/65/large/digi_byte.png?1696503403', // error
//   XTZ: 'https://coin-images.coingecko.com/coins/images/1713/large/tezos.png?1696506444', // error
//   YFI: 'https://coin-images.coingecko.com/coins/images/12114/large/yearn-finance.png?1696512324', // error
//   TKO: 'https://coin-images.coingecko.com/coins/images/14215/large/tko.png?1696506811', // error
//   XEM: 'https://coin-images.coingecko.com/coins/images/12139/large/xem.png?1696511584', // error
//   STMX: 'https://coin-images.coingecko.com/coins/images/4514/large/stmx.png?1696507533', // error
//   BEL: 'https://coin-images.coingecko.com/coins/images/13153/large/bel.png?1696504886', // error
//   TROY: 'https://coin-images.coingecko.com/coins/images/4697/large/troy.png?1696505860', // error
//   BTRST: 'https://coin-images.coingecko.com/coins/images/8884/large/btrst.png?1696506434', // error
//   PPT: 'https://coin-images.coingecko.com/coins/images/1529/large/populous.png?1696509535', // error
//   CVP: 'https://coin-images.coingecko.com/coins/images/18184/large/cvp.png?1696512304', // error
//   SNX: 'https://coin-images.coingecko.com/coins/images/1270/large/snx.png?1696509502', // error
//   CTSI: 'https://coin-images.coingecko.com/coins/images/12780/large/ctsi.png?1696506127', // error
//   SUSHI: 'https://coin-images.coingecko.com/coins/images/12199/large/sushi.png?1696501851', // error
//   ZEN: 'https://coin-images.coingecko.com/coins/images/650/large/zen.png?1696513166', // error
//   EGLD: 'https://coin-images.coingecko.com/coins/images/12879/large/elrond.png?1696510953', // error
//   CG11: 'https://assets.coingecko.com/coins/images/25682/standard/CGO_200x200.png?1696524810',
//   ETC: 'https://assets-currency.kucoin.com/60bf91c68afb0a00068efeef_ETC.png',
//   OKB: 'https://www.okx.com/cdn/oksupport/asset/currency/icon/okb.png',
// };
