import { mapValues } from 'lodash';
import { stringify } from 'querystring';
import simpleRestProvider from 'ra-data-simple-rest';
import {
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetManyResult,
  HttpError,
  Options,
  QueryFunctionContext,
  RaRecord,
  fetchUtils,
} from 'react-admin';
import { getAuth } from './AppAuthProvider';

const baseUrl = process.env.REACT_APP_API_URL as string;

function modifyFilterParams(url: string) {
  const uri = new URL(url);
  const filterValue = uri.searchParams.get('filter');

  if (filterValue) {
    uri.searchParams.delete('filter');
    uri.searchParams.set('query', filterValue);
  }
  return uri.toString();
}

const countHeader = 'x-content-range';
export async function httpClient(_url: string, options: Options = {}) {
  const url = modifyFilterParams(_url);
  const { accessToken } = (await getAuth()) || {};

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };
  options.headers = new Headers(headers);
  const res = await fetchUtils.fetchJson(url, options).catch((err) => {
    if (err instanceof HttpError) {
      const { message, body, status } = err;
      throw new HttpError(message + '', status, body);
    }
    throw err;
  });

  if (res.headers.get(countHeader)) return res;
  const total = JSON.parse(res.body)?.total ?? 0;

  const responseHeaders = new Headers({
    ...res.headers,
    [countHeader]: total,
  });

  res.headers = responseHeaders;

  return res;
}

async function getPlayerById(playerId: string) {
  return httpClient(`/player/${playerId}`).then(({ json }) => json);
}

async function getList(resource: string, params: GetListParams) {
  const { page = 1, perPage = 10 } = params.pagination || {};

  const search = toSearch({
    sort: JSON.stringify(params.sort),
    page: page + '',
    size: perPage + '',
    query: JSON.stringify(params.filter),
  });

  if (resource === 'user') {
    resource = 'users';
  }
  const url = `${baseUrl}/${resource}${search}`;

  return httpClient(url).then(({ json }) => json);
}

function toSearch(params: Record<string, string | number>) {
  const searchParams = new URLSearchParams(mapValues(params, (v) => v.toString()));
  const search = searchParams.toString();
  return search ? '?' + search : '';
}

async function getManyReference(resource: string, params: GetManyReferenceParams) {
  const { page = 1, perPage = 10 } = params.pagination;

  const search = toSearch({
    sort: JSON.stringify(params.sort),
    page,
    size: perPage,
    query: JSON.stringify({
      ...params.filter,
      [params.target]: params.id,
    }),
  });

  const url = `${baseUrl}/${resource}?${search}`;

  return httpClient(url).then(({ json }) => json);
}

const dataProvider = simpleRestProvider(baseUrl, httpClient, countHeader);

export default {
  ...dataProvider,
  getMany,
  getList,
  getManyReference,
  getPlayerById,
};

function getMany<RecordType extends RaRecord = any>(
  resource: string,
  params: GetManyParams<RecordType> & QueryFunctionContext
): Promise<GetManyResult<RecordType>> {
  const query = {
    filter: JSON.stringify({ id: params.ids }),
  };
  const url = `${baseUrl}/${resource}?${stringify(query)}`;
  return httpClient(url, { signal: params?.signal }).then(({ json }) => {
    return {
      data: json?.data || [],
    };
  });
}
