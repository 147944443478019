import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CopyBtn from '../../../../../components/button/CopyBtn';
import { currency } from './Currency';
import { GameIds } from 'src/configs/game.config';
import { useTranslate } from 'react-admin';
import { colors } from 'src/configs/color.config';
import { TIME_FORMAT } from 'src/configs/common.config';
import CopyAll from 'src/components/button/CopyAll';

export function HashBetDetail({ order }: { order: any }) {
  const t = useTranslate();
  const gameID = order.gameType;
  const currencyUsed = order.currency;

  const isNiuNiuGame = gameID === GameIds.H_NIUNIU;
  const isNiuNiuTie = order.gameResult === 'TIE' && isNiuNiuGame;
  const isWin = order.bet === order.gameResult;
  const resultTitle = isNiuNiuTie ? 'tie' : isWin ? 'win' : 'lose';

  const betAmount = Number(order.amount);
  const freezeAmount = Number(order.freezeAmount ?? '0');
  const rewardAmount = Number(order.rewardAmount);
  const niuniuMultitple = Number(order.niuNiuMultiple ?? '1');

  let amountChange = isWin ? rewardAmount : -betAmount;
  if (isNiuNiuGame) {
    if (isNiuNiuTie) {
      amountChange = freezeAmount + betAmount;
    } else {
      amountChange = isWin
        ? rewardAmount + freezeAmount + betAmount
        : -betAmount * Number(order.niuNiuMultiple ?? '1');
    }
  }

  const stateColor = amountChange > 0 ? colors.itemGreen : '#ff5959';
  const endTime = order.game?.endTime ?? order.endTime;

  function generNiuNiuResult() {
    let niuniuReturnAmount = 0;
    let niuniuValidBet = 0;
    if (isNiuNiuGame) {
      if (isNiuNiuTie) {
        niuniuReturnAmount = freezeAmount + betAmount;
      } else {
        niuniuReturnAmount = isWin
          ? freezeAmount
          : betAmount + freezeAmount - betAmount * niuniuMultitple;
      }

      niuniuValidBet = order.niuNiuValidBet
        ? Number(order.niuNiuValidBet)
        : betAmount * niuniuMultitple * Number(order.winRate ?? '1');
    }

    return (
      <>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.freeze')}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography fontWeight={400}>{order.freezeAmount} </Typography>
              {currency.find((i) => i.name === currencyUsed)?.icon}
            </Box>
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.return')}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography fontWeight={400}>{niuniuReturnAmount}</Typography>
              {currency.find((i) => i.name === currencyUsed)?.icon}
            </Box>
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.validBet')}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography fontWeight={400}>{niuniuValidBet}</Typography>
              {currency.find((i) => i.name === currencyUsed)?.icon}
            </Box>
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <Box>
      <Stack px={4} pt={2} gap={0}>
        <Typography fontWeight={600} fontSize="18px" mt={2}>
          {order.game}
        </Typography>
        <Box m={0}>
          <Box display={'flex'} alignItems="center" gap={1}>
            <Typography fontSize={32} fontWeight={700} color={stateColor}>
              {amountChange > 0 ? '+' : ''} {amountChange}{' '}
            </Typography>
            {currency.find((i) => i.name === order.currency)?.icon}
          </Box>
          <Typography color={stateColor}>{t(`module.game.gameDetail.${resultTitle}`)}</Typography>
        </Box>
      </Stack>
      <Box p={4}>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.game')}</Typography>
            <Typography fontWeight={400}>{order.game}</Typography>
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.betType')}</Typography>
            <Typography fontWeight={400}>{t(`${order.bet}`)}</Typography>
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.betAmount')}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography fontWeight={400}>{order.amount} </Typography>
              {currency.find((i) => i.name === currencyUsed)?.icon}
            </Box>
          </Box>
        </Stack>
        {gameID === GameIds.H_NIUNIU && generNiuNiuResult()}

        {isWin ? (
          <Stack>
            <Box display="flex" justifyContent="space-between" py={2}>
              <Typography>{t('module.game.gameDetail.bonusAmount')}</Typography>
              <Box display="flex" gap={1}>
                <Typography fontWeight={400}>
                  {isNiuNiuGame ? betAmount + rewardAmount : order.rewardAmount}
                </Typography>
                {currency.find((i) => i.name === currencyUsed)?.icon}
              </Box>
            </Box>
          </Stack>
        ) : undefined}

        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.betTime')}</Typography>
            <Typography fontWeight={400}>
              {order.createdAt ? dayjs(order.createdAt).format(TIME_FORMAT) : '__'}
            </Typography>
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" justifyContent="space-between" py={2}>
            <Typography>{t('module.game.gameDetail.drawTime')}</Typography>
            <Typography fontWeight={400}>
              {endTime ? dayjs(endTime).format(TIME_FORMAT) : '__'}
            </Typography>
          </Box>
        </Stack>

        <Box>
          <hr style={{ borderColor: '#434343' }} />
          <Box display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <Typography>{t('module.game.gameDetail.lotteryHash')}</Typography>
            <CopyBtn text={order?.lotteryHash || ''} />
          </Box>
          <Typography
            fontWeight={400}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {order?.lotteryHash}
          </Typography>
        </Box>

        <Box>
          <hr style={{ borderColor: '#434343' }} />
          <Box display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <Typography>{t('module.game.gameDetail.serverSeed')}</Typography>
            <CopyBtn text={order?.serverSeed || ''} />
          </Box>
          <Typography
            fontWeight={400}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {order?.serverSeed}
          </Typography>
        </Box>

        <Box>
          <hr style={{ borderColor: '#434343' }} />

          <Box display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <Typography>{t('module.game.gameDetail.publicChainHash')}</Typography>
            <CopyBtn text={order?.publicChainHash || ''} />
          </Box>
          <Typography
            fontWeight={400}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {order?.publicChainHash}
          </Typography>
        </Box>

        <CopyAll
          cpyTxt={`${order?.lotteryHash}\n${order?.serverSeed}\n${order?.publicChainHash}`}
        />

        <Box>
          <hr style={{ borderColor: '#434343' }} />
          <Box display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <Typography>{t('module.game.gameDetail.resultLotteryHash')}</Typography>
            <CopyBtn text={order?.lotteryResultHash || ''} />
          </Box>
          <Typography
            fontWeight={400}
            sx={{
              overflowWrap: 'anywhere',
            }}
          >
            {order?.lotteryResultHash}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
