export class Resources {
  static readonly user = 'player';
  static readonly userStatistics = 'player/statistics';
  static readonly agency = 'agency';
  static readonly currency = 'currency';
  static readonly game = 'game';
  static readonly gameCategory = 'game/category';
  static readonly gameDetail = 'game-detail';
  static readonly gameRule = 'game-rule';
  static readonly gameDocument = 'game-document';
  static readonly transaction = 'transaction';
  static readonly setting = 'setting';
  static readonly gameStatistics = 'game/statistics';
  static readonly hashMonitor = 'hash-monitor';
  static readonly retryRewardHashGame = 'hash-monitor/retry-transaction';
  static readonly users = 'users';
}
