import { HashBetDetail } from './components/HashBetDetail';
import { Box, Button, Dialog, IconButton } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CryptoOrderDetail } from './components/CryptoOrderDetail';
import { GameIds } from 'src/configs/game.config';
import { BoOrderDetail } from './components/BoOrderDetail';
import {useTranslate} from "react-admin";

interface IProps {
  order: any;
  gameId: string;
}

export interface SimpleDialogProps {
  gameId: string;
  order: any;
  open: boolean;
  onClose: () => void;
}

function BetdetailDialog(props: SimpleDialogProps) {
  const { open, order, onClose } = props;

  return (
    <Dialog onClose={onClose} open={open} scroll="body" fullWidth>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          bgcolor: 'var(--palette-background-default)',
        }}
      >
        {props.gameId === GameIds.C_FUTURE ? <CryptoOrderDetail order={order} /> : undefined}
        {props.gameId === GameIds.C_BO ? <BoOrderDetail order={order} /> : undefined}
        {props.gameId !== GameIds.C_FUTURE && props.gameId !== GameIds.C_BO ? (
          <HashBetDetail order={order} />
        ) : undefined}
      </Box>
    </Dialog>
  );
}

export default function BetDetail({ order, gameId }: IProps) {
  const [open, setOpen] = useState(false);
  const t = useTranslate()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t('module.game.gameDetail.detail')}
      </Button>
      <BetdetailDialog open={open} order={order} gameId={gameId} onClose={handleClose} />
    </div>
  );
}
