import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { FaHistory, FaLock, FaUnlock } from 'react-icons/fa';
import { FaRegFaceSadTear } from 'react-icons/fa6';
import { MdLogout } from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import { get } from 'lodash';

import CGSaveButton from '../../components/CGSaveButton';
import * as playerService from '../../services/playerService';
import { formatMoney } from '../../utils/format-number';
import GameCard from '../game/GameCard';
import { Resources } from '../resource';
import AccountDialog from './AccountDialog';
import WinLoseProgress from 'src/components/progress-bar/WinLoseProgress';

const AccountPanel = () => {
  const record = useRecordContext();
  const [expandData, setExpandData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [histories, setHistories] = React.useState(null);
  const translate = useTranslate();

  React.useEffect(() => {
    if (record?.id) {
      setLoading(true);
      playerService
        .getPlayerById(record.id || '')
        .then((response: any) => {
          const data = (response?.data?.gameDatas || []).filter((x: any) =>
            get(x, 'aggregate.betCount')
          );
          setExpandData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [record]);

  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
      gap={3}
    >
      <Box sx={{ width: '100%', maxHeight: 300, overflow: 'auto', marginLeft: '18px' }} gap={1}>
        <Typography variant={'subtitle1'}>User games</Typography>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexFlow: 'column wrap',
              alignItems: 'center',
            }}
          >
            <Box>
              <CgSpinner size={30} />
            </Box>
          </Box>
        ) : (
          <>
            {!!expandData?.length ? (
              <Box sx={{ display: 'flex', flexFlow: 'row wrap', p: 2 }} gap={2}>
                {expandData.map((game: any) => (
                  <GameCard
                    title={game.name}
                    // label={null}
                    labelLeft="module.user.betAmount"
                    valueLeft={game?.aggregate?.betAmount}
                    labelCenter="module.user.betCount"
                    valueCenter={game?.aggregate?.betCount}
                    labelRight="module.user.winLose"
                    valueRight={
                      <>
                        <div>
                          {game?.aggregate?.win || '0'}/{game?.aggregate?.lose || '0'}
                        </div>
                        {game?.aggregate?.win === 0 && game?.aggregate?.lose === 0 ? undefined : (
                          <Stack spacing={2} sx={{ width: '100%' }}>
                            <WinLoseProgress
                              value={
                                (game?.aggregate?.win /
                                  (game?.aggregate?.win + game?.aggregate?.lose)) *
                                  100 || 0
                              }
                              backgroundColor="red"
                            />
                          </Stack>
                        )}
                      </>
                    }
                    bottomRightButton={{
                      title: 'module.user.betHistory',
                      icon: FaHistory,
                      disabled: false,
                      onClick: () => setHistories(game.histories || []),
                      link: `/${Resources.gameDetail}?filter=${JSON.stringify({
                        playerIds: record?.id || '',
                        games: game.id || '',
                        agencyIds: record?.agencyId,
                      })}`,
                    }}
                    cardStyle={{
                      border: 'solid 1px #eee',
                      maxWidth: 450,
                    }}
                  />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexFlow: 'column wrap',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <FaRegFaceSadTear size={30} />
                </Box>
                <Box>No game accessed</Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <CGSaveButton
            resource={Resources.user}
            startIcon={<MdLogout size={15} />}
            variant="outlined"
            type="edit"
            label="module.user.logUserOut"
            id={`${record?.id}/logout`}
            message={{ success: 'module.user.logoutUserSuccess' }}
            data={{}}
          />
          {record?.status === 'LOCK' ? (
            <CGSaveButton
              resource={Resources.user}
              startIcon={<FaUnlock size={12} />}
              variant="outlined"
              color="success"
              type="edit"
              label="module.user.unlockUser"
              id={`${record?.id}/unlock`}
              message={{ success: 'module.user.unlockUserSuccess' }}
              data={{}}
            />
          ) : (
            <CGSaveButton
              resource={Resources.user}
              startIcon={<FaLock size={12} />}
              variant="outlined"
              color="error"
              type="edit"
              label="module.user.lockUser"
              id={`${record?.id}/lock`}
              message={{ success: 'module.user.lockUserSuccess' }}
              data={{}}
            />
          )}
        </Box>
        {!!record?.betAmount && (
          <div>
            <Typography>
              {translate('module.user.totalWinLose')}: {record?.win}/{record?.lose}
            </Typography>
            <Typography>
              {translate('module.user.totalBet')}:{' '}
              {formatMoney(record?.betAmount, undefined, { maximumFractionDigits: 3 })}
            </Typography>
          </div>
        )}
      </Box>

      <AccountDialog selectedValue={histories} open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default AccountPanel;
