import { ResourceOptions } from 'react-admin';
import { FaRegUser } from 'react-icons/fa';
import { Resources } from '../resource';

export { default as list } from './List';

export const name = Resources.hashMonitor;
export const icon = FaRegUser;
export const options: ResourceOptions = {
  label: 'resource.account',
};
