import {
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  TextField,
  TopToolbar,
  useListController,
  useTranslate,
} from 'react-admin';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { DashboardContent } from '../../../../layouts/dashboard';
import CGDatagrid from '../../components/CGDatagrid';
import { CgDateInput } from '../../components/CgDateInput';
import { CgList } from '../../components/CgList';
import CGSaveButton from '../../components/CGSaveButton';
import CGTextInput from '../../components/CGTextInput';
import FilterGroup from '../../components/FilterGroup';
import { Resources } from '../resource';

function ListFilter(props: any) {
  const { filterValues } = useListController();

  return (
    <Filter {...(props || {})}>
      <FilterGroup alwaysOn spacing={0} p={0}>
        <FilterGroup>
          <CGTextInput label="hashMonitor.searchById" source="lotteryResultHash" />
          <CgDateInput label="common.fromTime" source="fromTime" sx={{ width: 200 }} />
          <CgDateInput
            label="common.toTime"
            source="toTime"
            sx={{ width: 200 }}
            type="to"
            minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
          />
        </FilterGroup>
      </FilterGroup>
    </Filter>
  );
}

function ListActions() {
  const { filterValues } = useListController();
  return (
    <TopToolbar
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pr: 2,
      }}
    >
      <CGSaveButton
        label="hashMonitor.retryForFiltered"
        variant="contained"
        color="primary"
        resource={Resources.retryRewardHashGame}
        data={filterValues}
        type={'create'}
        size="large"
        message={{
          success: 'hashMonitor.retryRewardSuccess',
        }}
      />
    </TopToolbar>
  );
}

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.hashMonitor')}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <CgList filters={<ListFilter />} actions={<ListActions />}>
        <CGDatagrid rowClick={false} bulkActionButtons={false}>
          <FunctionField
            source="lotteryResultHash"
            label="hashMonitor.lotteryResultHash"
            render={(record) => {
              const text = record?.lotteryResultHash + '';
              return (
                <Typography variant="body2" maxWidth={100} noWrap>
                  {text}
                </Typography>
              );
            }}
          />
          <DateField
            source="game.startTime"
            label="hashMonitor.gameFromTime"
            showTime
            // width={250}
            maxWidth={250}
          />
          <DateField
            source="game.endTime"
            label="hashMonitor.gameToTime"
            showTime
            // width={250}
            maxWidth={250}
          />
          <ReferenceField source="order.userId" reference={Resources.user} label="hashMonitor.user">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="status" label="hashMonitor.status" />
          <TextField source="error" label="hashMonitor.error" />
          <TextField source="txReference" label="hashMonitor.txReference" />
          <DateField source="createdAt" label="common.time" showTime />
        </CGDatagrid>
      </CgList>
    </DashboardContent>
  );
}

export default View;
