export enum GameIds {
  GAME_HASH = 'GAME_HASH',
  H_BP = 'H_BP',
  H_OE = 'H_OE',
  H_NIUNIU = 'H_NIUNIU',
  H_LUCKY = 'H_LUCKY',
  H_BS = 'H_BS',
  C_FUTURE = 'C_FUTURE',
  C_BO = 'C_BO',
}
