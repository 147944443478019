import {DialogActions, DialogContent, DialogTitle} from '@mui/material';
import MuiButton from '@mui/material/Button';
import {
  AutocompleteInput,
  Button,
  ButtonProps,
  Create,
  CreateContextProvider,
  CreateProps,
  Edit,
  EditContextProvider,
  EditProps,
  Form,
  FormProps,
  Identifier,
  RaRecord,
  ReferenceInput,
  SaveButton,
  SelectArrayInputProps,
  SelectInput,
  SelectInputProps,
  useCreateController,
  useEditController,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Resources } from '../resources/resource';
import {RecordType} from "zod";

export function SelectArrayReferneceInput({
  source,
  alwaysOn,
  reference,
  multiple = true,
  ...props
}: SelectArrayInputProps & { reference: string, multiple?: boolean }) {
  return (
    <ReferenceInput
      source={source || 'ids'}
      reference={reference}
      alwaysOn={alwaysOn}
      filter={{ optimize: true }}
    >
      <AutocompleteInput sx={{ minWidth: 200 }} {...props} multiple={multiple} />
    </ReferenceInput>
  );
}
export function SelectReferenceInput({
  reference,
  source,
  alwaysOn,
  emptyText,
  ...props
}: SelectInputProps & { reference: string }) {
  return (
    <ReferenceInput
      source={source || 'id'}
      reference={reference}
      alwaysOn={alwaysOn}
      filter={{ optimize: true }}
    >
      <SelectInput {...props} emptyText={emptyText || 'module.game.all'} />
    </ReferenceInput>
  );
}

export function SelectListAgencyInput(props: SelectArrayInputProps & {multiple?: boolean}) {
  const {multiple = true} = props
  return <SelectArrayReferneceInput {...props} reference={Resources.agency} multiple={multiple} />;
}

export function SelectAgencyInput(props: SelectArrayInputProps & {multiple?: boolean}) {
  return <SelectArrayReferneceInput {...props} reference={Resources.agency} multiple={false} />;
}

export function SelectListCurrencyInput(props: SelectArrayInputProps) {
  return <SelectArrayReferneceInput {...props} optionValue="code" reference={Resources.currency} />;
}
export function SelectCurrencyInput(props: SelectInputProps) {
  return <SelectReferenceInput {...props} reference={Resources.currency} />;
}
export function SelectGameInput(props: SelectArrayInputProps) {
  return <SelectArrayReferneceInput {...props} reference={Resources.game} multiple={false} />;
}
export function SelectListGameInput(props: SelectArrayInputProps) {
  return <SelectArrayReferneceInput {...props} reference={Resources.game} />;
}

export function SelectGameCategoryInput(props: SelectArrayInputProps & {multiple?: boolean}) {
  const {multiple = false} = props
  return <SelectArrayReferneceInput {...props} reference={Resources.gameCategory} multiple={multiple} />;
}

export function CgRecordButton({
  onRowClick,
  ...props
}: ButtonProps & { onRowClick: (record?: RaRecord<Identifier>) => any }) {
  const record = useRecordContext(props);

  const handleClick = () => {
    if (onRowClick) {
      onRowClick(record);
    }
  };

  return <Button {...props} onClick={handleClick} />;
}

export interface CgEditProps extends EditProps {
  onClose?: () => any;
  formProps?: FormProps<RecordType<any, any>>;
}

export function CgEdit(props: CgEditProps) {
  const controllerProps = useEditController(props);
  const t = useTranslate();
  const { title, children, onClose, formProps } = props;
  const titleEle = typeof title === 'string' ? t(title) : title;

  return (
    <EditContextProvider value={controllerProps}>
      <Edit
        {...props}
        sx={{
          boxShadow: 'none',
          '.RaEdit-noActions': {
            marginTop: 0,
          },
          '.MuiCard-root': {
            boxShadow: 'none',
          },
        }}
      >
        <Form
          {...formProps}
          disableInvalidFormNotification
        >
          {titleEle ? <DialogTitle>{titleEle}</DialogTitle> : null}
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <MuiButton variant="outlined" onClick={onClose}>
              {t('actions.cancel')}
            </MuiButton>
            <SaveButton
              type="button"
              transform={(data) => {
                const currencyData = data?.currency
                if (!currencyData || typeof currencyData === 'string') {
                  return data
                }

                const currencyArr = Array.from(new Set(currencyData ?? []))
                const currencyString: string = currencyArr.toString();
                return {
                  ...data,
                  currency: currencyString,
                }
              }}
            />
          </DialogActions>
        </Form>
      </Edit>
    </EditContextProvider>
  );
}

export interface CgCreateProps extends CreateProps {
  onClose?: () => any;
  formProps?: FormProps<RecordType<any, any>>;
}

export function CgCreate(props: CgCreateProps) {
  const controllerProps = useCreateController(props);
  const t = useTranslate();
  const { title, children, onClose, formProps } = props;
  const titleEle = typeof title === 'string' ? t(title) : title;

  return (
    <CreateContextProvider value={controllerProps}>
      <Create
        {...props}
        sx={{
          boxShadow: 'none',
          '.RaEdit-noActions': {
            marginTop: 0,
          },
          '.MuiCard-root': {
            boxShadow: 'none',
          },
        }}
      >
        <Form
          {...formProps}
          disableInvalidFormNotification
        >
          {titleEle ? <DialogTitle>{titleEle}</DialogTitle> : null}
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <MuiButton variant="outlined" onClick={onClose}>
              {t('actions.cancel')}
            </MuiButton>
            <SaveButton
              type="button"
              transform={(data) => {
                const currencyData = data?.currency
                if (!currencyData || typeof currencyData === 'string') {
                  return data
                }

                const currencyArr = Array.from(new Set(currencyData ?? []))
                const currencyString: string = currencyArr.toString();
                return {
                  ...data,
                  currency: currencyString,
                }
              }}
            />
          </DialogActions>
        </Form>
      </Create>
    </CreateContextProvider>
  );
}
