import { Button, Card, Chip, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { isEmpty, keyBy } from 'lodash';
import {
  Filter,
  Identifier,
  Link,
  List,
  ListControllerResult,
  RaRecord,
  // TextInput,
  useGetManyReference,
  useListContext,
  useLocale,
  usePermissions,
  useTranslate,
  WithListContext,
} from 'react-admin';
import { IoSettingsOutline } from 'react-icons/io5';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { paths } from '../../../../routes/paths';
import { SelectGameCategoryInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { formatMoney } from '../../utils/format-number';
import { Resources } from '../resource';
import styles from './style.module.scss';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { fShortenNumber } from '../../../../utils/format-number';
import { VscHistory } from 'react-icons/vsc';
import { _mock } from '../../../../_mock';
import { varAlpha } from '../../../../theme/styles';
import { Image } from '../../../../components/image';
import { DashboardContent } from '../../../../layouts/dashboard';
import CGTextInput from '../../components/CGTextInput';
import CGFilterAgency from '../../components/CGFilterAgency';
import CGSaveButton from '../../components/CGSaveButton';
import React, { useCallback, useState } from 'react';
import { PermissionData } from '../../entities/permission.entity';
import IconButton from '@mui/material/IconButton';
import { Iconify } from '../../../../components/iconify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EmptyContent } from '../../../../components/empty-content';
import ToolTip from 'src/components/button/ToolTip';

interface IGameItem {
  id: string;
  name: string;
  status: 1 | 2 | 3;
  gameCategoryId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  in: number;
  out: number;
  timePlay: number;
}

interface GameItemProps {
  game: IGameItem;
  agency?: string;
  translate: any;
}

const gameStatusLabel = {
  1: 'module.game.active',
  2: 'module.game.maintain',
  3: 'module.game.locked',
};

const switchGameStatusLabel = {
  1: 'module.game.switchStatus.activate',
  2: 'module.game.switchStatus.maintain',
  3: 'module.game.switchStatus.lock',
};

const currentGamesIDs = ['C_BO', 'C_FUTURE', 'H_BP', 'H_BS', 'H_LUCKY', 'H_NIUNIU', 'H_OE'];

function GameItem({ game, translate }: GameItemProps) {
  const linkToGameRule = `/${Resources.gameRule}?filter=${JSON.stringify({
    gameId: game.id || '',
  })}`;

  const status = game.status === 1 ? translate('resource.active') : translate('resource.inactive');
  const statusColor = game.status === 1 ? 'primary' : 'default';

  return (
    <Link
      className={styles.gameItemContainer}
      to={`/${Resources.gameDetail}?filter=${JSON.stringify({
        gameId: game.id || '',
      })}`}
    >
      <div className={styles.header}>
        <Typography variant="h6" className={styles.name}>
          {game.name}
        </Typography>
        <Chip color={statusColor} label={status} />
      </div>
      <div className={styles.body}>
        <div className={styles.field}>
          <div className={styles.label}>Today tx</div>
          <div className={styles.value}>{formatMoney(game.timePlay)}</div>
        </div>
        <div className={styles.field}>
          <div className={styles.label}>In</div>
          <div className={styles.value}>{formatMoney(game.in)}</div>
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Out</div>
          <div className={styles.value}>{formatMoney(game.out)}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Link to={linkToGameRule}>
          <Button startIcon={<IoSettingsOutline />}>{translate('resource.setupGameRule')}</Button>
        </Link>
      </div>
    </Link>
  );
}

export function GameItemV2({ game, agency }: GameItemProps) {
  const { permissions } = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');
  const isAdmin = !isEmpty(checkPermission['*']?.action);
  const gameID = game?.id;
  const t = useTranslate();
  const locale = useLocale();
  const [isOpenAdminMenu, setOpenAdminMenu] = useState<null | HTMLElement>(null);

  const linkToGameRule = `/${Resources.gameRule}?filter=${JSON.stringify({
    gameId: gameID || '',
  })}`;
  // const status = game.status === 1 ? t('resource.active') : t('resource.inactive');
  const statusColor = game.status === 1 ? 'primary' : 'default';

  const coverUrl = currentGamesIDs.includes(gameID)
    ? locale === 'cn'
      ? `/assets/images/gamesCover/cn/${gameID}.png`
      : `/assets/images/gamesCover/en/${gameID}.png`
    : _mock.image.cover(parseInt(game.gameCategoryId));

  const handleAdminMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenAdminMenu(event.currentTarget);
  }, []);

  const handleAdminMenuClose = useCallback(() => {
    setOpenAdminMenu(null);
  }, []);

  return (
    <Card sx={{ textAlign: 'center' }}>
      <Box>
        <Image
          src={coverUrl}
          // ratio="21/9"
          ratio="100/105"
          slotProps={{
            overlay: {
              background: (theme) => varAlpha(theme.vars.palette.grey['900Channel'], 0.3),
            },
          }}
        />
      </Box>
      {isAdmin && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleAdminMenuClick}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              color: 'white',
              zIndex: 1,
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>

          <Menu
            anchorEl={isOpenAdminMenu}
            onClose={handleAdminMenuClose}
            open={!!isOpenAdminMenu}
            slotProps={{ paper: { sx: { maxHeight: 48 * 4.5 } } }}
          >
            <MenuItem
              onClick={handleAdminMenuClose}
              sx={{
                padding: 0,
              }}
              disabled={game.status === 1}
            >
              <CGSaveButton
                resource={Resources.game}
                type="edit"
                label={switchGameStatusLabel[1]}
                id={gameID}
                message={{
                  success: t('module.game.switchStatus.updateSuccess'),
                  error: t('module.game.switchStatus.updateFailed'),
                }}
                component={Box}
                data={{
                  status: 1,
                }}
                sx={{
                  width: '100%',
                  padding: '5px',
                }}
              />
            </MenuItem>
            <MenuItem
              onClick={handleAdminMenuClose}
              sx={{
                padding: 0,
              }}
              disabled={game.status === 2}
            >
              <CGSaveButton
                resource={Resources.game}
                type="edit"
                label={switchGameStatusLabel[2]}
                id={gameID}
                message={{
                  success: t('module.game.switchStatus.updateSuccess'),
                  error: t('module.game.switchStatus.updateFailed'),
                }}
                component={Box}
                data={{
                  status: 2,
                }}
                sx={{
                  width: '100%',
                  padding: '5px',
                }}
              />
            </MenuItem>
          </Menu>
        </>
      )}
      <ListItemText
        sx={{ mt: 3, mb: 3 }}
        primary={game.name}
        secondary={t(gameStatusLabel[game.status])}
        primaryTypographyProps={{ typography: 'subtitle1' }}
        secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
        color={statusColor}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack direction="row" spacing={2} justifyContent="center">
        <Link
          to={`/${Resources.gameDetail}?displayedFilters=${encodeURIComponent(
            JSON.stringify({
              games: gameID || '',
              agencyIds: agency,
            })
          )}&filter=${encodeURIComponent(
            JSON.stringify({
              games: gameID || '',
              agencyIds: agency,
            })
          )}`}
        >
          <Button startIcon={<VscHistory size={16} />}>{t('resource.gameHistory')}</Button>
        </Link>
        <Link to={linkToGameRule}>
          <Button startIcon={<IoSettingsOutline size={16} />} size={'medium'}>
            {t('resource.setupGameRule')}
          </Button>
        </Link>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        sx={{ py: 3, pr: 1, typography: 'subtitle1' }}
      >
        <div>
          <Typography variant="caption" component="div" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('module.game.today')}
          </Typography>
          {fShortenNumber(game.timePlay ?? 0)}
        </div>

        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 0.5,
            }}
          >
            <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
              {t('module.game.in')}
            </Typography>
            <ToolTip title={t('tooltips.module.game.gameDetail.outcome')} />
          </Box>

          {fShortenNumber(Math.abs(game.in ?? 0))}
        </div>

        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 0.5,
            }}
          >
            <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
              {t('module.game.out')}
            </Typography>
            <ToolTip title={t('tooltips.module.game.gameDetail.income')} />
          </Box>
          {fShortenNumber(Math.abs(game.out ?? 0))}
        </div>
      </Box>
    </Card>
  );
}

interface FilterProps {
  alwaysOn?: boolean;
}

function FilterByStatus(props: FilterProps) {
  const translate = useTranslate();
  const { filterValues, setFilters } = useListContext();

  const key = 'status';
  const status = filterValues[key] || 'all';

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setFilters({ ...filterValues, [key]: value });
  };

  return (
    <ToggleButtonGroup
      value={status}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="large"
      sx={{ height: 40, mt: 2, border: 'solid 1px rgba(var(--palette-grey-500Channel) / 0.2)' }}
    >
      <ToggleButton value="" className={!status || status === 'all' ? 'Mui-selected' : ''}>
        {translate('module.game.all')}
      </ToggleButton>
      <ToggleButton value={1}>{translate('module.game.active')}</ToggleButton>
      <ToggleButton value={2}>{translate('module.game.maintain')}</ToggleButton>
      <ToggleButton value={3} disabled>
        {translate('module.game.locked')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const ListFilter = (props: any) => {
  return (
    <div className={styles.filterContainer}>
      <Filter {...props}>
        <FilterGroup
          className={styles.filterLayler}
          sx={{ paddingX: 0, paddingTop: '0 !important' }}
          alwaysOn
        >
          <CGTextInput source="q" label="module.game.search" sx={{ minWidth: 318 }} />
          <CGFilterAgency
            source="agency"
            label="module.agency.filterByAgency"
            alwaysOn
            sx={{ minWidth: 200, maxWidth: 200 }}
          />
          <SelectGameCategoryInput
            source="gameCategoryId"
            label="module.agency.filterByCategory"
            alwaysOn
            sx={{ minWidth: 200, maxWidth: 200 }}
          />
          <div className={styles.blank} />
        </FilterGroup>
        <FilterByStatus key="status" alwaysOn />
      </Filter>
    </div>
  );
};

function CustomList(props: ListControllerResult<RaRecord<Identifier>>) {
  const { filterValues } = props;
  const translate = useTranslate();
  const { data: exData } = useGetManyReference(Resources.gameStatistics, {
    id: 'statistics',
    target: 'field',
    filter: { id: props.data?.map((x) => x.id), agency: filterValues?.agency },
  });

  const data = keyBy(exData, 'id');

  if (!props?.data || props?.data?.length === 0) {
    return <EmptyContent filled />;
  }

  return (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)',
        xl: 'repeat(5, 1fr)',
        xxl: 'repeat(6, 1fr)',
      }}
      maxWidth="1600px"
    >
      {props.data?.map((item) => {
        const game = {
          ...item,
          ...(data[item.id] || {}),
        } as IGameItem;
        // @ts-ignore
        return (
          <GameItemV2
            key={item.id}
            game={game}
            agency={filterValues?.agency}
            translate={undefined}
          />
        );
      })}
    </Box>
  );
}

function View() {
  const t = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={t('resource.gameList')}
        links={[
          { name: `${t('resource.game')}`, href: paths.dashboard.game.root },
          { name: `${t('resource.gameList')}` },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <List
        filters={<ListFilter />}
        actions={false}
        perPage={25}
        sx={{
          // pl: 1,
          // pr: 2,
          '& .RaList-actions': {
            marginBottom: 2,
          },
          '& .RaList-content': {
            background: 'none',
            boxShadow: 'none',
          },
        }}
      >
        <WithListContext
          render={(list) => {
            return <CustomList {...list} />;
          }}
        />
      </List>
    </DashboardContent>
  );
}

export default View;
