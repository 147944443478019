import { ResourceOptions } from 'react-admin';
import { VscSettings } from 'react-icons/vsc';
import { Resources } from '../resource';

export { default as list } from './List';

export const name = Resources.setting;
export const icon = VscSettings;
export const options: ResourceOptions = {
  label: 'resource.setting',
};
