import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import PnLValueFormatted from './PnLValueFormatted';
import { f } from 'src/utils/format';
import { useTranslate } from 'react-admin';

type PnLTextProps = {
  pnl?: number | string;
  roi?: number | string;
  isClosed?: boolean;
};

const PnLText = (props: PnLTextProps) => {
  const { pnl, roi, isClosed } = props;
  const t = useTranslate();
  const pnlNumber = Number(pnl);
  const roiNumber = Number(roi);
  const textColor =
    pnlNumber > 0 ? 'var(--palette-primary-main)' : pnlNumber < 0 ? '#ff4651' : '#b1b1b6';

  return (
    <Box fontWeight={500} lineHeight={1}>
      {!!pnl ? (
        <>
          <Box
            display={'flex'}
            sx={{
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: 600,
                lineHeight: '1',
                marginRight: '10px',
                marginBottom: '3px',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: textColor,
              }}
            >
              <PnLValueFormatted pnl={pnlNumber} />
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '5px',
                color: textColor,
              }}
            >
              {' '}
              {!!roi && <>({f(roiNumber, { showPlus: true, decimal: 2 })}%)</>}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '16px',
              color: textColor,
            }}
          >
            {!isClosed
              ? t('pnl')
              : pnlNumber >= 0
                ? t('module.game.gameDetail.win')
                : t('module.game.gameDetail.lose')}
          </Typography>
        </>
      ) : (
        '--'
      )}
    </Box>
  );
};

export default PnLText;
