import { useTranslate } from 'react-admin';
import { RevenueChart } from '../../game-detail/RevenueChart';
import { Grid } from '@mui/material';
import { useMemo } from 'react';

interface DashboardRevenueChartProps {
  revenueData: any;
  isLoading: boolean;
}

export const DashboardRevenueChart = ({ revenueData, isLoading }: DashboardRevenueChartProps) => {
  const translate = useTranslate();

  const dataChartRevenue = useMemo(() => {
    if (revenueData) {
      return {
        categories: revenueData.categories,
        series: [
          {
            name: `${translate('module.dashBoard.revenue')}`,
            data: revenueData.revenues,
          },
          {
            name: `${translate('module.dashBoard.profitOverTime')}`,
            data: revenueData.profits,
          },
        ],
      };
    }
    return null;
  }, [revenueData, translate]);

  if (!dataChartRevenue) {
    return <></>;
  }

  return (
    <RevenueChart
      title={`${translate('module.dashBoard.revenue')} / ${translate('module.dashBoard.profitOverTime')}`}
      subheader=""
      chart={dataChartRevenue}
      // loading={isLoading ?? false}
    />
  );
};
