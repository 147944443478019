import { isEmpty, keyBy } from 'lodash';
import { usePermissions } from 'react-admin';
import { PermissionData } from 'src/pages/admin/entities/permission.entity';

export default function useIsAdmin() {
  const { permissions } = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');
  const isAdmin = !isEmpty(checkPermission['*']?.action);

  return isAdmin;
}
