import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

export interface AuthModel {
  accessToken: string;
  refreshToken: string;
}

interface AuthRequest {
  username: string;
  password: string;
}

export async function login(params: AuthRequest) {
  const { username, password } = params;

  const url = `${baseUrl}/auth/login`;
  const resp = await axios.post<AuthModel>(url, { identifier: username, password });
  return resp.data;
}
