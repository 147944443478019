import { Card } from '@mui/material';
import { List, ListProps } from 'react-admin';

export function CgList(props: ListProps) {
  return (
    <Card>
      <List
        actions={false}
        empty={false}
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
      />
    </Card>
  );
}
