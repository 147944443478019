// @ts-nocheck
import { merge } from 'lodash';
import language from 'ra-language-hindi';
import { TranslationMessages } from 'react-admin';
import en from './en';
import packageLanguage from './packages/hi.json';

const lang: TranslationMessages = merge({}, en, language, packageLanguage);

export default lang;
