export enum GAME_LISTS {
  BANKER_PLAYER = 'H_BP',
}

export interface GameRuleForm<T> {
  value?: T;
  gameId: string;
}

export declare type FormElement = (props: GameRuleForm<any>) => JSX.Element;
