import {useContext, useMemo} from 'react';
import defaults from 'lodash/defaults';
import {DatagridContext, DatagridContextValue, DatagridProps} from "./index";

export const useDatagridContext = (
  props?: DatagridProps
): DatagridContextValue => {
  const context = useContext(DatagridContext);

  return useMemo(
    () =>
      defaults(
        {},
        props != null ? {isRowExpandable: props.isRowExpandable} : {},
        context
      ),
    [context, props]
  );
};
