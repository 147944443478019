import {SelectArrayInputProps, usePermissions} from "react-admin";
import {PermissionData} from "../entities/permission.entity";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import {SelectListAgencyInput} from "./common";

const CGFilterAgency = (props: SelectArrayInputProps & {multiple?: boolean}) => {
  const {multiple = true} = props
  const {permissions} = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');
  const isAdmin = !isEmpty(checkPermission['*']?.action);

  if (!isAdmin) {
    return <></>
  }

  return <SelectListAgencyInput {...props} multiple={multiple} />
}

export default CGFilterAgency
