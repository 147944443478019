import { Filter, useListController } from 'react-admin';
import { SelectListGameInput } from '../../../components/common';
import FilterGroup from '../../../components/FilterGroup';
import CGFilterAgency from 'src/pages/admin/components/CGFilterAgency';
import { CgDateInput } from 'src/pages/admin/components/CgDateInput';
import dayjs from 'dayjs';

export const DashboardFilter = (props: any) => {
  const { filterValues } = useListController();
  const defaultFromTime = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

  return (
    <Filter {...props}>
      <FilterGroup alwaysOn px="0px">
        <CGFilterAgency source="agencyId" label="module.user.agency" multiple={false} alwaysOn />
        <SelectListGameInput source="gameIds" label="module.currency.filterByGames" />
        <CgDateInput
          label="resource.fromTime"
          source="fromTime"
          // show default value is 7 day ago (not working)
          // defaultValue={defaultFromTime}
          sx={{ width: 200 }}
        />
        <CgDateInput
          label="resource.toTime"
          source="toTime"
          sx={{ width: 200 }}
          type="to"
          minDate={filterValues.fromTime ? dayjs(filterValues.fromTime) : undefined}
        />
      </FilterGroup>
    </Filter>
  );
};
