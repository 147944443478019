import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { useLocation } from 'react-router';
import { CustomBreadcrumbs } from 'src/components/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { varAlpha } from 'src/theme/styles';
import { StorageKey } from '../../providers/storage';
import { DonutChart } from './DonutChart';
import GameHistory from './GameHistory';
import { RevenueChart } from './RevenueChart';
import useInOutCome from '../dashboard/hooks/useInOutCome';
import useRevenue from './useRevenue';
import { DashboardContent } from '../../../../layouts/dashboard';
import { UserModel } from '../../entities/user.entity';
import SelectInput from 'src/components/inputs/select-input';
import useIsAdmin from 'src/hooks/use-is-admin';
import { TimeDuration } from '../../services/chartService';

function GameOverview() {
  const translate = useTranslate();
  const location = useLocation();

  const isAdmin = useIsAdmin();
  const { data: userData } = useGetIdentity();
  const user = userData as UserModel;
  const [timeDuration, setTimeDuration] = useState<TimeDuration>('week');

  const paramLocalStorage = localStorage.getItem(StorageKey.GameDetailData);
  const agencyId = user?.agencies ? user?.agencies[0]?.id : undefined;

  const paramChart = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterData = queryParams.get('filter') ? JSON.parse(queryParams.get('filter')!) : {};

    const gameIds =
      filterData.games ??
      (!!paramLocalStorage ? JSON.parse(paramLocalStorage!)?.filter?.gameIds : '');
    const agencyIdFilter =
      filterData.agencyIds ??
      (!!paramLocalStorage ? JSON.parse(paramLocalStorage!)?.filter?.agencyIds : '');

    return {
      filter: isAdmin
        ? {
            gameIds,
            agencyId: agencyIdFilter,
            fromTime: filterData.fromTime,
            toTime: filterData.toTime,
          }
        : {
            gameIds,
            agencyId: agencyId,
            fromTime: filterData.fromTime,
            toTime: filterData.toTime,
          },
    };
  }, [isAdmin, agencyId, location.search, paramLocalStorage]);

  const isSelectedGameAndAgency = paramChart.filter.agencyId && paramChart.filter.gameIds;

  const { data: dataRevenue, loading: loadingRevenue } = useRevenue('game', paramChart);

  const dataChartRevenue = useMemo(() => {
    if (dataRevenue) {
      const obj = {
        categories: dataRevenue.categories,
        series: [
          {
            name: `${translate('module.dashBoard.revenue')}`,
            data: dataRevenue.revenues,
          },
          {
            name: `${translate('module.dashBoard.profitOverTime')}`,
            data: dataRevenue.profits,
          },
        ],
      };
      return obj;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRevenue]);

  // });
  const { data: dataInoutCome, loading: loadingPlayer } = useInOutCome(paramChart);
  const dataChartInoutCome = useMemo(() => {
    if (_.has(dataInoutCome, 'income')) {
      const obj = {
        series: [
          {
            label: `${translate('module.game.income')} ${!!dataInoutCome?.income?.percentage ? `(${dataInoutCome?.income?.percentage?.toFixed(2)}%)` : ''}`,
            value: Number(dataInoutCome?.income?.value),
          },
          {
            label: `${translate('module.game.outcome')} ${!!dataInoutCome?.outcome?.percentage ? `(${dataInoutCome?.outcome?.percentage?.toFixed(2)}%)` : ''}`,
            value: Number(dataInoutCome?.outcome?.value),
          },
        ],
        tooltips: [
          translate('tooltips.module.game.gameDetail.outcome'),
          translate('tooltips.module.game.gameDetail.income'),
        ],
      };
      return obj;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInoutCome]);

  return (
    <>
      <Box width="180px">
        <SelectInput
          title={translate('module.game.timeDuration')}
          value={timeDuration}
          choices={[
            {
              id: 'week',
              name: `7 ${translate('days')}`,
            },
            {
              id: 'month',
              name: `30 ${translate('days')}`,
            },
            // {
            //   id: 'year',
            //   name: 'Year',
            // },
          ]}
          onChange={(e) => {
            setTimeDuration(e.target.value as any);
          }}
        />
      </Box>
      <Grid container ml={0} mt={0} width="100%" spacing={2}>
        {isSelectedGameAndAgency ? (
          <>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ paddingLeft: '0 !important', paddingTop: '0 !important' }}
            >
              {loadingRevenue ? (
                <Skeleton
                  sx={{
                    width: '100%',
                    height: '100%',
                    minHeight: '250px',
                  }}
                />
              ) : dataChartRevenue ? (
                <RevenueChart
                  titleRevenue={translate('module.dashBoard.revenue')}
                  titleProfit={translate('module.dashBoard.profitOverTime')}
                  tooltipRevenue={translate('tooltips.module.dashboard.revenue')}
                  tooltipProfit={translate('tooltips.module.dashboard.systemProfit')}
                  subheader=""
                  chart={dataChartRevenue}
                />
              ) : undefined}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              pl={0}
              sx={{
                paddingLeft: { xs: '0 !important', md: '24px !important' },
                paddingTop: { md: '0 !important' },
              }}
            >
              {loadingPlayer ? (
                <Skeleton
                  sx={{
                    width: '100%',
                    height: '100%',
                    minHeight: '250px',
                  }}
                />
              ) : dataChartInoutCome ? (
                <DonutChart
                  title={`${translate('module.game.income')} / ${translate('module.game.outcome')}`}
                  chart={dataChartInoutCome!}
                />
              ) : undefined}
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              width: 1,
              height: 100,
              borderRadius: 2,
              bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.04),
              border: (theme) => `dashed 1px ${theme.vars.palette.divider}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">
              {isAdmin
                ? translate('module.game.blankState')
                : translate('module.game.blankStateAgency')}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.gameDetail')}
        links={[
          { name: `${translate('resource.game')}`, href: paths.dashboard.game.root },
          { name: `${translate('resource.gameDetail')}` },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Stack spacing={2}>
        {/* <GameOverview /> */}
        <GameHistory />
      </Stack>
    </DashboardContent>
  );
}

export default View;
