import { useGetIdentity, useTranslate } from 'react-admin';
import { DonutChart } from '../../game-detail/DonutChart';
import { Grid } from '@mui/material';
import usePlayer from '../hooks/usePlayer';
import { useMemo } from 'react';
import _ from 'lodash';
import { UserModel } from 'src/pages/admin/entities/user.entity';

interface DashboardPlayerChartProps {
  filterValues: any;
  isLoading: boolean;
}

export const DashboardPlayerChart = ({ filterValues, isLoading }: DashboardPlayerChartProps) => {
  const translate = useTranslate();

  const { data: userData } = useGetIdentity();
  const user = userData as UserModel;

  const { data: dataPlayer } = usePlayer(user, filterValues);

  const dataChartPlayer = useMemo(() => {
    if (!_.isEmpty(dataPlayer)) {
      const series = dataPlayer
        .map((item: any) => ({
          label: `${item?.name ? item?.name : ''} (${
            item?.percentage ? item?.percentage?.toFixed(2) : ''
          }%)`,
          value: item?.numberPlayer,
        }))
        .sort((a: any, b: any) => b.value - a.value)
        .filter((item: any, index: number) => index <= 5);
      return { series };
    }
    return null;
  }, [dataPlayer]);

  if (!dataChartPlayer) {
    return <></>;
  }

  return (
    <DonutChart
      title={translate('module.dashBoard.playerByGames')}
      chart={dataChartPlayer}
      // loading={isLoading}
    />
  );
};
