import { DynamicFormatOption, f } from 'src/utils/format';

type PnLValueFormattedProps = {
  pnl?: number | string;
  formatOptions?: DynamicFormatOption;
};

const defaultFormatOptions: DynamicFormatOption = {
  decimal: 2,
  showPlus: true,
  tinySupport: 6,
};

const PnLValueFormatted = (props: PnLValueFormattedProps) => {
  const { pnl, formatOptions } = props;

  const pnlNumbered = Number(pnl);

  return <>{f(pnlNumbered, { ...defaultFormatOptions, ...formatOptions })}</>;
};

export default PnLValueFormatted;
