export enum RoleAction {
  _all = '*',
  read = 'read',
  create = 'create',
  edit = 'edit',
  delete = 'delete',
}
export interface Permission {
  action: RoleAction[];
  resource: string;
  record?: {
    [key: string]: string | string[];
  };
}

export interface GroupPermission {
  id: number;
  groupId: string;
  isActived: boolean;
  permissionId: string;
}

export interface PermissionData {
  permissions: Permission[];
  groups: GroupPermission[];
}
