import type { StackProps } from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { fShortenNumber } from 'src/utils/format-number';
import Typography from '@mui/material/Typography';
import { Iconify } from 'src/components/iconify';
import { Tooltip } from '@mui/material';
import * as React from 'react';

// ----------------------------------------------------------------------

export const StyledLegend = styled(Box)(({ theme }) => ({
  gap: 6,
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const StyledDot = styled(Box)(() => ({
  width: 12,
  height: 12,
  flexShrink: 0,
  display: 'flex',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'currentColor',
}));

// ----------------------------------------------------------------------

type Props = StackProps & {
  labels?: string[];
  colors?: string[];
  values?: number[];
  sublabels?: string[];
  icons?: React.ReactNode[];
  tooltips?: string[];
};

export function ChartLegends({
  labels = [],
  colors = [],
  values,
  sublabels,
  icons,
  tooltips,
  ...other
}: Props) {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={2} {...other}>
      {labels?.map((series, index) => (
        <Stack direction="row" justifyContent="space-between" key={series} spacing={1}>
          <StyledLegend>
            {icons?.length ? (
              <Box
                component="span"
                sx={{ color: colors[index], '& svg, & img': { width: 20, height: 20 } }}
              >
                {icons?.[index]}
              </Box>
            ) : (
              <StyledDot component="span" sx={{ color: colors[index] }} />
            )}

            <Stack flexDirection="row" alignItems="center" sx={{ flexShrink: 0 }}>
              {series}
              {tooltips?.length ? (
                <Tooltip
                  title={<Typography fontSize={12}>{tooltips[index]}</Typography>}
                  placement="top-end"
                  enterDelay={300}
                >
                  <Iconify
                    width={16}
                    icon="eva:info-outline"
                    ml="5px"
                    sx={{ cursor: 'pointer', color: 'text.disabled' }}
                  />
                </Tooltip>
              ) : null}
              {sublabels && <> {` (${sublabels[index]})`}</>}
            </Stack>
          </StyledLegend>

          {values && <Box sx={{ typography: 'h6' }}>{fShortenNumber(values[index])}</Box>}
        </Stack>
      ))}
    </Stack>
  );
}
