import { Filter, List, useTranslate, WithListContext } from 'react-admin';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { DashboardContent } from '../../../../layouts/dashboard';
import { paths } from '../../../../routes/paths';
import { SelectGameInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { BOGameConfigTable } from './BoForm';
import { FormForHashGame } from './HashForm';
import { FormForCryptoGame } from './FutureForm';
import { FormElement } from './gamerule.type';

const mapGameCategoryWithForm: { [key: string]: FormElement } = {
  HASH_GAME: FormForHashGame,
  C_FUTURE: FormForCryptoGame,
  C_BO: BOGameConfigTable,
};

function ListFilter(props: any) {
  return (
    <Filter {...(props || {})}>
      <FilterGroup alwaysOn sx={{ padding: 0, margin: 0 }}>
        <SelectGameInput source="gameId" label="module.user.games" isRequired required alwaysOn />
      </FilterGroup>
    </Filter>
  );
}

function CustomList() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.rule')}
        links={[
          { name: `${translate('resource.game')}`, href: paths.dashboard.game.root },
          { name: `${translate('resource.rule')}` },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <List
        pagination={false}
        filters={<ListFilter />}
        actions={false}
        // resource="gameRule"
        sx={{
          marginTop: '-8px !important',
          pr: 2,
          '& .RaList-actions': {
            marginBottom: '5px',
          },
        }}
      >
        <p
          style={{
            color: 'var(--palette-text-secondary)',
            fontSize: '0.75rem',
            margin: '0',
            paddingLeft: '14px',
          }}
        >
          {translate('module.gameRule.gameRuleSetBySA')}
        </p>
        <WithListContext
          render={(list) => {
            const { data, filterValues } = list;
            const { gameId } = filterValues || {};
            const View = mapGameCategoryWithForm[gameId] || FormForHashGame;
            const firstItem = (data || []).find((item: any) => item.gameId === gameId);
            const ruleConfig = firstItem?.ruleConfig;

            return <View key={firstItem?.id || gameId} value={ruleConfig} gameId={gameId} />;
          }}
        />
      </List>
    </DashboardContent>
  );
}

export default CustomList;
