import { isNil, isNumber } from 'lodash';
import { formatNumberLocale } from 'src/locales';
import { DynamicNumberFormat } from './format';

// ----------------------------------------------------------------------

/*
 * Locales code
 * https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416
 */

export type InputNumberValue = string | number | null | undefined;

type Options = Intl.NumberFormatOptions | undefined;

const DEFAULT_LOCALE = { code: 'en-US', currency: 'USD' };

function processInput(inputValue: InputNumberValue): number | null {
  if (inputValue == null || Number.isNaN(inputValue)) return null;
  return Number(inputValue);
}

// ----------------------------------------------------------------------

export function fNumber(inputValue: InputNumberValue, options?: Options) {
  const locale = formatNumberLocale() || DEFAULT_LOCALE;

  const number = processInput(inputValue);
  if (number === null) return '';

  const fm = new Intl.NumberFormat(locale.code, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);

  return fm;
}

// ----------------------------------------------------------------------

export function fCurrency(inputValue: InputNumberValue, options?: Options) {
  const locale = formatNumberLocale() || DEFAULT_LOCALE;

  const number = processInput(inputValue);
  if (number === null) return '';

  const fm = new Intl.NumberFormat(locale.code, {
    style: 'currency',
    currency: locale.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);

  return fm;
}

// ----------------------------------------------------------------------

export function fPercent(inputValue: InputNumberValue, options?: Options) {
  const locale = formatNumberLocale() || DEFAULT_LOCALE;

  const number = processInput(inputValue);
  if (number === null) return '';

  const fm = new Intl.NumberFormat(locale.code, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    ...options,
  }).format(number / 100);

  return fm;
}

// ----------------------------------------------------------------------

export function fShortenNumber(inputValue: InputNumberValue, options?: Options) {
  const locale = formatNumberLocale() || DEFAULT_LOCALE;

  const number = processInput(inputValue);
  if (number === null) return '';

  if (Math.abs(number) < 1000) {
    if (number % 1 !== 0) {
      return new Intl.NumberFormat(locale.code, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        ...options,
      }).format(number);
    }

    return new Intl.NumberFormat(locale.code, {
      ...options,
    }).format(number);
  }

  const fm = new Intl.NumberFormat(locale.code, {
    notation: 'compact',
    maximumFractionDigits: 1,
    ...options,
  }).format(number);

  return fm.replace(/[A-Z]/g, (match) => match.toUpperCase());
}

// ----------------------------------------------------------------------

export function fData(inputValue: InputNumberValue) {
  const number = processInput(inputValue);
  if (number === null || number === 0) return '0 bytes';

  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const decimal = 2;
  const baseValue = 1024;

  const index = Math.floor(Math.log(number) / Math.log(baseValue));
  const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${units[index]}`;

  return fm;
}

export const toFloats = ({
  value,
  emptyValue = 0,
}: {
  value: number | string | null | undefined;
  digit?: number;
  emptyValue?: string | number;
}) => {
  if (isNil(value) || !isNumber(+value) || !+value) {
    return emptyValue;
  }

  const result = formatNumber(+value);

  return result || emptyValue;
};

export function formatNumber(amount: number | undefined | string, decimal?: number): string {
  let num: number = Number(amount);
  if (amount !== undefined) {
    num = typeof amount !== 'number' ? Number(amount) : amount;
    if (isNaN(num)) {
      console.warn('{formatNumber} found NaN: ', amount);
      return 'NaN';
    }
  }

  return DynamicNumberFormat.safeFormat(num, {
    defaultValue: '--',
    decimal: decimal,
  });
}
