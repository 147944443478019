import { Create, Edit, NumberInput, SimpleForm } from "react-admin";
import CGTextInput from "../../components/CGTextInput";

const form = (
  <SimpleForm>
    {/* <CGTextInput source="id" label="Id" /> */}
    <CGTextInput source="currency" />
    <NumberInput source="playerCT" label="Player CT" />
    <NumberInput source="newPlayerCT" label="New Player CT" />
    <NumberInput source="hands" />
    <NumberInput source="rounds" />
    <NumberInput source="stake" />
    <NumberInput source="winAmount" />
    <CGTextInput source="playerWL" label="Player W/L" />
    <NumberInput source="sysWL" label="System W/L" />
    <NumberInput source="sysProfit" label="System Profit %" />
  </SimpleForm>
);

export function create() {
  return <Create>{form}</Create>;
}

function View() {
  return <Edit>{form}</Edit>;
}

export default View;
