import axios from "axios";
import {getAuth} from "../providers/AppAuthProvider";
import {map} from "lodash";
import {httpClient} from "../providers/AppDataProvider";

const baseUrl = process.env.REACT_APP_API_URL;
const resource = "game"

export const getAllResource = (resource: string) => async (params: any) => {
  const query = {
    sort: JSON.stringify(params.sort),
    page: params.page,
    size: params.size,
    filter: JSON.stringify(params.filter),
  };
  const queryParmas = map(query, (value, key) => `${key}=${value}`).join("&");

  const url = `${baseUrl}/${resource}${queryParmas ? "?" + queryParmas : ""}`;

  const {json} = await httpClient(url)

  return json;
}

export const getAll = getAllResource(resource);

export async function getGameRule(params: any) {
  const url = `${baseUrl}/game-rule`;

  const {accessToken} = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
  };

  return axios.get<any>(url, {
    headers,
    params,
  });
}

export async function create(params: any) {
  const url = `${baseUrl}/game-rule`;

  const {accessToken} = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
  };

  return axios.post<any>(url, params, {
    headers,
  });
}
