import React from 'react';
import * as currencyService from "../../services/currencyService";

const useWallets = () => {
    const [wallets, setWallets] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setLoading(true);
        currencyService.getWallets({ page: 1, size: 100 }).then((response: any) => {
            setWallets((response.data || []).map((curr: any) => ({ ...curr, id: curr.code })));
            setLoading(false);
        })
    }, []);

    return (
        {
            wallets,
            loading,
        }
    )
}

export default useWallets
