import dayjs from 'dayjs';
import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { DATE_FORMAT } from 'src/configs/common.config';

function View() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="agencyId" label="resource.id" />
        <TextField source="apiKey" label="module.agency.apiKey" />
        <TextField source="whitelistedIps" label="module.agency.whiteListedIps" />
        <TextField source="currency" label="module.agency.wallet" />
        <TextField source="creditLimit" label="module.agency.creditLimit" />
        <TextField source="creditFrozen" label="module.agency.creditFrozen" />
        <FunctionField
          source="registeredAt"
          label="module.agency.registeredAt"
          minWidth={180}
          maxWidth={200}
          render={(record) =>
            record.registeredAt ? dayjs(record.registeredAt).format(DATE_FORMAT) : ''
          }
        />
        <FunctionField
          source="updatedAt"
          label="module.agency.activatedAt"
          minWidth={180}
          maxWidth={200}
          render={(record) => (record.updatedAt ? dayjs(record.updatedAt).format(DATE_FORMAT) : '')}
        />
        <TextField source="status" label="resource.status" />
      </SimpleShowLayout>
    </Show>
  );
}

export default View;
