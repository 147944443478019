import {TextInput, TextInputProps} from "react-admin"

type CGTextInputProps = TextInputProps & {
  autoComplete?: string;
}

const CGTextInput = (props: CGTextInputProps) => {
  const {autoComplete = 'off'} = props

  return (
    <TextInput autoComplete={autoComplete} {...props} />
  )
}

export default CGTextInput
