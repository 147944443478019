import { DateTimePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import {
  DateInputProps,
  FieldTitle,
  InputHelperText,
  mergeRefs,
  sanitizeInputRestProps,
  useInput,
  useListController,
} from 'react-admin';
import { undefined } from 'zod';

const defaultInputLabelProps = { shrink: true };

const formatDate = (value?: string | Date) => {
  if (value == null || value === '') {
    return null;
  }

  return dayjs(value);
};

export const CgDateTimeInput = ({
  className,
  defaultValue,
  format = formatDate,
  label,
  source,
  resource,
  helperText,
  margin,
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant,
  disabled,
  readOnly,
  minDate,
  maxDate = dayjs(),
  type = 'from',
  ...rest
}: DateInputProps & {
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  type?: 'from' | 'to';
}) => {
  const { field, fieldState, isRequired } = useInput({
    defaultValue,
    onBlur,
    resource,
    source,
    validate,
    disabled,
    readOnly,
    ...rest,
  });
  const { filterValues, setFilters } = useListController();
  const [renderCount, setRenderCount] = React.useState(1);
  const valueChangedFromInput = React.useRef(false);
  const localInputRef = React.useRef<HTMLInputElement>();
  const initialDefaultValueRef = React.useRef(field.value);

  React.useEffect(() => {
    const initialDateValue = new Date(initialDefaultValueRef.current).getTime() || null;

    const fieldDateValue = new Date(field.value).getTime() || null;

    if (initialDateValue !== fieldDateValue && !valueChangedFromInput.current) {
      setRenderCount((r) => r + 1);
      parse ? field.onChange(parse(field.value)) : field.onChange(field.value);
      initialDefaultValueRef.current = field.value;
      valueChangedFromInput.current = false;
    }
  }, [setRenderCount, parse, field]);

  const { onBlur: onBlurFromField } = field;
  const hasFocus = React.useRef(false);

  // update the input text when the user types in the input
  const handleChange = (event?: dayjs.Dayjs) => {
    const value = event?.format();

    if (!dayjs(value).isValid()) {
      console.error(`[CgDateInput] date value not valid: `, value);
      return;
    }

    if (!value || value === '') {
      setFilters({
        ...filterValues,
        [name]: value,
      });
      return;
    }
    if (onChange) {
      onChange(value);
    }

    const newValue = parse ? parse(value) : formatDate(value)?.format();

    if (newValue !== '' && newValue != null) {
      field.onChange(newValue);
      valueChangedFromInput.current = true;
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
    hasFocus.current = true;
  };

  const handleBlur = () => {
    hasFocus.current = false;

    if (!localInputRef.current) {
      return;
    }

    // To ensure users can clear the input, we check its value on blur
    // and submit it to react-hook-form
    const newValue =
      // @ts-ignore
      localInputRef.current.valueAsDate !== undefined &&
      localInputRef.current.valueAsDate !== null &&
      !isNaN(new Date(localInputRef.current.valueAsDate).getTime())
        ? parse
          ? parse(localInputRef.current.valueAsDate)
          : formatDate(localInputRef.current.valueAsDate)
        : parse
          ? parse(localInputRef.current.value)
          : formatDate(localInputRef.current.value);

    if (newValue !== field.value) {
      field.onChange(newValue ?? '');
    }

    if (onBlurFromField) {
      onBlurFromField();
    }
  };

  const { error, invalid } = fieldState;
  const renderHelperText = helperText !== false || invalid;

  const { ref, name } = field;
  const inputRef = mergeRefs([ref, localInputRef]);

  return (
    <DateTimePicker
      format="YYYY/MM/DD HH:mm"
      ampm={false}
      name={name}
      inputRef={inputRef}
      defaultValue={format(initialDefaultValueRef.current)}
      key={renderCount}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      size="small"
      variant={variant}
      margin={margin}
      error={invalid}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      minDate={minDate}
      maxDate={maxDate}
      slotProps={{
        field: {
          clearable: true,
        },
      }}
      helperText={
        renderHelperText ? <InputHelperText error={error?.message} helperText={helperText} /> : null
      }
      label={
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      InputLabelProps={defaultInputLabelProps}
      {...sanitizeInputRestProps(rest)}
    />
  );
};
