export type DynamicFormatOption = {
  decimal?: number; // default: undefined => auto choose decimal, force show decimal
  // if decimal < 0: eg, -2 => round up to step 100 => 123456.789 => 123400

  meaningful?: boolean | number; // [default true] hide non-meaningful 0 digits at first and last of number string
  round?:
    | 'up' // force round up, round up a negative number will return a bigger absolute value, eg: abs(-11) > abs(10)
    | 'down' // force round down, absolute
    | 'auto'; // default: 'auto', rule by default javascript
  defaultValue?: string; // if passed, undefined/NaN will return this default value instead
  showPlus?: boolean | number; // default: false, show "+" sign before positive number, eg: 123 => +123

  // if the number is tiny, we will try to show at least 1 meaningful number,
  // eg: f(0.0000123, {decimal: 2}) return "0"
  // eg: f(0.00000123, {decimal: 2, tinySupport: 6}) return "0.000001"
  // eg: f(0.0000123, {decimal: 2, tinySupport: 5}) return "0.00001" or "0.000012" depends.
  // tiny support always combined `decimal` option above
  tinySupport?: number;

  // if maxDecimal was specified => auto decimal might not show enough MEANINGFUL_LENGTH meaningful digit
  maxDecimal?: number;
};

export class DynamicNumberFormat {
  // rounding with auto decimal always have minimum of 6 meaningful digits
  static MEANINGFUL_LENGTH = 6;
  // changed to opt.tinySupport
  // static MIN_TINY_SUPPORT = 1e-6; // if the number is smaller than this, it is considered "0", cannot apply tinySupport
  static EMPTY_NUMBER = '--';
  static DEBUG = false;

  static safeFormat(n: number | undefined, opt: DynamicFormatOption = {}): string {
    try {
      return DynamicNumberFormat.format(n, opt);
    } catch (e) {
      console.error('{safeFormat} e: ', e);
      return n?.toString() ?? this.defaultValue(opt);
    }
  }

  static format(n: number | undefined, opt: DynamicFormatOption = {}): string {
    if (n === undefined || isNaN(n)) {
      return this.defaultValue(opt);
    }

    // parse option with default value
    const meaningful = opt?.meaningful !== false; // default true

    // tryna format
    // eslint-disable-next-line prefer-const
    let { rounded, decimal } = this.dynamicRound(n, opt);
    this.DEBUG && console.log('{dynamicNumberFormat} rounded, decimal: ', rounded, decimal);

    // support negative decimal:
    // 12345678.89 and decimal -2 = 12345600
    if (decimal && decimal < 0) {
      decimal = 0; // rounded has been already rounded with negative decimal, so we don't need to round anymore
    }

    const optFmt: Intl.NumberFormatOptions = {
      minimumFractionDigits: meaningful ? 0 : decimal,
      maximumFractionDigits: decimal,
    };
    // if (opt.minDecimal) optFmt.minimumFractionDigits = minDecimal as number
    // if (opt.maxDecimal) optFmt.maximumFractionDigits = maxDecimal as number

    let prettyNum = new Intl.NumberFormat('en-US', optFmt).format(rounded);
    // if (meaningful) {
    //   // rm leading and trailing zero
    //   prettyNum = prettyNum
    //     // .replace(/^0+(?=\d)/, '') // format from number so we never have leading zero
    //     .replace(/(\.\d*?[1-9])0+$/, '$1')
    //     .replace(/\.0+$/, '')
    // }

    if (opt.showPlus && n >= 0 && prettyNum.charAt(0) !== '-') {
      prettyNum = `+${prettyNum}`;
    }

    return prettyNum;
  }

  static dynamicRound(
    n: number,
    opt: DynamicFormatOption = {}
  ): {
    rounded: number;
    decimal: number;
  } {
    // fixed decimal
    if (opt.decimal !== undefined) {
      // console.log('{dynamicNumberRound} dynamicDecimal 2: ', n, opt);
      let rounded = this.roundDecimal(n, opt);
      let decimal = opt.decimal;
      const tinyDecimal = opt.tinySupport ?? 0; // default is not support tiny
      const minTinySupport = 10 ** -tinyDecimal;
      if (Math.abs(n) >= minTinySupport && rounded === 0 && tinyDecimal > 0) {
        rounded = 0;
        const maxZeroAfterPeriodChar = Math.log10(1 / minTinySupport);
        while (rounded === 0 && decimal <= maxZeroAfterPeriodChar) {
          decimal += 2; // jump 2 step at once
          rounded = this.roundDecimal(n, { ...opt, decimal: decimal });
        }
        this.DEBUG &&
          console.log(
            '{dynamicNumberFormat} rounded, decimal with tinySupport: ',
            rounded,
            decimal
          );
      }

      return {
        rounded: rounded,
        decimal: decimal,
      };
    }

    const dynamicDecimal = this.getDecimal(n);
    this.DEBUG && console.log('{dynamicNumberRound} dynamicDecimal 2: ', dynamicDecimal);

    return {
      rounded: this.roundDecimal(n, {
        ...opt,
        decimal: Math.min(dynamicDecimal, opt.maxDecimal ?? 9999),
      }),
      decimal: dynamicDecimal,
    };
  }

  static roundDecimal(n: number, opt: any) {
    // this also support negative decimal
    const p = 10 ** opt.decimal;
    switch (opt.round) {
      case 'up':
        return n > 0 ? Math.ceil(n * p) / p : Math.floor(n * p) / p;
      case 'down':
        return n > 0 ? Math.floor(n * p) / p : Math.ceil(n * p) / p;
      case 'auto':
      default:
        return Math.round(n * p) / p;
    }
  }

  // auto smart decimal
  static getDecimal(n: number): number {
    const MeaningfulLength = this.MEANINGFUL_LENGTH;
    let dynamicDecimal = 0;
    const positiveN = Math.abs(n);
    if (Math.abs(n) >= 1) {
      const intDigitCount = Math.floor(Math.log10(positiveN)) + 1;
      dynamicDecimal = intDigitCount > MeaningfulLength ? 0 : MeaningfulLength - intDigitCount;
      this.DEBUG &&
        console.log(
          '{dynamicNumberRound} intDigitCount, dynamicDecimal: ',
          intDigitCount,
          dynamicDecimal
        );
    } else {
      // it's +-0.0000abcdefghi => zeroAfterDecimalPlace=-4
      const zeroAfterDecimalPlace = positiveN === 0 ? 0 : Math.floor(Math.log10(positiveN)) + 1;

      // +-0.0000abcdef
      dynamicDecimal =
        zeroAfterDecimalPlace > MeaningfulLength ? 0 : MeaningfulLength - zeroAfterDecimalPlace;
    }

    this.DEBUG && console.log('{getDecimal} dynamicDecimal: ', dynamicDecimal);

    return dynamicDecimal;
  }

  static defaultValue(opt: DynamicFormatOption = {}): string {
    return opt.defaultValue !== undefined ? opt.defaultValue : this.EMPTY_NUMBER;
  }

  static test_roundDecimal() {
    const testCases: {
      n: number;
      expected: number;
      opt?: DynamicFormatOption;
      desc?: string;
    }[] = [
      // auto
      { n: 10.123, expected: 11, opt: { round: 'up', decimal: 0 }, desc: 'round up' },
      { n: 10.123, expected: 10, opt: { round: 'down', decimal: 0 }, desc: 'round down' },
      { n: 10.123, expected: 10, opt: { round: 'auto', decimal: 0 }, desc: 'round auto down' },
      { n: 10.523, expected: 11, opt: { decimal: 0 }, desc: 'round auto up' },
      {
        n: 11101.123,
        expected: 11100,
        opt: { decimal: -2 },
        desc: 'decimal negative round auto down',
      },
      {
        n: 11150.123,
        expected: 11200,
        opt: { decimal: -2 },
        desc: 'decimal negative round auto up',
      },
      {
        n: 11101.123,
        expected: 11200,
        opt: { decimal: -2, round: 'up' },
        desc: 'decimal negative force round up',
      },
      {
        n: 11100.123,
        expected: 11200,
        opt: { decimal: -2, round: 'up' },
        desc: 'decimal negative force round up',
      },
      {
        n: 11199.123,
        expected: 11100,
        opt: { decimal: -2, round: 'down' },
        desc: 'decimal negative force round down',
      },

      // zero
      { n: 0, expected: 0, opt: { decimal: -2, round: 'down' }, desc: 'zero should work' },

      // negative
      { n: -10.123, expected: -11, opt: { round: 'up', decimal: 0 }, desc: 'round up' },
      { n: -10.123, expected: -10, opt: { round: 'down', decimal: 0 }, desc: 'round down' },
      { n: -10.123, expected: -10, opt: { round: 'auto', decimal: 0 }, desc: 'round auto down' },
      { n: -10.523, expected: -11, opt: { decimal: 0 }, desc: 'round auto up' },
      {
        n: -11101.123,
        expected: -11100,
        opt: { decimal: -2 },
        desc: 'decimal negative round auto down',
      },
      {
        n: -11150.123,
        expected: -11200,
        opt: { decimal: -2 },
        desc: 'decimal negative round auto up',
      },
      {
        n: -11101.123,
        expected: -11200,
        opt: { decimal: -2, round: 'up' },
        desc: 'decimal negative force round up',
      },
      {
        n: -11100.123,
        expected: -11200,
        opt: { decimal: -2, round: 'up' },
        desc: 'decimal negative force round up',
      },
      {
        n: -11199.123,
        expected: -11100,
        opt: { decimal: -2, round: 'down' },
        desc: 'decimal negative force round down',
      },
    ];

    for (let i = 0; i < testCases.length; i += 1) {
      const t = testCases[i];
      console.log('{Checking} t: ', t);
      const r = DynamicNumberFormat.roundDecimal(t.n, t.opt);
      if (r !== t.expected) {
        console.error('[FAILED] r, t', { actual: r, t });
      } else {
        console.info('[SUCCESS] t', t);
      }
    }
  }

  // TODO: Add jest test
  static test_dynamicNumberFormat() {
    const testCases: {
      n: number | undefined;
      expected: string;
      opt?: DynamicFormatOption;
      desc?: string;
    }[] = [
      // auto
      {
        n: 10000000.123,
        expected: '10,000,000',
        opt: undefined,
        desc: 'normal number should work',
      },
      { n: 10000, expected: '10,000', opt: undefined, desc: 'normal number should work' },
      {
        n: 10000.123456789,
        expected: '10,000.1',
        opt: undefined,
        desc: 'normal number should work',
      },
      { n: 1000.1299, expected: '1,000.13', opt: undefined, desc: 'normal number should work' },
      {
        n: -1000.1299,
        expected: '-1,000.13',
        opt: undefined,
        desc: 'normal number should work for negative',
      },
      {
        n: 0.000000000123123456789,
        expected: '0.000000000123123',
        opt: undefined,
        desc: 'auto should work in range 0-1',
      },
      {
        n: 0.000000000123456789,
        expected: '0.000000000123457',
        opt: undefined,
        desc: 'auto should work in range 0 -> -1',
      },
      {
        n: -0.000000000123123456789,
        expected: '-0.000000000123123',
        opt: undefined,
        desc: 'auto should work in range 0-1',
      },
      {
        n: -0.000000000123456789,
        expected: '-0.000000000123457',
        opt: undefined,
        desc: 'auto should work in range 0 -> -1',
      },

      // zero
      { n: 0, expected: '0', opt: { decimal: -2 }, desc: 'zero' },

      // fixed decimal
      {
        n: 123449.789,
        expected: '123,400',
        opt: { decimal: -2 },
        desc: 'decimal negative auto down',
      },
      {
        n: -123449.789,
        expected: '-123,400',
        opt: { decimal: -2 },
        desc: 'decimal negative with negative num auto up',
      },
      {
        n: 123456.789,
        expected: '123,500',
        opt: { decimal: -2 },
        desc: 'decimal negative auto up',
      },
      {
        n: -123456.789,
        expected: '-123,500',
        opt: { decimal: -2 },
        desc: 'decimal negative with negative num auto up',
      },

      // round direction
      { n: 1234.51, expected: '1,234.51', opt: { round: 'up' }, desc: 'can round up' },
      { n: 1234.51001, expected: '1,234.52', opt: { round: 'up' }, desc: 'can round up' },
      {
        n: -1234.51023,
        expected: '-1,234.52',
        opt: { round: 'up' },
        desc: 'can round up, negative',
      },
      { n: 1234.51023, expected: '1,234.51', opt: { round: 'down' }, desc: 'can round down' },
      { n: 1234.51923, expected: '1,234.51', opt: { round: 'down' }, desc: 'can round down' },
      {
        n: -1234.51023,
        expected: '-1,234.51',
        opt: { round: 'down' },
        desc: 'can round down, negative',
      },

      // meaningful
      {
        n: 1234.50023,
        expected: '1,234.50',
        opt: { round: 'down', meaningful: false },
        desc: 'meaningful',
      },
      {
        n: -1234.50023,
        expected: '-1,234.50',
        opt: { round: 'down', meaningful: false },
        desc: 'meaningful, negative',
      },
      {
        n: 0,
        expected: '0.0000',
        opt: { meaningful: false, decimal: 4 },
        desc: 'meaningful, negative',
      },
      {
        n: 0,
        expected: '0',
        opt: { meaningful: false, decimal: -2 },
        desc: 'meaningful, negative',
      },

      // tiny support
      { n: 0.0000000123, expected: '0', opt: { decimal: 2, tinySupport: 6 }, desc: 'tinySupport' },
      {
        n: -0.0000000123,
        expected: '-0',
        opt: { decimal: 2, tinySupport: 6 },
        desc: 'tinySupport, negative',
      },
      {
        n: 0.000123456789,
        expected: '0.0001',
        opt: { decimal: 2, tinySupport: 6 },
        desc: 'tinySupport',
      },
      {
        n: -0.000123456789,
        expected: '-0.0001',
        opt: { decimal: 2, tinySupport: 6 },
        desc: 'tinySupport, negative',
      },

      // show plus
      { n: 0, expected: '+0', opt: { showPlus: 1 }, desc: 'show plus' },
      { n: -0, expected: '-0', opt: { showPlus: 1 }, desc: 'show plus' },
      {
        n: 0.0000000123,
        expected: '+0',
        opt: { decimal: 2, tinySupport: 6, showPlus: 1 },
        desc: 'show plus',
      },
      {
        n: -0.0000000123,
        expected: '-0',
        opt: { decimal: 2, tinySupport: 6, showPlus: 1 },
        desc: 'show plus, negative',
      },
      {
        n: 0.000123456789,
        expected: '+0.0001',
        opt: { decimal: 2, tinySupport: 6, showPlus: 1 },
        desc: 'show plus',
      },
      {
        n: -0.000123456789,
        expected: '-0.0001',
        opt: { decimal: 2, tinySupport: 6, showPlus: 1 },
        desc: 'show plus, negative',
      },

      // maxDecimal
      {
        n: 0.000123456,
        expected: '+0.000123',
        opt: { decimal: undefined, tinySupport: 5, maxDecimal: 6, showPlus: 1 },
        desc: '',
      },
    ];

    for (let i = 0; i < testCases.length; i += 1) {
      const t = testCases[i];
      console.log('{Checking} t: ', t);
      const r = DynamicNumberFormat.format(t.n, t.opt);
      if (r !== t.expected) {
        console.error('[FAILED] r, t', { actual: r, t });
      } else {
        console.info('[SUCCESS] t', t);
      }
    }
  }
}

export const f = DynamicNumberFormat.safeFormat;

export const formatOrderId = (orderId?: string) => {
  if (!orderId) return '';

  const formatOrderIdRegex = /^(.{8}).*(.{8})$/;

  return orderId.replace(formatOrderIdRegex, '$1...$2');
};
