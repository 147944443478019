import { Filter, FunctionField, TextField, useListController, useTranslate } from 'react-admin';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { CGDatagrid } from '../../components/CGDatagrid';
import { CgList } from '../../components/CgList';
import { SelectListCurrencyInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import { ChoiceItem, TabsInput } from '../../components/TabsInput';
import { paths } from '../../../../routes/paths';
import React from 'react';
import { DashboardContent } from '../../../../layouts/dashboard';
import dayjs from 'dayjs';
import CGTextInput from '../../components/CGTextInput';
import CGFilterAgency from '../../components/CGFilterAgency';
import { CgDateTimeInput } from '../../components/CgDateTimeInput';
import { TIME_FORMAT } from 'src/configs/common.config';
import { Box, Typography } from '@mui/material';
import CopyBtn from 'src/components/button/CopyBtn';

const TYPE_OPTIONS: ChoiceItem[] = [
  { value: '', label: 'resource.all', badgeColor: 'default' },
  {
    value: 'IN',
    label: 'module.transaction.typeIn',
  },
  {
    value: 'OUT',
    label: 'module.transaction.typeOut',
  },
];

function ListFilter(props: any) {
  const { filterValues } = useListController();

  return (
    <Filter {...props}>
      <FilterGroup alwaysOn p={0} spacing={0}>
        <TabsInput choices={TYPE_OPTIONS} source="type" fullWidth />
        <FilterGroup>
          <CGFilterAgency source="agencyIds" label="module.transaction.fillterByAgency" />
          <CGTextInput label="module.transaction.fillterByTransaction" source="transactionId" />
          <CGTextInput label="module.transaction.userId" source="playerIds" />
          <SelectListCurrencyInput
            source="currencyCodes"
            label="module.transaction.currency"
            optionValue="code"
          />
          <CgDateTimeInput
            label="module.transaction.fromTime"
            source="fromTime"
            sx={{ width: 230 }}
          />
          <CgDateTimeInput
            source="toTime"
            label="module.transaction.toTime"
            sx={{ width: 230 }}
            minDate={filterValues?.fromTime ? dayjs(filterValues.fromTime) : undefined}
            type="to"
          />
        </FilterGroup>
      </FilterGroup>
    </Filter>
  );
}

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.transaction')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.transaction') },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <CgList filters={<ListFilter />}>
        <CGDatagrid rowClick={false} bulkActionButtons={false}>
          <TextField
            source="txReference"
            label="module.transaction.transactionId"
            width={307}
            minWidth={307}
            maxWidth={307}
          />
          <TextField
            source="players.agencies.name"
            label="module.transaction.operator"
            width={172}
            minWidth={172}
            maxWidth={172}
          />
          <FunctionField
            label="module.transaction.user"
            textAlign="left"
            minWidth={150}
            maxWidth={150}
            render={(record) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography>{record.players.name}</Typography>
                <CopyBtn text={record.playerId ?? record.userId} showMsg={false} />
              </Box>
            )}
          />

          <TextField
            source="currency"
            label="module.transaction.currency"
            width={119}
            minWidth={119}
            maxWidth={119}
          />
          <TextField
            source="source"
            label="module.transaction.event"
            width={134}
            minWidth={134}
            maxWidth={134}
          />
          <TextField
            source="type"
            label="module.transaction.type"
            width={112}
            minWidth={112}
            maxWidth={112}
          />
          <TextField
            source="amount"
            label="module.transaction.amount"
            width={125}
            minWidth={125}
            maxWidth={125}
          />
          <TextField
            source="beforeAmount"
            label="module.transaction.balanceBefore"
            width={160}
            minWidth={160}
            maxWidth={160}
          />
          <TextField
            source="afterAmount"
            label="module.transaction.balanceAfter"
            width={160}
            minWidth={160}
            maxWidth={160}
          />
          <FunctionField
            source="createdAt"
            label="module.transaction.createdAt"
            minWidth={180}
            maxWidth={200}
            render={(record) =>
              record.createdAt ? dayjs(record.createdAt).format(TIME_FORMAT) : ''
            }
          />
          {/*<DateField source="timestamp" label="module.transaction.time" showTime />*/}
        </CGDatagrid>
      </CgList>
    </DashboardContent>
  );
}

export default View;
