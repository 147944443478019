import React, { ReactNode } from 'react';
import { usePermissions, useRedirect, useTranslate } from 'react-admin';
import { AiOutlineDashboard } from 'react-icons/ai';
import { BiBuildings } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { HiDocumentDuplicate } from 'react-icons/hi2';
import { IoGameControllerOutline } from 'react-icons/io5';
import { MdCurrencyExchange, MdOutlineRuleFolder } from 'react-icons/md';
import { RiBankLine } from 'react-icons/ri';
import { VscSettings } from 'react-icons/vsc';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { isEmpty, keyBy } from 'lodash';
import { PermissionData } from '../../entities/permission.entity';
import { Resources } from '../../resources/resource';

interface MenuItem {
  icon: ReactNode;
  title: string;
  resource: string;
  path?: string;
  subMenus?: MenuItem[];
  disabled?: boolean;
  requireAdmin?: boolean;
}
const menus: MenuItem[] = [
  {
    icon: <AiOutlineDashboard />,
    title: 'resource.dashboard',
    path: '/',
    resource: '',
  },
  {
    icon: <MdCurrencyExchange />,
    title: 'resource.currencies',
    resource: Resources.currency,
  },
  {
    icon: <FaUser />,
    title: 'resource.accounts',
    resource: Resources.user,
  },
  {
    icon: <IoGameControllerOutline />,
    title: 'resource.games',
    resource: Resources.game,
    subMenus: [
      {
        icon: <IoGameControllerOutline />,
        title: 'resource.gameDetail',
        resource: Resources.gameDetail,
      },
      {
        icon: <MdOutlineRuleFolder />,
        title: 'resource.rule',
        resource: Resources.gameRule,
      },
      {
        icon: <HiDocumentDuplicate />,
        title: 'resource.document',
        resource: Resources.gameDocument,
      },
    ],
  },
  {
    icon: <RiBankLine />,
    title: 'resource.transaction',
    resource: Resources.transaction,
  },
  {
    icon: <BiBuildings />,
    title: 'resource.agencies',
    resource: Resources.agency,
    requireAdmin: true,
  },
  {
    icon: <VscSettings />,
    title: 'resource.setting',
    resource: Resources.setting,
    disabled: true,
  },
];

export const AdminMenu = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions<PermissionData>();
  const checkPermission = keyBy(permissions?.permissions, 'resource');

  const isAdmin = !isEmpty(checkPermission['*']?.action);

  const checkDisplayMenu = ({ requireAdmin }: MenuItem) => (requireAdmin ? isAdmin : true);

  const displayMenu = menus.filter(checkDisplayMenu).map(({ subMenus, ...menu }) => ({
    ...menu,
    subMenus: subMenus?.filter(checkDisplayMenu),
  }));

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {displayMenu.map(({ icon, title, path, resource, subMenus, disabled }) => {
        const props = {
          icon,
          title: translate(title) || title,
          path: path || `/${resource}`,
          disabled,
        };
        if (!subMenus) {
          return <Menu {...props} />;
        }

        return (
          <SubMenu
            {...props}
            subs={subMenus.map(({ icon, title, path, resource }) => ({
              icon,
              title: translate(title) || title,
              path: path || `/${resource}`,
            }))}
          />
        );
      })}
    </List>
  );
};

interface IMenu {
  icon?: any;
  title: string;
  path?: string;
  disabled?: boolean;
}

const Menu = ({ icon, title, path, disabled }: IMenu) => {
  const redirect = useRedirect();

  return (
    <ListItemButton
      disabled={disabled}
      onClick={() => {
        path && redirect(path);
      }}
    >
      <ListItemIcon
        style={{
          minWidth: 30,
        }}
      >
        {!!icon && icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

interface ISubMenu extends IMenu {
  subs: IMenu[];
}

const SubMenu = ({ icon, title, path, subs }: ISubMenu) => {
  const redirect = useRedirect();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    path && redirect(path);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon
          style={{
            minWidth: 30,
          }}
        >
          {!!icon && icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {subs.map((sub) => (
            <ListItemButton
              key={sub.path}
              onClick={() => {
                sub.path && redirect(sub.path);
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center' }}>{!!sub.icon && sub.icon}</ListItemIcon>
              <ListItemText primary={sub.title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
