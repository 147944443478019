import {
  Box,
  Container,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import CGSaveButton from '../../components/CGSaveButton';
import { Resources } from '../resource';
import styles from './style.module.scss';
import { useCanEditGameRule } from './hooks/useCanEditGameRule';
import { GameRuleForm } from './gamerule.type';

interface CryptoGameConfig {
  transactionFee: number;
  minAmount: number;
  maxAmount: number;
  showVolCandleChart: boolean;
  showVolLineChart: boolean;
  metric24hLeverage: number;
}

interface ICryptoGameError {
  transactionFee?: string | null;
  minAmount?: string | null;
  maxAmount?: string | null;
  showVolCandleChart?: string | null;
  showVolLineChart?: string | null;
  metric24hLeverage?: string | null;
}

export function FormForCryptoGame(props: GameRuleForm<CryptoGameConfig>) {
  const translate = useTranslate();
  const editDisabled = !useCanEditGameRule();
  const [value, setValue] = useState<CryptoGameConfig>({
    transactionFee: props.value?.transactionFee ?? 0,
    minAmount: props.value?.minAmount ?? 0,
    maxAmount: props.value?.maxAmount ?? 0,
    showVolCandleChart: props.value?.showVolCandleChart ?? true,
    showVolLineChart: props.value?.showVolLineChart ?? true,
    metric24hLeverage: props.value?.metric24hLeverage ?? 0,
  });
  const [input, setInput] = useState({
    transactionFee: value.transactionFee + '',
    minAmount: value.minAmount + '',
    maxAmount: value.maxAmount + '',
    showVolCandleChart: value.showVolCandleChart,
    showVolLineChart: value.showVolLineChart,
    metric24hLeverage: value.metric24hLeverage + '',
  });
  const [errors, setErrors] = useState<ICryptoGameError>({});

  const onChange =
    (field: string, pattern?: any) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const nextStr = event.target.value;
      if (
        !/^\d*(\.\d+)?$/.test(nextStr) ||
        (pattern && !/^[0-9]+$/.test(nextStr) && !pattern.test(nextStr))
      ) {
        return;
      }

      setValue({ ...value, [field]: Number(nextStr) });
      setInput({
        ...input,
        [field]: nextStr,
      });
    };

  const handleBet = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const minAmount = Number(input.minAmount);
    const maxAmount = Number(input.maxAmount);
    const nextAmount = Number(e.target.value);
    const tempErrors: any = {};
    if (field === 'minAmount') {
      tempErrors.minAmount =
        nextAmount > maxAmount ? translate('module.game.minBetLessMaxBet') : null;
    }
    if (field === 'maxAmount') {
      tempErrors.maxAmount =
        nextAmount < minAmount ? translate('module.game.minBetLessMaxBet') : null;
    }

    setErrors(tempErrors);

    onChange(field)(e);
  };

  const handleBlur = (field: string) => {
    const fieldValue = get(input, field) || '';
    // remove 000xxx
    if (fieldValue.length >= 2 && fieldValue.startsWith('0')) {
      setInput({
        ...input,
        [field]: `${Number(fieldValue)}`,
      });
    }
  };

  const handleChangeChecbox = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [field]: event.target.checked });
    setInput({
      ...input,
      [field]: event.target.checked,
    });
  };

  return (
    <Container className={styles.hashGameFormContainer}>
      <Container className={styles.groupInput}>
        <TextField
          label={translate('module.gameRule.cryptoGame.transactionFee')}
          value={input.transactionFee}
          type="number"
          onChange={onChange('transactionFee')} // limit 2 digit along
          onBlur={() => handleBlur('transactionFee')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={
            <Box>
              <Typography variant="caption" component="p">
                {translate('module.gameRule.cryptoGame.transactionFeeHelper')}
              </Typography>
              <Box
                component={'ul'}
                sx={{
                  listStyleType: 'disc',
                  ml: 2,
                  mt: 1,
                }}
              >
                <li>
                  <Typography variant="caption" component="p" mt="0.5px">
                    {translate('module.gameRule.cryptoGame.closeFeeHelper')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption" component="p" mt="0.5px">
                    {translate('module.gameRule.cryptoGame.transactionFeeValueHelper')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption" component="p" mt="0.5px">
                    {translate('module.gameRule.cryptoGame.feeRateHelper')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption" component="p" mt="0.5px">
                    {translate('module.gameRule.cryptoGame.feeHelper')}
                  </Typography>
                </li>
              </Box>
            </Box>
          }
          placeholder={translate('module.gameRule.cryptoGame.placeholderTransactionFee')}
          sx={{ width: 500 }}
          disabled={editDisabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">(%)</InputAdornment>,
          }}
        />
        <Box
          sx={{
            width: 500,
            display: 'flex',
            justifyContent: 'space-around',
            gap: 4,
          }}
        >
          <TextField
            label={translate('module.gameRule.cryptoGame.minAmount')}
            value={input.minAmount}
            type="number"
            helperText={
              !!errors?.minAmount ? (
                <span style={{ color: 'red' }}>{errors.minAmount}</span>
              ) : (
                translate('module.game.minBetExplan')
              )
            }
            placeholder={translate('module.game.placeholderMinBet')}
            sx={{ width: '50%' }}
            onChange={handleBet('minAmount')}
            onBlur={() => handleBlur('minAmount')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            disabled={editDisabled}
          />
          <TextField
            label={translate('module.gameRule.cryptoGame.maxAmount')}
            value={input.maxAmount}
            type="number"
            helperText={
              !!errors?.maxAmount ? (
                <span style={{ color: 'red' }}>{errors.maxAmount}</span>
              ) : (
                translate('module.game.maxBetExplan')
              )
            }
            placeholder={translate('module.game.placeholderMaxBet')}
            sx={{ width: '50%' }}
            onChange={handleBet('maxAmount')}
            onBlur={() => handleBlur('maxAmount')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            disabled={editDisabled}
          />
        </Box>

        <Box
          sx={{
            width: 500,
            // display: 'flex',
            // justifyContent: 'space-around',
            // gap: 4,
          }}
        >
          <TextField
            label={translate('module.gameRule.cryptoGame.metric24hLeverage')}
            value={input.metric24hLeverage}
            type="number"
            helperText={
              !!errors?.metric24hLeverage ? (
                <span style={{ color: 'red' }}>{errors.minAmount}</span>
              ) : (
                translate('module.gameRule.cryptoGame.metric24hLeverageHelper')
              )
            }
            placeholder={translate('module.gameRule.cryptoGame.metric24hLeveragePlaceholder')}
            sx={{ width: '100%' }}
            onChange={handleBet('metric24hLeverage')}
            onBlur={() => handleBlur('metric24hLeverage')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            disabled={editDisabled}
          />
        </Box>

        <Box
          sx={{
            width: 500,
            display: 'block',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={input.showVolCandleChart}
                onChange={handleChangeChecbox('showVolCandleChart')}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={translate('module.game.showVolCandleChartExplan')}
          />

          <FormControlLabel
            control={
              <Switch
                checked={input.showVolLineChart}
                onChange={handleChangeChecbox('showVolLineChart')}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={translate('module.game.showVolLineChartExplan')}
          />
        </Box>

        <CGSaveButton
          className={styles.button}
          resource={Resources.gameRule}
          type="create"
          variant="contained"
          color="success"
          data={{
            gameId: props.gameId,
            ruleConfig: value,
          }}
          message={{ success: 'message.configSaved' }}
          disabled={editDisabled || Object.values(errors).some((err) => !!err)}
        />
      </Container>
      <Container className={styles.actions}></Container>
    </Container>
  );
}
