import React from 'react';
import * as chartService from '../../../services/chartService';
import { useGetIdentity } from 'react-admin';
import { UserModel } from '../../../entities/user.entity';

const useInOutCome = (filterData: chartService.FilterParam) => {
  const [data, setData] = React.useState({} as any);
  const [loading, setLoading] = React.useState(false);
  const { data: userData } = useGetIdentity();
  const user = userData as UserModel;
  const { agencyId, gameIds, fromTime, toTime } = filterData?.filter ?? {};

  React.useEffect(() => {
    if (user) {
      setLoading(true);
      chartService
        .getInOutCome({ filter: { agencyId, gameIds, fromTime, toTime } })
        .then((response: any) => {
          setData(response.data.data[0]);
          setLoading(false);
        });
    } else {
      setData(null);
    }
  }, [user, agencyId, gameIds, fromTime, toTime]);

  return {
    data,
    loading,
  };
};

export default useInOutCome;
