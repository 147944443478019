import { NavItemBaseProps } from 'src/components/nav-section';
import { paths } from 'src/routes/paths';
import { AccountDrawerProps } from '../components/account-drawer';
import { ICONS } from './icons';

export interface LeftMenuItem {
  subheader?: string;
  items: NavItemBaseProps[];
}

export const adminLeftMenu: LeftMenuItem[] = [
  /**
   * Management
   */
  {
    subheader: 'resource.agency',
    items: [
      { title: 'resource.dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
      { title: 'resource.currency', path: paths.dashboard.currency, icon: ICONS.invoice },
      { title: 'resource.account', path: paths.dashboard.account, icon: ICONS.user },
      {
        title: 'resource.game',
        path: paths.dashboard.game.root,
        icon: ICONS.course,
        children: [
          { title: 'resource.games', path: paths.dashboard.game.root, icon: ICONS.job },
          {
            title: 'resource.gameDetail',
            path: paths.dashboard.game.gameDetail,
            icon: ICONS.label,
          },
          {
            title: 'resource.gameRule',
            path: paths.dashboard.game.gameRule,
            icon: ICONS.parameter,
          },
          { title: 'resource.document', path: paths.dashboard.game.gameDocument, icon: ICONS.file },
        ],
      },
      { title: 'resource.transaction', path: paths.dashboard.transaction, icon: ICONS.banking },
    ],
  },
  /**
   * Internal admin setting
   */
  {
    subheader: 'resource.superAdmin',
    items: [
      { title: 'resource.agencies', path: paths.dashboard.agency.root, icon: ICONS.menuItem },
      // { title: 'resource.hashMonitor', path: paths.dashboard.hashMonitor, icon: ICONS.menuItem },
      { title: 'resource.users', path: paths.dashboard.users.root, icon: ICONS.user },
    ].map((item) => ({ ...item, roles: ['*'] })),
  },
];

export const accountMenus: AccountDrawerProps['data'] = [
  {
    label: 'account.home',
    href: '/',
    icon: ICONS.home,
  },
  {
    label: 'account.profile',
    href: '#',
    icon: ICONS.profile,
  },
  {
    label: 'account.projects',
    href: '#',
    icon: ICONS.projects,
    info: '3',
  },
  {
    label: 'account.subscription',
    href: '#',
    icon: ICONS.subscription,
  },
  {
    label: 'account.security',
    href: '#',
    icon: ICONS.security,
  },
  {
    label: 'account.accountSettings',
    href: '#',
    icon: ICONS.settings,
  },
];
