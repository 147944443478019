import { usePermissions } from 'react-admin';
import { PermissionData, RoleAction } from 'src/pages/admin/entities/permission.entity';
import { Resources } from '../../resource';

export function useCanEditGameRule() {
  const { permissions } = usePermissions<PermissionData>();
  const pers = permissions?.permissions || [];
  const per = pers.find(({ resource, action }) => {
    const isMatchResource = resource === '*' || resource === Resources.gameRule;
    const isMatchAction = action.includes(RoleAction._all) || action.includes(RoleAction.edit);
    return isMatchResource && isMatchAction;
  });
  return !!per;
}
