// @ts-nocheck
import { merge } from 'lodash';
import { TranslationMessages } from 'react-admin';
import en from './en';
import packageLanguage from './packages/ar.json';

const raLanguage = {
  ra: {
    action: {
      add_filter: 'إضافة فلتر',
      add: 'إضافة',
      back: 'رجوع',
      bulk_actions: 'عنصر واحد محدد |||| %{smart_count} عناصر محددة',
      cancel: 'إلغاء',
      clear_array_input: 'مسح القائمة',
      clear_input_value: 'مسح القيمة',
      clone: 'استنساخ',
      confirm: 'تأكيد',
      create: 'إنشاء',
      create_item: 'إنشاء %{item}',
      delete: 'حذف',
      edit: 'تعديل',
      export: 'تصدير',
      list: 'قائمة',
      refresh: 'تحديث',
      remove_filter: 'إزالة هذا الفلتر',
      remove_all_filters: 'إزالة جميع الفلاتر',
      remove: 'إزالة',
      save: 'حفظ',
      search: 'بحث',
      select_all: 'تحديد الكل',
      select_row: 'تحديد هذا الصف',
      show: 'عرض',
      sort: 'فرز',
      undo: 'تراجع',
      unselect: 'إلغاء التحديد',
      expand: 'توسيع',
      close: 'إغلاق',
      open_menu: 'فتح القائمة',
      close_menu: 'إغلاق القائمة',
      update: 'تحديث',
      move_up: 'نقل للأعلى',
      move_down: 'نقل للأسفل',
      open: 'فتح',
      toggle_theme: 'تبديل الوضع الفاتح/الداكن',
      select_columns: 'الأعمدة',
      update_application: 'إعادة تحميل التطبيق',
    },
    boolean: {
      true: 'نعم',
      false: 'لا',
      null: ' ',
    },
    page: {
      create: 'إنشاء %{name}',
      dashboard: 'لوحة التحكم',
      edit: 'تعديل %{name} %{recordRepresentation}',
      error: 'حدث خطأ ما',
      list: '%{name}',
      loading: 'جارٍ التحميل',
      not_found: 'غير موجود',
      show: 'عرض %{name} %{recordRepresentation}',
      empty: 'لا يوجد %{name} بعد.',
      invite: 'هل ترغب في إضافة واحد؟',
    },
    input: {
      file: {
        upload_several: 'قم بإسقاط بعض الملفات للتحميل، أو انقر لاختيار واحدة.',
        upload_single: 'قم بإسقاط ملف للتحميل، أو انقر لاختياره.',
      },
      image: {
        upload_several: 'قم بإسقاط بعض الصور للتحميل، أو انقر لاختيار واحدة.',
        upload_single: 'قم بإسقاط صورة للتحميل، أو انقر لاختيارها.',
      },
      references: {
        all_missing: 'غير قادر على العثور على بيانات المراجع.',
        many_missing: 'على الأقل واحدة من المراجع المرتبطة لم تعد متاحة.',
        single_missing: 'المرجع المرتبط لم يعد متاحًا.',
      },
      password: {
        toggle_visible: 'إخفاء كلمة المرور',
        toggle_hidden: 'عرض كلمة المرور',
      },
    },
    message: {
      about: 'حول',
      are_you_sure: 'هل أنت متأكد؟',
      auth_error: 'حدث خطأ أثناء التحقق من رمز المصادقة.',
      bulk_delete_content:
        'هل أنت متأكد أنك تريد حذف هذا %{name}؟ |||| هل أنت متأكد أنك تريد حذف هذه %{smart_count} العناصر؟',
      bulk_delete_title: 'حذف %{name} |||| حذف %{smart_count} %{name}',
      bulk_update_content:
        'هل أنت متأكد أنك تريد تحديث هذا %{name}؟ |||| هل أنت متأكد أنك تريد تحديث هذه %{smart_count} العناصر؟',
      bulk_update_title: 'تحديث %{name} |||| تحديث %{smart_count} %{name}',
      clear_array_input: 'هل أنت متأكد أنك تريد مسح القائمة بالكامل؟',
      delete_content: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
      delete_title: 'حذف %{name} #%{id}',
      details: 'تفاصيل',
      error: 'حدث خطأ في العميل ولم يتمكن طلبك من الاكتمال.',
      invalid_form: 'النموذج غير صالح. يرجى التحقق من الأخطاء',
      loading: 'يرجى الانتظار',
      no: 'لا',
      not_found: 'إما أنك كتبت عنوان URL خاطئ، أو أنك اتبعت رابطًا خاطئًا.',
      yes: 'نعم',
      unsaved_changes: 'بعض تغييراتك لم تُحفظ. هل أنت متأكد أنك تريد تجاهلها؟',
    },
    navigation: {
      no_results: 'لم يتم العثور على نتائج',
      no_more_results: 'رقم الصفحة %{page} خارج الحدود. حاول الصفحة السابقة.',
      page_out_of_boundaries: 'رقم الصفحة %{page} خارج الحدود',
      page_out_from_end: 'لا يمكن الذهاب بعد الصفحة الأخيرة',
      page_out_from_begin: 'لا يمكن الذهاب قبل الصفحة 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} من %{total}',
      partial_page_range_info: '%{offsetBegin}-%{offsetEnd} من أكثر من %{offsetEnd}',
      current_page: 'الصفحة %{page}',
      page: 'اذهب إلى الصفحة %{page}',
      first: 'اذهب إلى الصفحة الأولى',
      last: 'اذهب إلى الصفحة الأخيرة',
      next: 'اذهب إلى الصفحة التالية',
      previous: 'اذهب إلى الصفحة السابقة',
      page_rows_per_page: 'صفوف لكل صفحة:',
      skip_nav: 'تخطي إلى المحتوى',
    },
    sort: {
      sort_by: 'ترتيب حسب %{field} %{order}',
      ASC: 'تصاعدي',
      DESC: 'تنازلي',
    },
    auth: {
      auth_check_error: 'يرجى تسجيل الدخول للاستمرار',
      user_menu: 'الملف الشخصي',
      username: 'اسم المستخدم',
      password: 'كلمة المرور',
      sign_in: 'تسجيل الدخول',
      sign_in_error: 'فشل المصادقة، يرجى المحاولة مرة أخرى',
      logout: 'تسجيل الخروج',
    },
    notification: {
      updated: 'تم تحديث العنصر |||| تم تحديث %{smart_count} عنصر',
      created: 'تم إنشاء العنصر',
      deleted: 'تم حذف العنصر |||| تم حذف %{smart_count} عنصر',
      bad_item: 'عنصر غير صحيح',
      item_doesnt_exist: 'العنصر غير موجود',
      http_error: 'خطأ في الاتصال بالخادم',
      data_provider_error: 'خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.',
      i18n_error: 'تعذر تحميل الترجمات للغة المحددة',
      canceled: 'تم إلغاء الإجراء',
      logged_out: 'انتهت جلستك، يرجى إعادة الاتصال.',
      not_authorized: 'لست مخولًا للوصول إلى هذا المورد.',
      application_update_available: 'نسخة جديدة متاحة.',
    },
    validation: {
      required: 'مطلوب',
      minLength: 'يجب أن يكون على الأقل %{min} حرف',
      maxLength: 'يجب أن يكون %{max} حرف أو أقل',
      minValue: 'يجب أن يكون على الأقل %{min}',
      maxValue: 'يجب أن يكون %{max} أو أقل',
      number: 'يجب أن يكون رقمًا',
      email: 'يجب أن يكون بريدًا إلكترونيًا صالحًا',
      oneOf: 'يجب أن يكون واحدًا من: %{options}',
      regex: 'يجب أن يتطابق مع تنسيق معين (regexp): %{pattern}',
      unique: 'يجب أن يكون فريدًا',
    },
    saved_queries: {
      label: 'استعلامات محفوظة',
      query_name: 'اسم الاستعلام',
      new_label: 'حفظ الاستعلام الحالي...',
      new_dialog_title: 'حفظ الاستعلام الحالي كـ',
      remove_label: 'إزالة الاستعلام المحفوظ',
      remove_label_with_name: 'إزالة الاستعلام "%{name}"',
      remove_dialog_title: 'إزالة الاستعلام المحفوظ؟',
      remove_message: 'هل أنت متأكد أنك تريد إزالة هذا العنصر من قائمة الاستعلامات المحفوظة لديك؟',
      help: 'قم بتصفية القائمة واحفظ هذا الاستعلام لاحقًا',
    },
    configurable: {
      customize: 'تخصيص',
      configureMode: 'تكوين هذه الصفحة',
      inspector: {
        title: 'المفتش',
        content: 'مرر فوق عناصر واجهة المستخدم لتكوينها',
        reset: 'إعادة تعيين الإعدادات',
        hideAll: 'إخفاء الكل',
        showAll: 'عرض الكل',
      },
      Datagrid: {
        title: 'شبكة البيانات',
        unlabeled: 'عمود غير معنون #%{column}',
      },
      SimpleForm: {
        title: 'نموذج',
        unlabeled: 'إدخال غير معنون #%{input}',
      },
      SimpleList: {
        title: 'قائمة',
        primaryText: 'النص الأساسي',
        secondaryText: 'النص الثانوي',
        tertiaryText: 'النص الثالث',
      },
    },
  },
};

const lang: TranslationMessages = merge({}, en, raLanguage, packageLanguage);

export default lang;
