import { MdDoubleArrow } from 'react-icons/md';
import { Box, Stack, Typography } from '@mui/material';
import { toFloats } from 'src/utils/format-number';
import { colors } from 'src/configs/color.config';
import { getSymbolFromPair } from 'src/utils/currency';
import PnLValueFormatted from './PnLValueFormatted';
import { f } from 'src/utils/format';
import { useTranslate } from 'react-admin';
import dayjs from 'dayjs';
import { TIME_FORMAT } from 'src/configs/common.config';
import { currency } from './Currency';

type Props = {
  order: any;
};

function getBoOrderResult(order: any): { result: string; reward: number; pnl: number } {
  const { status, transactionType, openPrice, exitPrice } = order ?? {};
  const amount = Number(order.amount);
  const winRate = Number(order.winRate);

  if (status !== 'Exit') return { result: 'pending', reward: 0, pnl: 0 };
  if (!exitPrice || !openPrice) return { result: 'lose', reward: -amount, pnl: -amount };

  if (exitPrice === openPrice) return { result: 'draw', reward: amount, pnl: 0 };

  const isUp = exitPrice > openPrice;

  if (isUp && transactionType === 'Up')
    return { result: 'win', reward: amount * winRate, pnl: amount * winRate - amount };
  if (!isUp && transactionType === 'Down')
    return { result: 'win', reward: amount * winRate, pnl: amount * winRate - amount };

  return { result: 'lose', reward: -amount, pnl: -amount };
}

export const BoOrderDetail = (props: Props) => {
  const t = useTranslate();

  const { order } = props;
  const { result, reward, pnl } = getBoOrderResult(order);
  const stateColor = reward > 0 ? colors.itemGreen : '#ff5959';

  return (
    <div>
      <Box
        sx={{
          px: 3,
          pt: 6,
          pb: '29px',
        }}
      >
        <Box display="flex" alignItems="center" gap={1} mb="13px">
          <Typography fontSize={18}>{order.pair?.replace('_', '/')}</Typography>
        </Box>
        <Stack pt={2} gap={0}>
          <Box m={0}>
            <Box display={'flex'} alignItems="center" gap={1}>
              <Typography fontSize={32} fontWeight={700} color={stateColor}>
                {result === 'pending'
                  ? '--'
                  : `${pnl > 0 ? '+' : ''} ${f(pnl, { maxDecimal: 3 })} `}
              </Typography>
              {currency.find((i) => i.name === order.currency)?.icon}
            </Box>
            {result !== 'pending' ? (
              <Typography color={stateColor}>{t(`module.game.gameDetail.${result}`)}</Typography>
            ) : undefined}
          </Box>
        </Stack>
      </Box>
      <Box px={4} lineHeight={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          px={0}
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.betType')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            {order.transactionType === 'Up' ? (
              <>
                <MdDoubleArrow
                  style={{
                    transform: 'rotate(-0.25turn)',
                    color: colors.itemGreen,
                    width: 18,
                    height: 18,
                  }}
                />
                <Typography fontSize={16}>{t('module.game.gameDetail.crypto.buyUp')}</Typography>
              </>
            ) : (
              <>
                <MdDoubleArrow
                  style={{
                    transform: 'rotate(0.25turn)',
                    color: colors.btnBgRed,
                    width: 18,
                    height: 18,
                  }}
                />
                <Typography fontSize={16}>{t('module.game.gameDetail.crypto.buyDown')}</Typography>
              </>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.betAmount')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            <Typography fontSize={16} lineHeight="1" mt="2px">
              {f(order.amount, { decimal: 2, defaultValue: '--' })}
            </Typography>
            {currency.find((i) => i.name === order.currency)?.icon}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.bo.winRate')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            x{order.winRate}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.pnl')}</Typography>
          <Box display="flex" alignItems="center" gap="7px">
            {order.status === 'Exit' ? (
              <Typography
                fontSize={16}
                color={pnl >= 0 ? 'primary.main' : '#ff4651'}
                lineHeight="1"
                mt="2px"
              >
                <PnLValueFormatted pnl={pnl} />
              </Typography>
            ) : (
              <Typography fontSize={16} lineHeight="1" mt="2px">
                --
              </Typography>
            )}
            {currency.find((i) => i.name === order.currency)?.icon}
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.bo.openPrice')}</Typography>
          <Box display="flex" gap={1}>
            <Typography fontSize={16} lineHeight="1">
              {toFloats({ value: order.openPrice, emptyValue: '--' })}
            </Typography>
            <img src={getSymbolFromPair(order.pair)} width="15px" height="15px" alt="" />
          </Box>
        </Box>
        {order?.exitPrice ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>{t('module.game.gameDetail.bo.exitPrice')}</Typography>
            <Box display="flex" gap={1}>
              <Typography fontSize={16} lineHeight="1">
                {!!+order?.exitPrice ? f(order?.exitPrice ?? 0, { decimal: 2 }) : '--'}
              </Typography>
              <img src={getSymbolFromPair(order.pair)} width="15px" height="15px" alt="" />
            </Box>
          </Box>
        ) : undefined}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.bettingTime')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            {dayjs(order.createdAt).format(TIME_FORMAT)}
          </Typography>
        </Box>
        {order.exitAt && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="18px"
            borderBottom="1px solid #38383d"
          >
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.exitAt')}</Typography>
            <Typography fontSize={16} lineHeight="1">
              {order.exitAt && dayjs(order.exitAt).format(TIME_FORMAT)}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="18px"
          borderBottom="1px solid #38383d"
        >
          <Typography fontSize={14}>{t('module.game.gameDetail.crypto.orderStatus')}</Typography>
          <Typography fontSize={16} lineHeight="1">
            {t(`module.game.gameDetail.crypto.${order.status}`)}
            {!!order.exit_reason && <>&nbsp; ({t(order.exit_reason.toLowerCase())})</>}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" py={4}>
          <Box display="flex" width="110px">
            <Typography fontSize={14}>{t('module.game.gameDetail.crypto.orderId')}</Typography>
          </Box>

          <Box display="flex" flexGrow="1" gap={2} justifyContent="flex-end" alignItems="center">
            <Typography fontSize={14}>{order.id}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
