import { Stack, StackProps } from '@mui/material';

interface FilterProps extends StackProps {
  alwaysOn?: boolean;
}

function FilterGroup(props: FilterProps) {
  return (
    <Stack
      alignItems={{ xs: 'flex-end', md: 'stretch' }}
      direction={{ xs: 'column', md: 'row' }}
      flexWrap="wrap"
      spacing={2}
      p={2}
      {...props}
    />
  );
}

export default FilterGroup;
