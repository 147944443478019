import axios from "axios";
import { getAuth } from "../providers/AppAuthProvider";
import {map} from "lodash";
import {httpClient} from "../providers/AppDataProvider";
import {getAllResource} from "./gameService";

const baseUrl = process.env.REACT_APP_API_URL;
const resource = "currency"

export async function getWallets(params: any) {
  const url = `${baseUrl}/currency`;
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
  };

  return axios
    .get<any>(url, {
      headers,
      ...params,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data?.data || [],
          total: resp?.data?.total || 0,
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

export const getAll = getAllResource(resource);
