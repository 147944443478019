import React from 'react';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

function AccountDialog(props: any) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <List sx={{ pt: 0 }}>
        {(props.selectedValue || []).map((history: any) => (
          <ListItem disableGutters key={history.id}>
            <ListItemButton>
              <ListItemText primary={history.id} />
              <ListItemText primary={history.lotteryResultHash} />
              <ListItemText primary={history.amount} />
              <ListItemText primary={history.bet} />
              <ListItemText primary={dayjs(history.createdAt).format('DD/MM/YYYY HH:mm:ss')} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default AccountDialog;
