import axios, { AxiosRequestConfig } from 'axios';
import { map } from 'lodash';
import { mergeQueryParams } from 'src/utils/param';
import { getAuth } from '../providers/AppAuthProvider';

const baseUrl = process.env.REACT_APP_API_URL;
const resource = 'charts/revenue';
const resourcePlayer = 'charts/player';
const resourceInOutCome = 'charts/in-outcome';
const resourceTxns = 'charts/txns';

export type TimeDuration = 'week' | 'month' | 'year';
export type FilterParam = {
  filter?: {
    agencyId?: string;
    gameIds?: string[];
    fromTime?: string;
    toTime?: string;
  };
};

export async function getRevenue(params: FilterParam = {}, config?: AxiosRequestConfig<any>) {
  const url = mergeQueryParams(`${baseUrl}/${resource}`, params);
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };

  return axios
    .get<any>(url, {
      headers,
      ...config,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data || {},
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

export async function getPlayer(params: FilterParam = {}) {
  const url = mergeQueryParams(`${baseUrl}/${resourcePlayer}`, params);

  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };
  resourceInOutCome;
  return axios
    .get<any>(url, {
      headers,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data || {},
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

export async function getInOutCome(params: any) {
  const url = mergeQueryParams(`${baseUrl}/${resourceInOutCome}`, params);
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };

  return axios
    .get<any>(url, {
      headers,
      ...params,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data || {},
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

export async function getTxnsByGame(params: FilterParam = {}) {
  const url = mergeQueryParams(`${baseUrl}/${resourceTxns}`, params);
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };

  return axios
    .get<any>(url, {
      headers,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data || {},
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

// export const getAll = getAllResource(resource);
