import { ResourceOptions } from "react-admin";
import { BsBuildings } from "react-icons/bs";
import { Resources } from "../resource";

export { CreateMode as create, default as edit } from "./Edit";
export { default as list } from "./List";
export { default as show } from "./Show";

export const name = Resources.agency;
export const icon = BsBuildings;
export const options: ResourceOptions = {
  label: "resource.agencies",
};
