import { useGetIdentity, useTranslate } from 'react-admin';
import { DonutChart } from '../../game-detail/DonutChart';
import { useMemo } from 'react';
import _ from 'lodash';
import { UserModel } from 'src/pages/admin/entities/user.entity';
import useInOutCome from '../hooks/useInOutCome';

interface DashboardInOutComeChartProps {
  filterValues: any;
  isLoading: boolean;
}

export const DashboardInOutComeChart = ({
  filterValues,
  isLoading,
}: DashboardInOutComeChartProps) => {
  const translate = useTranslate();

  // const { data: userData } = useGetIdentity();
  // const user = userData as UserModel;

  const { data: dataInoutCome, loading: loadingPlayer } = useInOutCome(filterValues);

  const dataChartInoutCome = useMemo(() => {
    if (_.has(dataInoutCome, 'income')) {
      const obj = {
        series: [
          {
            label: `${translate('module.game.income')} ${!!dataInoutCome?.income?.percentage ? `(${dataInoutCome?.income?.percentage?.toFixed(2)}%)` : ''}`,
            value: Math.round(Number(dataInoutCome?.income?.value) * 100) / 100,
          },
          {
            label: `${translate('module.game.outcome')} ${!!dataInoutCome?.outcome?.percentage ? `(${dataInoutCome?.outcome?.percentage?.toFixed(2)}%)` : ''}`,
            value: Math.round(Number(dataInoutCome?.outcome?.value) * 100) / 100,
          },
        ],
        tooltips: [
          translate('tooltips.module.game.gameDetail.outcome'),
          translate('tooltips.module.game.gameDetail.income'),
        ],
      };
      return obj;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInoutCome]);

  if (!dataChartInoutCome) {
    return <></>;
  }

  return (
    <DonutChart
      title={`${translate('module.game.income')} / ${translate('module.game.outcome')}`}
      chart={dataChartInoutCome!}
    />
  );
};
