import { Button, ButtonOwnProps } from '@mui/material';
import { ComponentType, ReactNode } from 'react';
import { useCreate, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';

interface FilterProps extends ButtonOwnProps {
  alwaysOn?: boolean;
}

interface CGSaveButtonProps extends FilterProps {
  className?: string;
  resource: string;
  data: any;
  id?: string | number;
  type: 'create' | 'edit';
  label?: string;
  startIcon?: ReactNode;
  message?: {
    success?: string;
    error?: string;
  };
  onSucces?: () => void;
  component?: ComponentType<any>;
}

function CGSaveButton({
  className,
  resource,
  data,
  id,
  type,
  label,
  message,
  component: Component,
  onSucces,
  ...props
}: CGSaveButtonProps) {
  const translate = useTranslate();
  const _label = label || 'ra.action.save';
  const [create] = useCreate();
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSave = () => {
    const options = {
      onSuccess: () => {
        refresh();
        const text = message?.success || 'ra.notification.created';
        notify(text, { type: 'success' });
        onSucces && onSucces();
      },
      onError: (err: any) => {
        const text = message?.error || err?.message || err + '' || 'ra.notification.http_error';
        notify(text, { type: 'error' });
      },
    };
    if (type === 'create') {
      create(
        resource,
        {
          data,
        },
        options
      );
      return;
    } else {
      update(
        resource,
        {
          id,
          data,
        },
        options
      );
    }
  };

  if (Component) {
    return (
      <Component {...props} onClick={handleSave}>
        {translate(_label) || label}
      </Component>
    );
  }

  return (
    <Button {...props} className={className} onClick={handleSave}>
      {translate(_label) || label}
    </Button>
  );
}

export default CGSaveButton;
