import axios from "axios";
import { getAuth } from "../providers/AppAuthProvider";
import mockData from "../providers/mock-data.json";

const baseUrl = process.env.REACT_APP_API_URL;

export async function getUserTransaction(params: any) {
  const url = `${baseUrl}/player`;
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
  };

  return axios
    .get<any>(url, {
      headers,
      ...params,
    })
    .then((resp) => {
      if (resp?.status === 200) {
        return {
          data: resp?.data?.data || [],
          total: resp?.data?.total || 0,
        };
      }
      return {};
    })
    .catch(() => {
      return {};
    });
}

export async function getPlayerById(playerId: string | number) {
  const url = `${baseUrl}/player/${playerId}`;
  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: "application/json",
  };

  if (process.env.REACT_APP_MODE === "mock") {
    return new Promise((resolve, reject) => {
      resolve({
        status: 200,
        data: mockData.playerById,
      });
    });
  }

  return axios.get<any>(url, {
    headers,
  });
}
