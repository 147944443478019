import en from 'ra-language-english';
import { TranslationMessages } from 'react-admin';
import enPack from './packages/en.json';

const lang: TranslationMessages = {
  ...en,
  ...enPack,
};

export default lang;
