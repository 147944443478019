import React, { ReactNode } from 'react';
import _keyBy from 'lodash/keyBy';
import { Image, ImageProps } from 'src/components/image';
import { RiMoneyCnyCircleFill, RiMoneyDollarCircleFill } from 'react-icons/ri';
import { coinLinks } from './currency-url';

type CurrencyType = {
  name: string;
  icon: ReactNode;
  decimal: number;
};

export const currency: CurrencyType[] = [
  {
    name: 'USD',
    icon: <RiMoneyDollarCircleFill fontSize={24} color="#e9983d" />,
    decimal: 2,
  },
  {
    name: 'CNY',
    icon: <RiMoneyCnyCircleFill fontSize={24} color="#e9983d" />,
    decimal: 1,
  },
  {
    name: 'PHP',
    icon: <Image maxWidth={24} src="/assets/icons/currencies/php.svg" />,
    decimal: 1,
  },
  {
    name: 'USDT',
    icon: <Image width={24} src="/assets/icons/currencies/Tether.png" alt="" />,
    decimal: 2,
  },
  {
    name: 'BRL',
    icon: <Image width={24} src="/assets/icons/currencies/ic_brl.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'INR',
    icon: <Image width={24} src="/assets/icons/currencies/ic_inr.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'LTC',
    icon: <Image width={24} src="/assets/icons/currencies/ic_ltc.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'SOL',
    icon: <Image width={24} src="/assets/icons/currencies/ic_sol.svg" alt="" />,
    decimal: 2,
  },
];

export const currencyMap = _keyBy(currency, 'name');

export default function Currency({ pair, ...props }: { pair: string } & ImageProps) {
  const coinKey = pair ? pair.toUpperCase().split('_')[0] : '';

  return <Image src={coinLinks[coinKey]} alt={`${pair} icon`} {...(props as any)} />;
}
