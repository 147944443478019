import { Box, Container, TextField } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import CGSaveButton from '../../components/CGSaveButton';
import { Resources } from '../resource';
import styles from './style.module.scss';
import { useCanEditGameRule } from './hooks/useCanEditGameRule';
import { GAME_LISTS, GameRuleForm } from './gamerule.type';

// interface HashGameConfig {
//   winRate?: number | string;
//   minAmount?: number | string;
//   maxAmount?: number | string;
//   amountStep?: number | string;
//   roundTime?: number | string;
// }

interface HashGameConfig {
  winRate: number;
  tieRate?: number;
  minAmount: number;
  maxAmount: number;
  amountStep: number;
  roundTime: number;
  betAmounts: string;
}

interface IHashGameError {
  winRate?: string | null;
  minAmount?: string | null;
  maxAmount?: string | null;
  amountStep?: string | null;
  roundTime?: string | null;
  betAmounts?: string | null;
}

export function FormForHashGame(props: GameRuleForm<HashGameConfig>) {
  const translate = useTranslate();
  const editDisabled = !useCanEditGameRule();
  const [value, setValue] = useState<HashGameConfig>(
    props.value || {
      winRate: 0,
      [props.gameId === GAME_LISTS.BANKER_PLAYER ? 'tieRate' : '']: 0,
      minAmount: 0,
      maxAmount: 0,
      amountStep: 10,
      roundTime: 0,
      betAmounts: '',
    }
  );
  const [input, setInput] = useState({
    winRate: value.winRate + '',
    [props.gameId === GAME_LISTS.BANKER_PLAYER ? 'tieRate' : '']: value.tieRate + '',
    minAmount: value.minAmount + '',
    maxAmount: value.maxAmount + '',
    amountStep: value.amountStep + '',
    roundTime: value.roundTime + '',
    betAmounts: value.betAmounts ? value.betAmounts + '' : '',
  });
  const [errors, setErrors] = useState<IHashGameError>({});

  const onChange =
    (field: string, pattern?: any) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const nextStr = event.target.value;
      if (
        !/^\d*(\.\d+)?$/.test(nextStr) ||
        (pattern && !/^[0-9]+$/.test(nextStr) && !pattern.test(nextStr))
      ) {
        return;
      }

      setValue({ ...value, [field]: Number(nextStr) });
      setInput({
        ...input,
        [field]: nextStr,
      });
    };

  const onChangeBetAmounts = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nextStr = event.target.value;
    console.log('nextStr: ', nextStr);
    const whitelist = /^[0-9,]*$/;
    if (!whitelist.test(nextStr)) {
      return;
    }
    // regex for comma separated numbers, e.g. 1,2,3,4,5
    // const regex = /^(\d+,)*\d+$/;
    // const regex = /^(\d+)(,\d+)*$/;
    // console.log('result: ', regex.test(nextStr));
    // if (!regex.test(nextStr)) {
    //   return;
    // }

    setValue({ ...value, betAmounts: nextStr });
    setInput({
      ...input,
      betAmounts: nextStr,
    });
  };

  const handleBet = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const minAmount = Number(input.minAmount);
    const maxAmount = Number(input.maxAmount);
    const nextAmount = Number(e.target.value);
    const tempErrors: any = {};
    if (field === 'minAmount') {
      tempErrors.minAmount =
        nextAmount > maxAmount ? translate('module.game.minBetLessMaxBet') : null;
    }
    if (field === 'maxAmount') {
      tempErrors.maxAmount =
        nextAmount < minAmount ? translate('module.game.minBetLessMaxBet') : null;
    }

    setErrors(tempErrors);

    onChange(field)(e);
  };

  const handleBlur = (field: string) => {
    const fieldValue = get(input, field) || '';
    // remove 000xxx
    if (fieldValue.length >= 2 && fieldValue.startsWith('0')) {
      setInput({
        ...input,
        [field]: `${Number(fieldValue)}`,
      });
    }
  };

  return (
    <Container className={styles.hashGameFormContainer}>
      <Container className={styles.groupInput}>
        <TextField
          label={translate('module.gameRule.hashGame.winRate')}
          value={input.winRate}
          type="number"
          onChange={onChange('winRate', /^\d*(\.\d{0,2})?$/i)} // limit 2 digit along
          onBlur={() => handleBlur('winRate')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.game.winRateHelper')}
          placeholder={translate('module.game.placeholderWinRate')}
          sx={{ width: 500 }}
          disabled={editDisabled}
        />

        {props.gameId === GAME_LISTS.BANKER_PLAYER ? (
          <TextField
            label={translate('module.gameRule.hashGame.tieRate')}
            value={input.tieRate}
            type="number"
            onChange={onChange('tieRate', /^\d*(\.\d{0,2})?$/i)} // limit 2 digit along
            onBlur={() => handleBlur('tieRate')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            helperText={translate('module.game.tieRateHelper')}
            placeholder={translate('module.game.placeholderTieRate')}
            sx={{ width: 500 }}
            disabled={editDisabled}
          />
        ) : undefined}

        <Box
          sx={{
            width: 500,
            display: 'flex',
            justifyContent: 'space-around',
            gap: 4,
          }}
        >
          <TextField
            label={translate('module.gameRule.hashGame.minBet')}
            value={input.minAmount}
            type="number"
            helperText={
              !!errors?.minAmount ? (
                <span style={{ color: 'red' }}>{errors.minAmount}</span>
              ) : (
                translate('module.game.minBetExplan')
              )
            }
            placeholder={translate('module.game.placeholderMinBet')}
            sx={{ width: '50%' }}
            onChange={handleBet('minAmount')}
            onBlur={() => handleBlur('minAmount')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            disabled={editDisabled}
          />
          <TextField
            label={translate('module.gameRule.hashGame.maxBet')}
            value={input.maxAmount}
            type="number"
            helperText={
              !!errors?.maxAmount ? (
                <span style={{ color: 'red' }}>{errors.maxAmount}</span>
              ) : (
                translate('module.game.maxBetExplan')
              )
            }
            placeholder={translate('module.game.placeholderMaxBet')}
            sx={{ width: '50%' }}
            onChange={handleBet('maxAmount')}
            onBlur={() => handleBlur('maxAmount')}
            onKeyDown={(e) =>
              ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            disabled={editDisabled}
          />
        </Box>

        <TextField
          label={translate('module.gameRule.hashGame.amountStep')}
          value={input.amountStep}
          type="number"
          onChange={onChange('amountStep')}
          onBlur={() => handleBlur('amountStep')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          // helperText={translate('module.game.amountStepHelper')}
          placeholder={translate('module.game.placeholderAmountSteps')}
          sx={{ width: 500 }}
          disabled={editDisabled}
        />
        <TextField
          label={translate('module.gameRule.hashGame.roundTime')}
          value={input.roundTime}
          type="number"
          // helperText={translate('module.game.roundTimeHelper')}
          placeholder={translate('module.game.placeholderRoundTime')}
          onChange={onChange('roundTime', /^1?\d{0,2}$/i)}
          onBlur={() => handleBlur('roundTime')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          sx={{ width: 500 }}
          disabled={editDisabled}
        />

        <TextField
          label={translate('module.gameRule.hashGame.betAmounts')}
          value={input.betAmounts}
          helperText={translate('module.game.betAmountHelper')}
          placeholder={translate('module.game.placeholderBetAmounts')}
          onChange={onChangeBetAmounts}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          sx={{ width: 500 }}
          disabled={editDisabled}
        />

        <CGSaveButton
          className={styles.button}
          resource={Resources.gameRule}
          type="create"
          variant="contained"
          color="success"
          data={{
            gameId: props.gameId,
            ruleConfig: value,
          }}
          message={{ success: 'message.configSaved' }}
          disabled={editDisabled || Object.values(errors).some((err) => !!err)}
        />
      </Container>
      <Container className={styles.actions}></Container>
    </Container>
  );
}
