import type { CardProps } from '@mui/material/Card';
import Card from '@mui/material/Card';
import { useTheme, alpha as hexAlpha } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import { Chart, useChart } from 'src/components/chart';
import { fShortenNumber } from 'src/utils/format-number';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { Iconify } from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { f } from 'src/utils/format';

type Props = CardProps & {
  titleRevenue?: string;
  titleProfit?: string;
  tooltipRevenue?: string;
  tooltipProfit?: string;
  subheader?: string;
  chart: {
    colors?: string[];
    categories?: string[];
    series: {
      name?: string;
      data: number[];
    }[];
  };
};

export function RevenueChart({
  titleRevenue,
  titleProfit,
  tooltipRevenue,
  tooltipProfit,
  subheader,
  chart,
  ...other
}: Props) {
  const theme = useTheme();

  const chartColors = chart.colors ?? [
    theme.palette.success.dark,
    hexAlpha(theme.palette.error.main, 0.48),
  ];

  const chartOptions = useChart({
    colors: chartColors,
    legend: { show: true },
    xaxis: {
      categories: chart.categories,
      labels: {
        formatter: (value: string) => moment(new Date(value).toString()).format('DD-MM'),
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => fShortenNumber(value),
      },
      min: (min) => {
        if (min >= 0) return 0;

        // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
        const magnitude = Math.pow(10, Math.floor(Math.log10(-min)));
        const multiple = -min / magnitude; // example : 1.22
        const offset = multiple - Math.floor(multiple); // example : 0.22
        const buffer = offset < 0.5 ? 0.5 : 0;
        const bufferMagnitude = buffer * magnitude;
        const result = -(Math.round(multiple) * magnitude + bufferMagnitude);
        return result;
      },
    },
    tooltip: {
      y: {
        // formatter: (value: number) => fShortenNumber(value, { maximumFractionDigits: 1 }),
        formatter: (value: number) => f(value, { decimal: 2 }),
        title: {
          formatter: (seriesName: string) => {
            return seriesName.replace(/\s*\(.*?\)\s*/g, '');
          },
        },
      },
    },
  });

  const title =
    titleRevenue && titleProfit ? (
      <Stack flexDirection="row" alignItems="center">
        {titleRevenue}
        {tooltipRevenue && (
          <Tooltip
            title={<Typography fontSize={12}>{tooltipRevenue}</Typography>}
            placement="top"
            enterDelay={300}
          >
            <Iconify
              width={16}
              icon="eva:info-outline"
              ml="5px"
              sx={{ cursor: 'pointer', color: 'text.disabled' }}
            />
          </Tooltip>
        )}
        <Box marginX={1}>/</Box>
        {titleProfit}
        {tooltipProfit && (
          <Tooltip
            title={<Typography fontSize={12}>{tooltipProfit}</Typography>}
            placement="top"
            enterDelay={300}
          >
            <Iconify
              width={16}
              icon="eva:info-outline"
              ml="5px"
              sx={{ cursor: 'pointer', color: 'text.disabled' }}
            />
          </Tooltip>
        )}
      </Stack>
    ) : null;

  return (
    <Card {...other} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardHeader title={title} subheader={subheader} action={<></>} />
      <Chart
        type="line"
        series={chart.series}
        options={chartOptions}
        minHeight={'250px'}
        height="100%"
        sx={{ mb: 2, pl: 1, mt: 0, pr: 2, flex: 1 }}
      />
    </Card>
  );
}
