import {
  Create,
  Edit,
  Link,
  minValue,
  number,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  // TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { agencyStatus } from '../../providers/constants';
import useWallets from './useWallets';
import { forEach, get } from 'lodash';
import {
  Box,
  Breadcrumbs,
  Button as MUIButton,
  Card,
  CardActions,
  Typography,
} from '@mui/material';
import { Resources } from '../resource';
import { MdModeEdit } from 'react-icons/md';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { paths } from '../../../../routes/paths';
import { RouterLink } from '../../../../routes/components';
import { Iconify } from '../../../../components/iconify';
import React from 'react';
import { DashboardContent } from 'src/layouts/dashboard';
import CGTextInput from "../../components/CGTextInput";

const UserEditToolbar = () => (
  <Toolbar>
    <SaveButton
      transform={(data: any) => {
        const trimData: any = {};
        forEach(data, (v, k) => {
          if (typeof v === 'string' && v) {
            trimData[k] = v.trim();
          }
        });
        return { ...data, ...trimData };
      }}
      type="button"
    />
  </Toolbar>
);

const validateSpace = (value: string, allValues: any) => {
  if (value && !value.trim()) {
    return 'Required';
  }
  return undefined;
};

const form = (mode: 'add' | 'edit', wallets: any[], translate: any) => (
  <SimpleForm toolbar={<UserEditToolbar />}>
    <CGTextInput
      source="name"
      label="resource.name"
      validate={[required(), validateSpace]}
      sx={{ width: 250 }}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <CGTextInput
      source="apikey"
      label="module.agency.apiKey"
      validate={[required(), validateSpace]}
      sx={{ width: 250 }}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <CGTextInput
      source="secret"
      label="module.agency.secret"
      validate={[required(), validateSpace]}
      sx={{ width: 250 }}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <CGTextInput
      source="baseApiUrl"
      label="module.agency.baseApiUrl"
      sx={{ width: 250 }}
      validate={[required(), validateSpace]}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <CGTextInput
      source="whitelistedIps"
      label="module.agency.whiteListedIps"
      sx={{ width: 250 }}
      format={(value) => {
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        return (value || '') + '';
      }}
      parse={(value) => {
        if (typeof value === 'string') {
          return value
            .split(',')
            .map((x) => x.trim())
            .filter((x) => x);
        }
        return value || '';
      }}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <SelectInput
      source="currency"
      label="module.agency.wallet"
      sx={{ width: 250 }}
      validate={required()}
      choices={wallets}
      disabled={mode === 'edit'}
    />
    <NumberInput
      source="creditLimit"
      label="module.agency.creditLimit"
      size="medium"
      validate={[required(), number()]}
      sx={{ width: 250 }}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <NumberInput
      source="creditFrozen"
      label="module.agency.creditFrozen"
      size="medium"
      sx={{ width: 250 }}
      validate={[required(), number(), minValue(0)]}
      inputProps={{
        sx: {
          height: '56px',
          boxSizing: 'border-box',
        },
      }}
    />
    <SelectInput
      source="status"
      label="resource.status"
      sx={{ width: 250 }}
      choices={agencyStatus(translate)}
      defaultValue={get(agencyStatus(translate), '[0].id') || 'ACTIVE'}
      validate={[required(translate('resource.statusCantEmpty'))]}
    />
  </SimpleForm>
);

export function CreateMode() {
  const { wallets } = useWallets();
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.agencies')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.agencies'), href: paths.dashboard.agency.root },
          { name: translate('resource.add') },
        ]}
        action={
          <MUIButton
            component={RouterLink}
            href={paths.dashboard.agency.create}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {translate('module.agency.newAgency')}
          </MUIButton>
        }
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Card>
        <Create redirect="list">{form('add', wallets, translate)}</Create>
      </Card>
    </DashboardContent>
  );
}

function EditMode() {
  const { wallets } = useWallets();
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.agencies')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.agencies'), href: paths.dashboard.agency.root },
          { name: translate('resource.edit') },
        ]}
        action={
          <MUIButton
            component={RouterLink}
            href={paths.dashboard.agency.create}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {translate('module.agency.newAgency')}
          </MUIButton>
        }
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Card sx={{}}>
        <Edit redirect="list" actions={<NoneActions />}>
          {form('edit', wallets, translate)}
        </Edit>
      </Card>
    </DashboardContent>
  );
}

const NoneActions = () => <CardActions />;

export const Breadcrumb = ({ icon: Icon, title, subTitle, subIcon: SubIcon = MdModeEdit }: any) => (
  <Box pl={1} pt={2}>
    <Breadcrumbs aria-label="breadcrumb" className="p-1">
      <Link
        sx={{ display: 'flex', alignItems: 'center' }}
        color="inherit"
        style={{ textDecoration: 'none' }}
        to={`/${Resources.agency}`}
      >
        <Icon style={{ marginRight: 5 }} />
        <Typography color="text.primary" fontSize={20}>
          {title}
        </Typography>
      </Link>

      <Box sx={{ display: 'flex', alignItems: 'center' }} color="inherit">
        <SubIcon style={{ marginRight: 5 }} />
        <Typography color="text.primary" fontSize={20}>
          {subTitle}
        </Typography>
      </Box>
    </Breadcrumbs>
  </Box>
);

export default EditMode;
