import { Tooltip, Typography } from '@mui/material';
import { Iconify } from '../iconify';

interface ToolTipProps {
  title: string;
}

export default function ToolTip({ title }: ToolTipProps) {
  return (
    <Tooltip
      title={<Typography fontSize={12}>{title}</Typography>}
      placement="top"
      enterDelay={300}
    >
      <Iconify
        width={16}
        icon="eva:info-outline"
        ml="5px"
        sx={{ cursor: 'pointer', color: 'text.disabled' }}
      />
    </Tooltip>
  );
}
