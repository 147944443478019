import React from "react"
import {
  Filter,
  NumberField,
  TextField,
  // TextInput,
  useTranslate,
} from 'react-admin';

import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { paths } from '../../../../routes/paths';
import CGDatagrid from '../../components/CGDatagrid';
import { CgList } from '../../components/CgList';
import { SelectListCurrencyInput, SelectListGameInput } from '../../components/common';
import FilterGroup from '../../components/FilterGroup';
import {DashboardContent} from "../../../../layouts/dashboard";
import CGTextInput from "../../components/CGTextInput";

function ListFilter(props: any) {
  return (
    <Filter {...props}>
      <FilterGroup alwaysOn>
        <SelectListCurrencyInput source="currencyCodes" label="module.currency.currency" />
        <SelectListGameInput source="gameIds" label="module.currency.filterByGames" />
        <CGTextInput type="number" label="module.currency.sysProfitFrom" source="fromSystemProfit" />
        <CGTextInput type="number" label="module.currency.sysProfitTo" source="toSystemProfit" />
      </FilterGroup>
    </Filter>
  );
}

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.currency')}
        links={[
          { name: translate('resource.dashboard'), href: paths.dashboard.root },
          { name: translate('resource.currency') },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <CgList filters={<ListFilter />}>
        <CGDatagrid
          rowClick={false}
          bulkActionButtons={false}
        >
          <TextField
            source="name"
            label="module.currency.currency"
            sortable={false}
            textAlign={'left'}
            minWidth={94}
          />
          <NumberField
            source="player"
            label="module.currency.playerCT"
            sortable={false}
            minWidth={139}
          />
          <NumberField
            source="newPlayer"
            label="module.currency.newPlayerCT"
            sortable={false}
            minWidth={136}
          />
          <NumberField
            source="winAmount"
            label="module.currency.winAmount"
            sortable={false}
            minWidth={152}
          />
          <NumberField
            source="playerWL"
            label="module.currency.playerWL"
            sortable={false}
            minWidth={131}
          />
          <NumberField
            source="sysWL"
            label="module.currency.sysWL"
            sortable={false}
            minWidth={133}
          />
          <NumberField
            source="sysProfit"
            label="module.currency.sysProfit"
            sortable={false}
            minWidth={160}
          />
        </CGDatagrid>
      </CgList>
    </DashboardContent>
  );
}

export default View;
