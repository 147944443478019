import axios from 'axios';
import { getAuth } from '../providers/AppAuthProvider';

const baseUrl = process.env.REACT_APP_API_URL;

export async function getAll() {
  const url = `${baseUrl}/permission`;

  const { accessToken } = (await getAuth()) || {};
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  };

  return axios.get<any>(url, {
    headers,
  });
}
