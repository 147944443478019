import { ResourceOptions } from "react-admin";
import { FaGamepad } from "react-icons/fa";
import { Resources } from "../resource";

export { create, default as edit } from "./Edit";
export { default as list } from "./List";
export { default as show } from "./Show";

export const name = Resources.gameRule;
export const icon = FaGamepad;
export const options: ResourceOptions = {
  label: "resource.gameRule",
};
