import { isEmpty } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { adminLeftMenu } from 'src/layouts/configs';
import { DashboardLayout } from 'src/layouts/dashboard';
import { Permission, PermissionData } from '../../entities/permission.entity';
import {SettingsDrawer} from "../../../../components/settings";

function hasPermission(permissions?: Permission[], roles?: string[]) {
  if (!roles || isEmpty(roles)) return true;
  const resources = permissions?.map((per) => per.resource) || [];
  if (resources.includes('*')) return true;
  if (roles?.includes('*')) return false;
  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];
    if (!resources.includes(role)) return false;
  }
  return true;
}

export const AdminLayout = ({ children }: { children: ReactNode }) => {
  const { data } = usePermissions<PermissionData>();
  const { permissions } = data || {};
  const menus = useMemo(() => {
    return adminLeftMenu
      .map(({ items, ...item }) => ({
        ...item,
        items: items?.filter(({ roles }) => hasPermission(permissions, roles)),
      }))
      .filter(({ items }) => !isEmpty(items));
  }, [permissions]);

  return (
    <DashboardLayout
      data={{
        nav: menus,
      }}
    >
      {children}
      <SettingsDrawer hideFont />
    </DashboardLayout>
  );
};
