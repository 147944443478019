import { Box, Button, Typography } from '@mui/material';
import { Link, useTranslate } from 'react-admin';
import { CustomBreadcrumbs } from '../../../../components/custom-breadcrumbs';
import { paths } from '../../../../routes/paths';
import { DashboardContent } from '../../../../layouts/dashboard';

function View() {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs
        heading={translate('resource.document')}
        links={[
          { name: `${translate('resource.game')}`, href: paths.dashboard.game.root },
          { name: `${translate('resource.document')}` },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Box>
        <Typography variant={'body1'} mb={2}>
          {translate('resource.gameDocsDesc')}:
        </Typography>
        <Link
          to={
            'https://cgame.notion.site/CGame-API-HUB-Integration-API-specs-English-1067c80654b18028b00ed9debe29e53b'
          }
          target={'_blank'}
        >
          <Button variant="contained" color="success">
            {translate('resource.viewOnlineDoc')}
          </Button>
        </Link>
      </Box>
    </DashboardContent>
  );
}

export default View;
