import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

function View() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <TextField source="fullName" label="Full name" />
        <DateField source="dob" label="Birthday" />
        <TextField source="phoneNumber" label="Phone number" />
        <TextField source="email" label="Email" />
      </SimpleShowLayout>
    </Show>
  );
}

export default View;
