import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Button, Link, useTranslate } from "react-admin";
import { formatMoney } from "../../utils/format-number";

interface BottomRightButton {
  title: string;
  icon: any;
  disabled?: boolean;
  link: string;
  onClick?: () => void;
}

interface IProps {
  title: string;
  label?: string;
  labelColor?: string;
  labelLeft: string;
  valueLeft: string | number;
  labelCenter: string;
  valueCenter: string | number;
  labelRight: string;
  valueRight: string | number | any;
  bottomRightButton: BottomRightButton;
  cardStyle?: any;
}

const GameCard = ({
  title,
  label,
  labelColor,
  labelLeft,
  valueLeft,
  labelCenter,
  valueCenter,
  labelRight,
  valueRight,
  bottomRightButton,
  cardStyle = {},
}: IProps) => {
  const Icon = bottomRightButton?.icon;
  const translate = useTranslate();

  return (
    <Card style={{ borderRadius: 15, ...cardStyle }}>
      <CardContent sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Typography>{title}</Typography>
          </Grid>

          <Grid item xs={6} display="flex" justifyContent="flex-end">
            {!!label && (
              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  background: labelColor || "green",
                  color: "black",
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {translate(label)}
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={3}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography color="gray">{translate(labelLeft)}</Typography>
              {formatMoney(valueLeft)}
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography color="gray">{translate(labelCenter)}</Typography>
              {valueCenter}
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography color="gray">{translate(labelRight)}</Typography>
              {valueRight}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Link to={bottomRightButton.link}>
              <Button
                style={{
                  background: bottomRightButton?.disabled ? "gray" : "#cb6c6c",
                  color: "white",
                  cursor: "pointer",
                }}
                variant="outlined"
                disabled={!bottomRightButton?.disabled}
                onClick={bottomRightButton?.onClick}
              >
                <>
                  {!!Icon && <Icon size={18} style={{ marginRight: 3 }} />}
                  {translate(bottomRightButton?.title)}
                </>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GameCard;
