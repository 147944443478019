import React from 'react';
import { UserModel } from '../../../entities/user.entity';
import * as chartService from '../../../services/chartService';

const usePlayer = (user?: UserModel, params?: chartService.FilterParam) => {
  const [data, setData] = React.useState({} as any);
  const [loading, setLoading] = React.useState(false);
  const { agencyId, gameIds, fromTime, toTime } = params?.filter ?? {} ?? {};

  React.useEffect(() => {
    if (user) {
      setLoading(true);
      chartService
        .getPlayer({ filter: { agencyId, gameIds, fromTime, toTime } })
        .then((response: any) => {
          setData(response.data);
          setLoading(false);
        });
    }
  }, [user, agencyId, gameIds, fromTime, toTime]);

  return {
    data,
    loading,
  };
};

export default usePlayer;
