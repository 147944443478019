import {
  Create,
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin";

const form = (
  <SimpleForm>
    <div>
      <ReferenceInput source="gameId" reference="game" />
      <NumberInput source="ruleConfig.min" label="module.game.config.min" />
      <NumberInput source="ruleConfig.max" label="module.game.config.max" />
      <NumberInput source="ruleConfig.rate" label="module.game.config.rate" />
    </div>
  </SimpleForm>
);

export function create() {
  return <Create>{form}</Create>;
}

function View() {
  return <Edit>{form}</Edit>;
}

export default View;
