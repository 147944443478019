import { ResourceOptions } from "react-admin";
import { MdCurrencyExchange } from "react-icons/md";
import { Resources } from "../resource";

export { create, default as edit } from "./Edit";
export { default as list } from "./List";
export { default as show } from "./Show";

export const name = Resources.currency;
export const icon = MdCurrencyExchange;
export const options: ResourceOptions = {
  label: "resource.currency",
};
