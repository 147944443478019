import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Datagrid, DateField, List, TextField, useTranslate } from "react-admin";
import { VscSettings } from "react-icons/vsc";

const ListActions = () => null;

function View() {
  const translate = useTranslate();

  return (
    <>
      <Box pl={1} pt={2}>
        <Breadcrumbs aria-label="breadcrumb" className="p-1">
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
          >
            <VscSettings style={{ marginRight: 5 }} />
            <Typography color="text.primary" fontSize={20}>{translate("resource.agencies")}</Typography>
          </Box>
        </Breadcrumbs>
      </Box>

      <List empty={false} actions={<ListActions />} sx={{
        pl: 1, pr: 2, mt: '6px',
        '& .RaList-actions': {
          marginBottom: 2,
        },
      }}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="fullName" label="module.user.fullName" />
          <DateField source="dob" label="module.user.birthday" />
          <TextField source="phoneNumber" label="module.user.phoneNumber" />
          <TextField source="email" label="module.user.email" />
        </Datagrid>
      </List>
    </>

  );
}

export default View;
