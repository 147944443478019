import { Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames';
import { f } from 'src/utils/format';
import { Typography } from '@mui/material';
import PnLValueFormatted from './PnLValueFormatted';
import PnLText from './PnLText';

interface IProps {
  selectedHistory: any;
  isField?: boolean;
}

const ItemPnl = (props: IProps) => {
  const { selectedHistory, isField } = props;
  const dynamicPnl = selectedHistory.pnl;
  const dynamicRoi = selectedHistory.roi;
  const color =
    dynamicPnl > 0 ? 'var(--palette-primary-main)' : dynamicPnl < 0 ? '#ff4651' : '#b1b1b6';

  if (isField) {
    return (
      <Typography color={color}>
        <PnLValueFormatted pnl={dynamicPnl} />
      </Typography>
    );
  }
  return <PnLText pnl={dynamicPnl} roi={dynamicRoi} />;
};
export default ItemPnl;
