import type { ThemeUpdateOptions } from './types';

// ----------------------------------------------------------------------

export const overridesTheme: ThemeUpdateOptions = {
  /**
   *
 ```jsx
  colorSchemes: {
    light: {
      palette: {
        primary: createPaletteChannel({
          lighter: '#E4DCFD',
          light: '#A996F8',
          main: '#6950E8',
          dark: '#3828A7',
          darker: '#180F6F',
          contrastText: '#FFFFFF',
        }),
      },
    },
  },
  shape: { borderRadius: 0 },
 ```
 */
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 'var(--cg-mui-input-base-height) !important',
        },
      },
    },
  },
};
