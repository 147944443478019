import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
} from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import CGSaveButton from '../../components/CGSaveButton';
import { Resources } from '../resource';
import styles from './style.module.scss';
import { useCanEditGameRule } from './hooks/useCanEditGameRule';
import { BiPlus } from 'react-icons/bi';
import CloseIcon from '@mui/icons-material/Close';
import CheckedIcon from '@mui/icons-material/CheckRounded';
import UnCheckedIcon from '@mui/icons-material/CloseOutlined';
import { formatNumber } from 'src/utils/format-number';
import useIsAdmin from '../../../../hooks/use-is-admin';

interface GameRuleForm<T> {
  value?: T;
  gameId: string;
}

interface BOGameConfig {
  name: string;
  schedule: string;
  skipTime: string; // skip game schedule if exist
  lockBefore: string;
  pair: string;
  fee: string;
  minAmount: string;
  maxAmount: string;
  amountStep: string;
  winRate: string;
  enable?: boolean;
}

interface IGameError {
  name?: string | null;
  sequence?: string | null;
  skipTime?: string | null;
  lockBefore?: string | null;
  pair?: string | null;
  fee?: string | null;
  minAmount?: string | null;
  maxAmount?: string | null;
  amountStep?: string | null;
  winRate?: string | null;
}

const defaultConfig: BOGameConfig = {
  name: '',
  schedule: '',
  skipTime: '',
  lockBefore: '',
  pair: '',
  fee: '',
  minAmount: '',
  maxAmount: '',
  winRate: '',
  amountStep: '',
  enable: false,
};

interface GameConfigColumn {
  id: keyof BOGameConfig;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number | string) => string;
}

export function BOGameConfigTable(props: GameRuleForm<BOGameConfig[]>) {
  const translate = useTranslate();
  const isAdmin = useIsAdmin();
  const tableColumns: GameConfigColumn[] = [
    { id: 'pair', label: translate('module.gameRule.bo.pair'), minWidth: 100 },
    // { id: 'name', label: translate('module.gameRule.bo.name'), minWidth: 170 },
    // { id: 'schedule', label: translate('module.gameRule.bo.schedule'), minWidth: 100 },
    // { id: 'skipTime', label: translate('module.gameRule.bo.skipTime'), minWidth: 120 },
    { id: 'lockBefore', label: translate('module.gameRule.bo.lockBefore'), minWidth: 120 },
    // { id: 'fee', label: translate('module.gameRule.bo.fee'), minWidth: 100 },
    {
      id: 'minAmount',
      label: translate('module.gameRule.bo.minAmount'),
      minWidth: 120,
      format: (value) => formatNumber(value),
    },
    {
      id: 'maxAmount',
      label: translate('module.gameRule.bo.maxAmount'),
      minWidth: 120,
      format: (value) => formatNumber(value),
    },
    { id: 'amountStep', label: translate('module.gameRule.bo.amountStep'), minWidth: 120 },
    { id: 'winRate', label: translate('module.gameRule.bo.winRate'), minWidth: 100 },
    // { id: 'enable', label: translate('module.gameRule.bo.enable'), minWidth: 100 },
  ];

  // const [configs, setConfigs] = useState<BOGameConfig[]>(props.value ?? []);
  const [openDialog, setOpenDialog] = useState(false);
  const configs = props.value ?? [];
  const [selectedConfig, setSelectedConfig] = useState<BOGameConfig>(defaultConfig);

  return (
    <Container className={styles.hashGameFormContainer}>
      <Container className={styles.groupInput}>
        {/* add table show game config, click at row show dialog edit */}
        {isAdmin && (
          <Button
            startIcon={<BiPlus />}
            variant="outlined"
            onClick={() => {
              setSelectedConfig(defaultConfig);
              setOpenDialog(true);
            }}
          >
            {translate('addConfig')}
          </Button>
        )}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {configs?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.name}_${row.pair}`}
                    onClick={() => {
                      setSelectedConfig(row);
                      setOpenDialog(true);
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {tableColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? (
                            column.format(value as string)
                          ) : typeof value === 'boolean' ? (
                            value ? (
                              <CheckedIcon />
                            ) : (
                              <UnCheckedIcon />
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <BoConfigDialog
          open={openDialog}
          config={selectedConfig}
          gameId={props.gameId}
          configs={configs}
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      </Container>
      <Container className={styles.actions}></Container>
    </Container>
  );
}

interface BoDialogProps {
  gameId: string;
  config: BOGameConfig;
  configs: BOGameConfig[];
  open: boolean;
  onClose: () => void;
}

function BoConfigDialog(props: BoDialogProps) {
  const { open, config, onClose } = props;

  return (
    <Dialog onClose={onClose} open={open} scroll="body" fullWidth>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          bgcolor: 'var(--palette-background-default)',
        }}
      >
        <BOGameConfigForm
          gameId={props.gameId}
          config={config}
          configs={props.configs}
          onSuccessful={onClose}
        />
      </Box>
    </Dialog>
  );
}

interface FormProps {
  gameId: string;
  configs: BOGameConfig[];
  config: BOGameConfig;
  onSuccessful?: () => void;
}

function BOGameConfigForm({ gameId, config, configs, onSuccessful }: FormProps) {
  const translate = useTranslate();
  const editDisabled = !useCanEditGameRule();
  const isEditMode = !!config?.pair;

  const [input, setInput] = useState<BOGameConfig>(config ?? defaultConfig);

  const uniqueConfigs = configs.filter(
    (c) => `${c.name}_${c.pair}` !== `${config.name}_${config.pair}`
  );
  const value = [...uniqueConfigs, input];

  const [errors, setErrors] = useState<IGameError>({});

  const onChange =
    (field: string, pattern?: any) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const nextStr = event.target.value;
      if (
        !/^\d*(\.\d+)?$/.test(nextStr) ||
        (pattern && !/^[0-9]+$/.test(nextStr) && !pattern.test(nextStr))
      ) {
        return;
      }

      setInput({
        ...input,
        [field]: nextStr,
      });
    };

  const onChangeText =
    (field: string, pattern?: any) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const nextStr = event.target.value;

      setInput({
        ...input,
        [field]: nextStr,
      });
    };

  const handleBet = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const minAmount = Number(input.minAmount);
    const maxAmount = Number(input.maxAmount);
    const nextAmount = Number(e.target.value);
    const tempErrors: any = {};
    if (field === 'minAmount') {
      tempErrors.minAmount =
        nextAmount > maxAmount ? translate('module.game.minBetLessMaxBet') : null;
    }
    if (field === 'maxAmount') {
      tempErrors.maxAmount =
        nextAmount < minAmount ? translate('module.game.minBetLessMaxBet') : null;
    }

    setErrors(tempErrors);

    onChange(field)(e);
  };

  const handleBlur = (field: string) => {
    const fieldValue = get(input, field) || '';
    // remove 000xxx
    if (fieldValue.length >= 2 && fieldValue.startsWith('0')) {
      setInput({
        ...input,
        [field]: `${Number(fieldValue)}`,
      });
    }
  };

  const handleChangeChecbox = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [field]: event.target.checked,
    });
  };

  return (
    <Container className={styles.hashGameFormContainer}>
      <Container className={styles.groupInput}>
        <TextField
          label={translate('module.gameRule.bo.pair')}
          value={input.pair}
          onChange={onChangeText('pair')}
          helperText={translate('module.gameRule.bo.pairHelper')}
          placeholder={translate('module.gameRule.bo.pairPlaceholder')}
          sx={{ width: 500 }}
          disabled={editDisabled || isEditMode}
        />
        {/* <TextField
          label={translate('module.gameRule.bo.name')}
          value={input.name}
          onChange={onChangeText('name')}
          helperText={translate('module.gameRule.bo.nameHelper')}
          placeholder={translate('module.gameRule.bo.namePlaceholder')}
          sx={{ width: 500 }}
          disabled={editDisabled}
        /> */}
        {/* <TextField
          label={translate('module.gameRule.bo.schedule')}
          value={input.schedule}
          onChange={onChangeText('schedule')}
          helperText={translate('module.gameRule.bo.scheduleHelper')}
          placeholder={translate('module.gameRule.bo.schedulePlaceholder')}
          sx={{ width: 500, mt: 3 }}
          disabled={editDisabled}
        /> */}
        {/* <TextField
          label={translate('module.gameRule.bo.skipTime')}
          value={input.skipTime}
          type="number"
          onChange={onChange('skipTime')}
          onBlur={() => handleBlur('skipTime')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.gameRule.bo.skipTimeHelper')}
          placeholder={translate('module.gameRule.bo.skipTimePlaceholder')}
          disabled={editDisabled}
          sx={{ width: 500, mt: 3 }}
        /> */}
        <TextField
          label={translate('module.gameRule.bo.lockBefore')}
          value={input.lockBefore}
          type="number"
          onChange={onChange('lockBefore')}
          onBlur={() => handleBlur('lockBefore')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.gameRule.bo.lockBeforeHelper')}
          placeholder={translate('module.gameRule.bo.lockBeforePlaceholder')}
          disabled
          sx={{ width: 500, mt: 3 }}
        />
        <TextField
          label={translate('module.gameRule.bo.minAmount')}
          value={input.minAmount}
          type="number"
          onChange={handleBet('minAmount')}
          onBlur={() => handleBlur('minAmount')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          placeholder={translate('module.gameRule.bo.minAmountPlaceholder')}
          disabled={editDisabled}
          sx={{ width: 500, mt: 3 }}
          helperText={
            !!errors?.minAmount ? (
              <span style={{ color: 'red' }}>{errors.minAmount}</span>
            ) : (
              translate('module.gameRule.bo.minAmountHelper')
            )
          }
        />
        <TextField
          label={translate('module.gameRule.bo.maxAmount')}
          value={input.maxAmount}
          type="number"
          onChange={handleBet('maxAmount')}
          onBlur={() => handleBlur('maxAmount')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.gameRule.bo.maxAmountHelper')}
          placeholder={translate('module.gameRule.bo.maxAmountPlaceholder')}
          disabled={editDisabled}
          sx={{ width: 500, mt: 3 }}
        />
        <TextField
          label={translate('module.gameRule.bo.amountStep')}
          value={input.amountStep}
          type="number"
          onChange={onChange('amountStep')}
          onBlur={() => handleBlur('amountStep')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.gameRule.bo.amountStepHelper')}
          placeholder={translate('module.gameRule.bo.amountStepPlaceholder')}
          disabled={editDisabled}
          sx={{ width: 500, mt: 3 }}
        />
        <TextField
          label={translate('module.gameRule.bo.winRate')}
          value={input.winRate}
          type="number"
          onChange={onChange('winRate')}
          onBlur={() => handleBlur('winRate')}
          onKeyDown={(e) =>
            ['ArrowUp', 'ArrowDown', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
          }
          helperText={translate('module.gameRule.bo.winRateHelper')}
          placeholder={translate('module.gameRule.bo.winRatePlaceholder')}
          disabled={editDisabled}
          sx={{ width: 500, mt: 3 }}
        />
        {/* <Box
          sx={{
            width: 500,
            display: 'block',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={input.enable}
                onChange={handleChangeChecbox('enable')}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={translate('module.gameRule.bo.enable')}
          />
        </Box> */}

        <CGSaveButton
          className={styles.button}
          resource={Resources.gameRule}
          type="create"
          variant="contained"
          color="success"
          data={{
            gameId,
            ruleConfig: value,
          }}
          message={{ success: 'message.configSaved' }}
          disabled={editDisabled || Object.values(errors).some((err) => !!err)}
          onSucces={onSuccessful}
        />
      </Container>
      <Container className={styles.actions}></Container>
    </Container>
  );
}
