import { Navigate, useRoutes } from 'react-router-dom';

import { CONFIG } from 'src/config-global';

import { createTheme, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { lazy } from 'react';
import { Admin, Resource } from 'react-admin';
import { AuthSplitLayout } from 'src/layouts/auth-split';
import { AdminLayout } from 'src/pages/admin/components/Layout';
import AppAuthProvider from 'src/pages/admin/providers/AppAuthProvider';
import AppDataProvider from 'src/pages/admin/providers/AppDataProvider';
import FakeDataProvider from 'src/pages/admin/providers/FakeDataProvider';
import i18nProvider from 'src/pages/admin/providers/i18nProvider';
import resources from 'src/pages/admin/resources';
import { MainDashboard } from 'src/pages/admin/resources/dashboard';
import { SettingsDrawer } from '../../components/settings';

// ----------------------------------------------------------------------
const isMock = process.env.REACT_APP_MODE === 'mock';

export function AppRouter() {
  const { vars, ..._theme } = useTheme();

  const theme = deepmerge(_theme, {
    components: {
      RaDatagrid: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            borderRadius: 0,

            [`& .RaDatagrid-headerCell`]: {
              fontSize: 14,
              color: vars.palette.text.secondary,
              fontWeight: _theme.typography.fontWeightSemiBold,
              backgroundColor: vars.palette.background.neutral,
              padding: _theme.spacing(2),
            },
            [`& .RaDatagrid-headerCell:first-of-type`]: {
              borderRadius: 0,
            },
            [`& .RaDatagrid-headerCell:last-of-type`]: {
              borderRadius: 0,
            },
            [`& .RaDatagrid-expandIconCell`]: {
              paddingLeft: '16px',
            },
          },
        },
      },
      RaFilterFormInput: {
        styleOverrides: {
          root: {
            '& .RaFilterFormInput-spacer': {
              width: 0,
            },
          },
        },
      },
      RaFilterForm: {
        styleOverrides: {
          root: {
            padding: 0,
            '& .RaFilterForm-filterFormInput .MuiFormControl-root': {
              marginTop: 0,
            },
          },
        },
      },
      RaList: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            paddingRight: 0,
            width: '100%',
            '& .RaList-main': {
              width: '100%',
            },
            '& .RaList-content': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              borderRadius: 0,
            },
            '& .RaList-actions': {
              marginBottom: 2,
            },
            '& .RaFilterForm-filterFormInput': {
              marginTop: 0,
            },
          },
        },
      },
    },
  });
  const myTheme = createTheme(theme as any);

  return useRoutes([
    {
      path: '/*',
      element: (
        <Admin
          dashboard={MainDashboard}
          dataProvider={isMock ? FakeDataProvider : AppDataProvider}
          authProvider={AppAuthProvider}
          i18nProvider={i18nProvider}
          layout={AdminLayout}
          loginPage={<LoginPage />}
          theme={myTheme}
        >
          {resources.map((resource) => (
            <Resource key={resource.name} {...resource} />
          ))}
        </Admin>
      ),
    },

    // No match
    { path: '*', element: <Navigate to={CONFIG.auth.redirectPath} replace /> },
  ]);
}

const Jwt = {
  SignInPage: lazy(() => import('src/pages/auth/jwt/sign-in')),
};

function LoginPage(props: any) {
  return (
    <AuthSplitLayout sx={{ height: '100vh' }} section={{ title: 'Hi, Welcome back' }}>
      <Jwt.SignInPage />
      <SettingsDrawer hideFont />
    </AuthSplitLayout>
  );
}
