import type { CardProps } from '@mui/material/Card';
import type { ChartOptions } from 'src/components/chart';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import { useTheme, alpha as hexAlpha } from '@mui/material/styles';

import { fNumber, fShortenNumber } from 'src/utils/format-number';

import { Chart, useChart, ChartLegends } from 'src/components/chart';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = CardProps & {
  title?: string;
  subheader?: string;
  chart: {
    colors?: string[];
    series: {
      label: string;
      value: number;
    }[];
    options?: ChartOptions;
    tooltips?: string[];
  };
};

export function DonutChart({ title, subheader, chart, ...other }: Props) {
  const theme = useTheme();

  const chartColors = chart.colors ?? [
    '#22C55E',
    theme.palette.secondary.light,
    theme.palette.info.light,
    theme.palette.warning.light,
    theme.palette.error.light,
    '#FF5630',
    '#454F5B',
    '#B71D18',
    '#FFAB00',
  ];

  const chartSeries = chart.series.map((item) => item.value);

  const chartOptions = useChart({
    chart: { sparkline: { enabled: true } },
    colors: chartColors,
    labels: chart.series.map((item) => item.label),
    stroke: { width: 0 },
    tooltip: {
      y: {
        formatter: (value: number) => fShortenNumber(value),
        title: { formatter: (seriesName: string) => `${seriesName}` },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85%',
          labels: {
            value: {
              formatter: (value: number | string) => fShortenNumber(value),
            },
            total: {
              formatter: (w: {
                globals: {
                  seriesTotals: number[];
                };
              }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fShortenNumber(sum);
              },
            },
            name: {
              color: 'black',
            },
          },
        },
      },
    },
    ...chart.options,
  });

  return (
    <Card {...other} sx={{ overflow: 'visible' }}>
      {title && <CardHeader title={title} subheader={subheader} />}
      <Chart
        type="donut"
        series={chartSeries}
        options={chartOptions}
        height="100%"
        sx={{ mt: 2, mb: 3 }}
      />
      <Divider sx={{ borderStyle: 'dashed' }} />
      <ChartLegends
        labels={chartOptions?.labels}
        colors={chartOptions?.colors}
        values={chartSeries}
        sx={{
          py: 2,
          marginTop: 2,
          // justifyContent: 'center',
          flexDirection: 'column',
          gap: 0,
          px: 4,
        }}
        tooltips={chart.tooltips}
      />
    </Card>
  );
}
