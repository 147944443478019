import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './global.css';

import { I18nProvider, LocalizationProvider } from 'src/locales';
import { MotionLazy } from './components/animate/motion-lazy';
import { ProgressBar } from './components/progress-bar';
import { defaultSettings, SettingsProvider } from './components/settings';
import { AppRouter } from './routes/sections';
import { ThemeProvider } from './theme/theme-provider';

dayjs.extend(utc);

function App() {
  return (
    <I18nProvider>
      <LocalizationProvider>
        <SettingsProvider settings={defaultSettings}>
          <ThemeProvider>
            <MotionLazy>
              <ProgressBar />
              <AppRouter />
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </I18nProvider>
  );
}

export default App;
