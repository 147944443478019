import * as agency from './agency';
import * as currency from './currency';
import * as game from './game';
import * as gameDetail from './game-detail';
import * as gameDocument from './game-document';
import * as gameRule from './game-rule';
import * as hashMonitor from './hash-monitor';
import { Resources } from './resource';
import * as setting from './setting';
import * as transaction from './transaction';
import * as user from './user';
import * as users from './users';

const addionalResources = [Resources.userStatistics, Resources.gameCategory].map((name) => ({
  name,
}));

export default [
  currency,
  user,
  game,
  gameDetail,
  gameDocument,
  transaction,
  gameRule,
  agency,
  setting,
  hashMonitor,
  users,
  ...addionalResources,
];
