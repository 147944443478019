export const currencySupported = ['USDT'];

export const mapPingMoney = [
  {
    key: 'BTC_USDT',
    value: 'BTC_USDT',
    icon: '/images/icons/currencies/bitcoin.png',
    symbol: '$',
  },
  {
    key: 'ETH_USDT',
    value: 'ETH_USDT',
    icon: '/images/currency/eth.png',
    symbol: '$',
  },
  {
    key: 'DOGE_USDT',
    value: 'DOGE_USDT',
    icon: '/images/currency/doge.png',
    symbol: '$',
  },
  {
    key: 'BNB_USDT',
    value: 'BNB_USDT',
    icon: '/images/currency/bnb.png',
    symbol: '$',
  },
  {
    key: 'TRON_TRX',
    value: 'TRON_TRX',
    icon: '/images/currency/trx.png',
    symbol: '$',
  },
  {
    key: 'SOL_USDT',
    value: 'SOL_USDT',
    icon: '/images/currency/sol.png',
    symbol: '$',
  },
  {
    key: 'CG11_USDT',
    value: 'CG11_USDT',
    icon: '/images/currency/CGO_200x200.png',
    symbol: '$',
  },
  {
    key: 'bitcoin',
    value: 'bitcoin',
    icon: '/images/icons/currencies/bitcoin.png',
    symbol: '$',
  },
  {
    key: 'BCH_USDT',
    value: 'BCH_USDT',
    icon: '/images/icons/currencies/bitcoin-cash-circle.png',
    symbol: '$',
  },
  {
    key: 'ETC_USDT',
    value: 'ETC_USDT',
    icon: '/images/icons/currencies/ethereum-classic-logo.png',
    symbol: '$',
  },
  {
    key: 'OKB_USDT',
    value: 'OKB_USDT',
    icon: '/images/icons/currencies/okb.png',
    symbol: '$',
  },
  {
    key: 'TON_USDT',
    value: 'TON_USDT',
    icon: '/images/icons/currencies/ton_symbol.png',
    symbol: '$',
  },
];

export const CUSTOM_CURRENCY = 'CG11_USDT';
const keyPingMoney: any = mapPingMoney.reduce(
  (previousObject, currentObject) => ({
    ...previousObject,
    [currentObject.key]: currentObject.icon,
  }),
  {}
);

export const getImgFromPair = (pair: string) => keyPingMoney[pair || 'BTC_USDT'];

export const getSymbolFromPair = (pair: string) => {
  if (!pair) {
    return '/assets/icons/currencies/Tether.png';
  }

  const symbols = [
    { label: '_USDT', value: '/assets/icons/currencies/Tether.png' },
    { label: '_TRX', value: '/assets/icons/currencies/tron-logo.png' },
    { label: '_BTC', value: '/assets/icons/currencies/bitcoin.png' },
  ].find((obj) => pair.endsWith(obj.label));

  return symbols?.value || '/assets/icons/currencies/Tether.png';
};
