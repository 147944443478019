import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';
import { FaCheck } from 'react-icons/fa';

export default function CopyAll({ cpyTxt }: { cpyTxt: string }) {
  const t = useTranslate();
  const [checked, setChecked] = useState(false);

  const copy = useCallback(() => {
    navigator.clipboard.writeText(cpyTxt).catch(console.warn);
    setChecked(true);
    setTimeout(() => {
      setChecked(false);
    }, 1000);
  }, [cpyTxt]);

  return (
    <Button
      aria-label="Copy Content"
      aria-labelledby="Copy Content"
      onClick={copy}
      sx={{
        width: '100%',
        mt: 2,
        bgcolor: 'primary.main',
        color: 'white',
        ':hover': { backgroundColor: '#A3CE0C' },
      }}
    >
      {checked ? t('notification.copied') : t('notification.copyAll')}
    </Button>
  );
}
