import { Icon } from '@iconify/react';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Filter, List, useGetIdentity, useTranslate, WithListContext } from 'react-admin';
import { CustomBreadcrumbs } from 'src/components/custom-breadcrumbs';
import { DashboardContent } from '../../../../layouts/dashboard';
import { UserModel } from '../../entities/user.entity';
import { DonutChart } from '../game-detail/DonutChart';
import { RevenueChart } from '../game-detail/RevenueChart';
import useRevenue from '../game-detail/useRevenue';
import { ListCard } from './components/ListCard';
import usePlayer from './hooks/usePlayer';
import AgencySelectInput from '../../components/CGListAgency';
import useIsAdmin from 'src/hooks/use-is-admin';
import SelectInput from 'src/components/inputs/select-input';
import { FilterParam } from '../../services/chartService';
import FilterGroup from '../../components/FilterGroup';
import { SelectListGameInput } from '../../components/common';
import { DashboardCharts } from './components/DashboardCharts';
import { DashboardFilter } from './components/DashboardFilter';

export default () => {
  const translate = useTranslate();

  return (
    <DashboardContent
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      }}
    >
      <CustomBreadcrumbs heading={translate('resource.dashboard')} sx={{ px: 0 }} />

      <List
        pagination={false}
        filters={<DashboardFilter />}
        actions={false}
        resource="charts/revenue"
      >
        <DashboardCharts />
      </List>
    </DashboardContent>
  );
};
