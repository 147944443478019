import axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AuthProvider } from 'react-admin';
import { PermissionData } from '../entities/permission.entity';
import { UserModel } from '../entities/user.entity';
import * as authService from '../services/authService';
import { AuthModel } from '../services/authService';
import * as permissionService from '../services/permissionService';
import { CGStorage, StorageKey } from './storage';

export async function getAuth() {
  return CGStorage.get<AuthModel>(StorageKey.AuthData);
}

async function login(params: any) {
  const { username, password } = params;

  const auth = await authService.login({ username, password }).catch((err) => {
    if (err instanceof AxiosError) {
      throw err.response?.data;
    }
    throw err;
  });

  await CGStorage.set(StorageKey.AuthData, auth);
  return auth;
}

const baseUrl = process.env.REACT_APP_API_URL;

const authProvider: AuthProvider = {
  login,
  async logout() {
    await Promise.all([
      CGStorage.remove(StorageKey.AuthData),
      CGStorage.remove(StorageKey.UserData),
      CGStorage.remove(StorageKey.Permission),
    ]);
  },
  async getPermissions() {
    const permission = await CGStorage.get(StorageKey.Permission);
    if (permission) return permission;

    const response = await permissionService.getAll();
    const result = response?.data as PermissionData;

    await CGStorage.set(StorageKey.Permission, result);
    return result;
  },
  async checkAuth(params: any) {
    const auth = await getAuth();
    if (!auth) {
      throw new Error('Session expired!');
    }
    const decoded = jwtDecode(auth?.accessToken);

    const expired = new Date((decoded.exp || 0) * 1000);

    if (expired.getTime() < Date.now()) {
      throw new Error('Session expired!');
    }
  },
  async checkError(params: any) {
    if (params instanceof AxiosError) {
      const { status = 0 } = params.response || {};
      if (status >= 400) {
        throw params;
      }
    }
    return Promise.resolve();
  },

  async getIdentity() {
    const user = await CGStorage.get<UserModel>(StorageKey.UserData);
    if (user) return user;
    const { accessToken } = (await getAuth()) || {};
    const url = `${baseUrl}/auth/me`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    };
    const resp = await axios.get<UserModel>(url, {
      headers,
    });
    const data = resp?.data;

    await CGStorage.set(StorageKey.UserData, data);
    return data;
  },
};

export default authProvider;
